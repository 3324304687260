import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SemenAnalysisState = { semenAnalyses: [] };

export const semenAnalysisSlice = createSlice({
  name: 'semenAnalyses',
  initialState,
  reducers: {
    addSemenAnalyses: (state, action: PayloadAction<SemenAnalysis[]>) => {
      state.semenAnalyses = action.payload;
    },
    deleteSemenAnalysis: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      state.semenAnalyses = state.semenAnalyses.filter(
        semenAnalysis => semenAnalysis.publicId !== action.payload.publicId
      );
    },
    addSemenAnalysis: (state, action: PayloadAction<SemenAnalysis>) => {
      state.semenAnalyses.push(action.payload);
    },
    updateSemenAnalysis: (state, action: PayloadAction<SemenAnalysis>) => {
      const index = state.semenAnalyses.findIndex(
        semenAnalysis => semenAnalysis.publicId === action.payload.publicId
      );
      if (index !== -1) {
        state.semenAnalyses[index] = action.payload;
      }
    },
  },
});

export default semenAnalysisSlice.reducer;
export const {
  addSemenAnalyses,
  deleteSemenAnalysis,
  addSemenAnalysis,
  updateSemenAnalysis,
} = semenAnalysisSlice.actions;
