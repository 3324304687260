import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Textarea,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../common/Alert';
import { DatePicker, SelectWithInputAndAddOption } from '../../../../common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { RadioButton } from '../../../../../components/UI';
import {
  createEmbryoTransfer,
  getAllEmbryologies,
  updateEmbryoTransfer,
} from '../../../../../services/api/endPoints/embryology';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import Skeleton from 'react-loading-skeleton';

const EmbryoTransferModal = ({ isOpen, onClose, ...props }: any) => {
  const { treatmentCycleId } = useParams();
  const [embryoIds, setEmbryoIds] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staffOptions, setStaffOptions] = useState<Record<string, string>>({});
  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);
  const user = useSelector<RootState, UserEntities>(state => state.user.user);

  const defaultFormData: EmbryoTransferRecord = {
    treatmentCyclePublicId: null,
    embryosTransferredPublicIds: null,
    transferredOn: new Date(),
    requestedByPublicId: user.staff.publicId,
    performedByPublicId: user.staff.publicId,
    signedOffByPublicId: user.staff.publicId,
    generalComments: '',
    compliantTransfer: null,
    reasonForNonCompliance: null,
    taScanPerformed: null,
    findings: '',
    flashSeen: null,
    catheter: null,
    bladderStatus: null,
    uterusPosition: null,
    easeOfTransfer: null,
    medicationsAdministered: null,
  };

  const [formData, setFormData] =
    useState<EmbryoTransferRecord>(defaultFormData);

  useEffect(() => {
    const setInitialData = async () => {
      const staffOptions: Record<string, string> = {};
      staffs.forEach(staff => {
        staffOptions[staff.publicId] = staff.fullName ?? '';
      });
      setStaffOptions(staffOptions);
    };
    setInitialData();
    if (treatmentCycleId) {
      setIsLoading(true);
      getEmbryos(treatmentCycleId);
      getAllTransferredEmbryos(treatmentCycleId);
    }
  }, []);

  const getAllTransferredEmbryos = async (treatmentCycleId: string) => {
    if (props.transferredEmbryo) {
      const data = props.transferredEmbryo.embryosTransferred
        .map((obj: { [x: string]: any }) => obj['publicId'])
        .filter((value: any) => typeof value === 'string');

      setFormData(props.transferredEmbryo);
      setFormData(prev => ({
        ...prev,
        embryosTransferredPublicIds: data,
        transferredOn: new Date(props.transferredEmbryo.transferredOn),
        requestedByPublicId: props.transferredEmbryo.requestedBy.publicId,
        performedByPublicId: props.transferredEmbryo.performedBy.publicId,
        signedOffByPublicId: props.transferredEmbryo.signedOffBy.publicId,
        treatmentCyclePublicId: treatmentCycleId,
      }));
      setFormData(prevFormData => {
        const newFormData = { ...prevFormData };
        delete newFormData.embryosTransferred;
        delete newFormData.performedBy;
        delete newFormData.requestedBy;
        delete newFormData.signedOffBy;
        delete newFormData.treatmentCycle;
        delete newFormData.whenCreated;
        return newFormData;
      });
    }
  };

  const getEmbryos = async (treatmentCycleId: string) => {
    const [response, error] = await getAllEmbryologies(treatmentCycleId);
    if (error) {
      notify.error({
        title: ERROR_TITLE.GENERIC_EMBRYOS,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      return;
    }

    const data = response
      .map((obj: { [x: string]: any }) => obj['publicId'])
      .filter((value: any) => typeof value === 'string');

    setEmbryoIds(data);
    inputHandler('treatmentCyclePublicId', treatmentCycleId);
    setIsLoading(false);
  };

  const inputHandler = (name: keyof EmbryoTransferRecord, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors: Record<string, string> = {};
    if (!formData.performedByPublicId) {
      errors.performedByPublicId = 'Performed by - staff is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    if (!formData.publicId) {
      const [res, error] = await createEmbryoTransfer(formData);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EMBRYO_TRANSFER,
        });
        onClose();
      } else {
        notify.error({
          title: SUCCESS_TITLE.GENERIC_FOLLICLE_SCAN_ADDED,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else if (formData.publicId) {
      const data = { ...formData };
      const [res, error] = await updateEmbryoTransfer(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EMBRYO_TRANSFER,
        });
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_EMBRYO_TRANSFER,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }
    setIsLoading(false);
  };

  const handleSelectChange = (
    name: keyof EmbryoTransferRecord,
    value: string | Date | string[]
  ) => {
    if (name === 'embryosTransferredPublicIds' && !(value instanceof Date)) {
      setFormData(prevData => ({
        ...prevData,
        [name]: [...value],
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value instanceof Date ? value.toISOString() : value,
      }));
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[70vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Embryo Transfer
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>

        <div className="flex-1 p-4 space-y-4 overflow-scroll">
          <div className="flex flex-wrap gap-4">
            <div className="flex-1 ">
              <div className="w-full   space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Transferred On
                </Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value =>
                    inputHandler('transferredOn', value)
                  }
                  initialValue={formData.transferredOn}
                  hasError={!!formErrors.transferredOn}
                />
                {formErrors.transferredOn && (
                  <small className="text-destructive-500">
                    {formErrors.transferredOn}
                  </small>
                )}
              </div>
              <div className="w-full  space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Requested by
                </Label>
                <Select
                  name="performedByPublicId"
                  value={formData.requestedByPublicId || ''}
                  onValueChange={value =>
                    inputHandler('requestedByPublicId', value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {Object.keys(staffOptions).length > 0 ? (
                      Object.keys(staffOptions).map(publicId => (
                        <SelectItem key={publicId} value={publicId}>
                          {staffOptions[publicId]}
                        </SelectItem>
                      ))
                    ) : (
                      <p>No staffs found</p>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.requestedByPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.requestedByPublicId}
                  </small>
                )}
              </div>
              <div className="w-full  space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Performed by
                </Label>
                <Select
                  name="performedByPublicId"
                  value={formData.performedByPublicId || ''}
                  onValueChange={value =>
                    inputHandler('performedByPublicId', value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {Object.keys(staffOptions).length > 0 ? (
                      Object.keys(staffOptions).map(publicId => (
                        <SelectItem key={publicId} value={publicId}>
                          {staffOptions[publicId]}
                        </SelectItem>
                      ))
                    ) : (
                      <p>No staffs found</p>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.performedByPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.performedByPublicId}
                  </small>
                )}
              </div>
              <div className="w-full  space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Signed off by
                </Label>
                <Select
                  name="signedOffByPublicId"
                  value={formData.signedOffByPublicId || ''}
                  onValueChange={value =>
                    inputHandler('signedOffByPublicId', value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {Object.keys(staffOptions).length > 0 ? (
                      Object.keys(staffOptions).map(publicId => (
                        <SelectItem key={publicId} value={publicId}>
                          {staffOptions[publicId]}
                        </SelectItem>
                      ))
                    ) : (
                      <p>No staffs found</p>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.signedOffByPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.signedOffByPublicId}
                  </small>
                )}
              </div>
            </div>
            <div className="flex-1">
              <div className="w-full   space-y-1">
                <Label>General comments</Label>
                <Textarea
                  placeholder="Enter general comments"
                  id="generalComments"
                  name="generalComments"
                  rows={8}
                  value={formData.generalComments || ''}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    inputHandler('generalComments', e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="flex flex-wrap gap-4">
            {isLoading ? (
              <div className="flex-1">
                <div className="space-y-1">
                  <Label htmlFor="Transferred">Transferred</Label>

                  <Skeleton width={'100%'} height={40} />
                </div>
              </div>
            ) : (
              <div className="flex-1">
                <div className="space-y-1">
                  <Label htmlFor="Transferred">Transferred</Label>
                  <SelectWithInputAndAddOption
                    name="type"
                    inputPlaceholder="Transferred"
                    values={
                      formData.embryosTransferredPublicIds
                        ? formData.embryosTransferredPublicIds
                        : []
                    }
                    options={embryoIds}
                    setOptions={setEmbryoIds}
                    setValues={(values: string[]) =>
                      handleSelectChange('embryosTransferredPublicIds', values)
                    }
                    type="multi"
                  />
                </div>
              </div>
            )}
            <div className="flex-1">
              <div className="w-full   space-y-1">
                <Label>Complete sample</Label>
                <div className="flex gap-2">
                  <RadioButton
                    selectedValue={formData?.compliantTransfer}
                    optionLabel="Yes"
                    label="compliantTransfer"
                    value={true}
                    onChange={val => inputHandler('compliantTransfer', val)}
                  />
                  <RadioButton
                    selectedValue={formData?.compliantTransfer}
                    optionLabel="No"
                    label="compliantTransfer"
                    value={false}
                    onChange={val => inputHandler('compliantTransfer', val)}
                  />
                </div>
              </div>
              <div className="w-full   space-y-1">
                <Label>Reason for non-compliance</Label>
                <Textarea
                  placeholder="Enter reason for non-compliance"
                  id="reasonForNonCompliance"
                  name="reasonForNonCompliance"
                  rows={4}
                  value={formData.reasonForNonCompliance || ''}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    inputHandler('reasonForNonCompliance', e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="flex flex-wrap gap-4">
            <div className="flex-1">
              <div className="w-full space-y-1">
                <Label>TA scan performed</Label>
                <div className="flex gap-2">
                  <RadioButton
                    selectedValue={formData?.taScanPerformed}
                    optionLabel="Yes"
                    label="taScanPerformed"
                    value={true}
                    onChange={val => inputHandler('taScanPerformed', val)}
                  />
                  <RadioButton
                    selectedValue={formData?.taScanPerformed}
                    optionLabel="No"
                    label="taScanPerformed"
                    value={false}
                    onChange={val => inputHandler('taScanPerformed', val)}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="w-full space-y-1">
                <Label>Findings</Label>
                <Textarea
                  placeholder="Enter findings"
                  id="findings"
                  name="findings"
                  rows={4}
                  value={formData.findings || ''}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    inputHandler('findings', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-wrap gap-4">
            <div className="flex-1">
              {[
                { key: 'flashSeen', label: 'Flash seen', enum: FlashSeen },
                { key: 'catheter', label: 'Catheter', enum: CatheterType },
                {
                  key: 'bladderStatus',
                  label: 'Bladder status',
                  enum: BladderStatus,
                },
                {
                  key: 'uterusPosition',
                  label: 'Uterus position',
                  enum: UterusPosition,
                },
                {
                  key: 'easeOfTransfer',
                  label: 'Ease of transfer',
                  enum: EaseOfTransfer,
                },
              ].map(item => (
                <div key={item.key} className="w-full  space-y-1">
                  <Label>{item.label}</Label>
                  <Select
                    name={item.key}
                    value={formData[item.key] || ''}
                    onValueChange={value =>
                      setFormData({
                        ...formData,
                        [item.key]: value,
                      })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {Object.values(item.enum).map(flashSeenItem => (
                        <SelectItem key={flashSeenItem} value={flashSeenItem}>
                          {flashSeenItem}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {formErrors.flashSeen && (
                    <small className="text-destructive-500">
                      {formErrors.flashSeen}
                    </small>
                  )}
                </div>
              ))}
            </div>
            <div className="flex-1">
              <div className="w-full space-y-1">
                <Label>Medications administered</Label>
                <Textarea
                  placeholder="Enter here..."
                  id="MedicationsAdministered"
                  name="MedicationsAdministered"
                  rows={6}
                  value={formData.medicationsAdministered || ''}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    inputHandler('medicationsAdministered', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={handleSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={
              isLoading || !formData.embryosTransferredPublicIds?.length
            }
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmbryoTransferModal;

enum FlashSeen {
  Visible = 'Visible',
  NotVisible = 'Not Visible',
  PartiallyVisible = 'Partially Visible',
}

enum CatheterType {
  Soft = 'Soft',
  Firm = 'Firm',
  Medium = 'Medium',
}

enum BladderStatus {
  Full = 'Full',
  Empty = 'Empty',
  PartiallyFull = 'Partially Full',
}

enum UterusPosition {
  Anteverted = 'Anteverted',
  Retroverted = 'Retroverted',
  MidPosition = 'Mid-position',
}

enum EaseOfTransfer {
  Easy = 'Easy',
  Difficult = 'Difficult',
  Moderate = 'Moderate',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MedicationsAdministered {
  Progesterone = 'Progesterone',
  Estrogen = 'Estrogen',
  Gonadotropins = 'Gonadotropins',
  Other = 'Other',
}
