import React, { ReactNode, useEffect, useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Checkbox,
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../UI';
import { DatePicker, notify } from '../../common';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { getLocationResources } from '../../../services/api/endPoints/resources';
import { updateBooking } from '../../../services/api/endPoints/bookings';
import { useDispatch } from 'react-redux';
import { updateResourceBooking } from '../../../redux/slice/resourceBookingSlice';
import { InfoIcon } from 'lucide-react';
import { ERROR_MESSAGE } from '../../../constants/NotificationConstants';
import { updateAppointment } from '../../../redux/slice/todayAppointments';

const AllocateResourceOverviewModal = ({
  event,
  open,
  updateEvent,
  onClose,
}: {
  event: AppointmentEvent;
  open: boolean;
  updateEvent: (appointment: AppointmentEvent) => void;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    new Date(event.appointmentTime)
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(event.appointmentEndTime)
  );
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<string>('');
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('');
  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [forceBooking, setForceBooking] = useState<boolean>();
  const [showForceBookingTooltip, setShowForceBookingTooltip] =
    useState<boolean>(false);

  const handleOnClose = () => {
    setSelectedLocation('');
    setSelectedResource('');
    setResources([]);
    setErrorMessage('');

    onClose();
  };

  const handleUpdateAndClose = () => {
    const updatedLocation = locations.find(
      loc => loc.publicId === selectedLocation
    );
    const updatedResource = resources.find(
      res => res.publicId === selectedResource
    );

    const updatedEvent = {
      ...event,
      location: updatedLocation ? updatedLocation : event.location,
      resource: updatedResource ? updatedResource : event.resource,
      appointmentTime: startDate,
      appointmentEndTime: endDate,
    };

    updateEvent(updatedEvent);
    handleOnClose();
  };

  const handleDateChange = (newDate: Date | null, type: 'start' | 'end') => {
    if (newDate) {
      if (type === 'start') {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (event.location.publicId) {
      setSelectedLocation(event.location.publicId);
    }
    if (event.resource?.publicId) {
      setSelectedResource(event.resource?.publicId);
    }
  }, [event]);

  const calculateDuration = (start: Date, end: Date) => {
    const duration = end.getTime() - start.getTime();

    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    return `${days > 0 ? days + ' day' + (days > 1 ? 's ' : ' ') : ''}${
      hours > 0 ? hours + ' hour' + (hours > 1 ? 's ' : ' ') : ''
    }${minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : ''}`;
  };

  const formatTime = (date: Date) => {
    if (date)
      return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    else return '';
  };

  const fetchResources = async (locationId: string) => {
    const [data, error] = await getLocationResources('', locationId);
    if (data) {
      setLocationErrorMessage('');
      const resourceInfo: Resource[] = data.map((resource: any) => ({
        publicId: resource.publicId,
        name: resource.name,
      }));
      setResources(resourceInfo);
      if (data.length === 0) {
        setLocationErrorMessage('No resources found for the selected location');
      }
    } else {
      notify.error({ title: 'Error Fetching Resource', message: error });
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      fetchResources(selectedLocation);
    }
  }, [selectedLocation]);

  const getForceBookingTooltip = (trigger: ReactNode) => {
    return (
      <TooltipProvider>
        <Tooltip
          open={showForceBookingTooltip}
          onOpenChange={setShowForceBookingTooltip}
        >
          <TooltipTrigger asChild>{trigger}</TooltipTrigger>
          <TooltipContent>
            <p>
              Check this box to override availability and ensure the resource is
              booked regardless of conflicts.
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };
  const updateResourceBookings = async () => {
    setIsLoading(true);

    const bookingData = {
      publicId: event.publicId,
      resourcePublicId: selectedResource || '',
      locationPublicId: selectedLocation || '',
      appointmentTime: startDate,
      appointmentEndTime: endDate,
      forceBooking: forceBooking,
    };

    const [response, error] = await updateBooking(bookingData);
    setIsLoading(false);

    if (response) {
      await dispatch(updateAppointment(response));
      await dispatch(updateResourceBooking(response));
      const resource = resources.find(res => res.publicId === selectedResource);
      notify.success({
        title: 'Booking Updated Successfully',
        message: `Allocated resource '${resource?.name}'`,
      });
      handleUpdateAndClose();
    } else {
      setErrorMessage(error || ERROR_MESSAGE.GENERIC_TRY_AGAIN);
    }
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={handleOnClose}>
        <DialogContent className="p-0 tablet:w-[32rem] md:w-[32rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Allocate resource
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={handleOnClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <div className="p-[1rem]">
            <div className="border border-neutral-100 p-[0.75rem] rounded">
              <h1 className="text-[14px] text-neutral-900 font-medium">
                {event.fullName}
              </h1>
              <h1 className="text-[14px] text-neutral-900 font-medium">
                {event.serviceName}
              </h1>
              <p className="text-sm text-neutral-400 font-medium">
                {`${formatTime(startDate)} - ${formatTime(
                  endDate
                )}, ${calculateDuration(startDate, endDate)}`}
              </p>
              {/* <p className="text-[14px] text-neutral-400 font-medium">₤200</p> */}
              <p className="text-[14px] text-neutral-400 font-medium">
                {event.resource?.name}
              </p>
            </div>
            <div className="pt-2">
              <Label className="pb-1">Location</Label>
              <Select
                value={selectedLocation}
                onValueChange={value => setSelectedLocation(value)}
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Select Location" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {locations.map(location => (
                    <SelectItem
                      key={location.publicId}
                      value={location.publicId}
                    >
                      {location.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {locationErrorMessage && (
                <div className="mt-2 text-red-500 text-sm text-left">
                  {locationErrorMessage}
                </div>
              )}
            </div>
            {resources.length > 0 && (
              <div className="mt-2">
                <Label>Resource</Label>
                <Select
                  // value={selectedResource}
                  onValueChange={value => {
                    setSelectedResource(value);
                  }}
                >
                  <SelectTrigger
                    className="w-full"
                    selectIcon={<DownArrowIcon />}
                  >
                    <SelectValue placeholder="Select Resource" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[999999]">
                    {resources.map(resource => (
                      <SelectItem
                        key={resource.publicId}
                        value={resource.publicId}
                      >
                        {resource.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
            <div className="pt-2 pb-2 flex flex-col gap-2">
              <div>
                <Label>Select Start Date & Time</Label>
                <DatePicker
                  initialValue={event.appointmentTime}
                  variant="dateTime"
                  handleDateChange={date => handleDateChange(date, 'start')}
                />
              </div>
              <div>
                <Label>Select End Date & Time (If Applicable)</Label>
                <DatePicker
                  initialValue={event.appointmentEndTime}
                  variant="dateTime"
                  handleDateChange={date => handleDateChange(date, 'end')}
                />
              </div>
            </div>
            <div className="pt-2 flex flex-col gap-4">
              <div className="flex flex-row items-center gap-[1rem]">
                {getForceBookingTooltip(
                  <div className="flex flex-row items-center gap-2">
                    <Label>Force Booking</Label>
                    <InfoIcon
                      size="1rem"
                      onClick={() => setShowForceBookingTooltip(prev => !prev)}
                    />
                  </div>
                )}
                <Checkbox
                  onCheckedChange={() => {
                    setForceBooking(prev => !prev);
                  }}
                  checked={forceBooking}
                />
              </div>
            </div>
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm text-left">
                {errorMessage}
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={handleOnClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={updateResourceBookings}
              className="w-full"
              variant={'primary'}
              disabled={!selectedLocation || !selectedResource || isLoading}
              isLoading={isLoading}
            >
              Allocate
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AllocateResourceOverviewModal;
