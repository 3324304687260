/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import {
  createHormoneLevel,
  updateHormoneLevel,
} from '../../../../../services/api/endPoints/hormoneLevel';
import { notify } from '../../../../common/Alert';
import { DatePicker } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getReadableDate } from '../../../../../utils/date.utl';
import { HORMONE } from '../../../../../constants/constants';

const HormoneLevelModal = ({
  open,
  onClose,
  ...props
}: AddAndEditHormoneLevelModalProps) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { treatmentCycleId } = useParams();
  const [formData, setFormData] = useState<HormoneLevelForm>({
    resultDate:
      props.type === 'Create'
        ? props.selectedResultDate
          ? new Date(props.selectedResultDate)
          : null
        : null,
    performedByPublicId: user.staff.publicId,
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staffOptions, setStaffOptions] = useState<Record<string, string>>({});
  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);

  useEffect(() => {
    const setInitialData = async () => {
      let currentFormData: HormoneLevelForm = { ...formData };
      const staffOptions: Record<string, string> = {};
      staffs.forEach(staff => {
        staffOptions[staff.publicId] = staff.fullName ?? '';
      });
      setStaffOptions(staffOptions);

      if (props.type === 'Edit') {
        const { selectedHormoneLevel } = props;
        currentFormData = {
          resultDate: new Date(selectedHormoneLevel.resultDate),
          performedByPublicId: selectedHormoneLevel.performedBy.publicId,
          fsh: selectedHormoneLevel.fsh,
          lh: selectedHormoneLevel.lh,
          e2: selectedHormoneLevel.e2,
          p4: selectedHormoneLevel.p4,
          hcg: selectedHormoneLevel.hcg,
          amh: selectedHormoneLevel.amh,
          tsh: selectedHormoneLevel.tsh,
          prolactin: selectedHormoneLevel.prolactin,
        };
      }
      setFormData(currentFormData);
    };
    setInitialData();
  }, []);

  const inputHandler = (name: keyof HormoneLevelForm, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors: Record<string, string> = {};
    if (!formData.performedByPublicId) {
      errors.performedByPublicId = 'Performed by - staff is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    if (props.type === 'Create') {
      const data = {
        treatmentCyclePublicId: treatmentCycleId!,
        ...formData,
      };
      const [res, error] = await createHormoneLevel(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_HORMONE_LEVEL_ADD,
          message: `Hormone levels for the date ${getReadableDate(
            res.resultDate
          )} has been added`,
        });
        props.addHormoneLevel(res);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_HORMONE_LEVEL_ADD,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else if (props.type === 'Edit') {
      const data = {
        publicId: props.selectedHormoneLevel.publicId,
        ...formData,
      };
      const [res, error] = await updateHormoneLevel(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_HORMONE_LEVEL_UPDATE,
          message: `Hormone levels for the date ${getReadableDate(
            res.resultDate
          )} has been updated`,
        });
        props.updateHormoneLevel(res);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_HORMONE_LEVEL_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Edit' ? 'Edit Hormone Level' : 'Add Hormone Level'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <div className="flex-1 p-4 space-y-4 overflow-scroll">
          <div className="w-full space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Result date
            </Label>
            <DatePicker
              variant="fullDate"
              handleDateChange={value => inputHandler('resultDate', value)}
              initialValue={formData.resultDate}
              hasError={!!formErrors.resultDate}
            />
            {formErrors.resultDate && (
              <small className="text-destructive-500">
                {formErrors.resultDate}
              </small>
            )}
          </div>
          <div className="w-full space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Performed by - staff
            </Label>
            <Select
              name="performedByPublicId"
              value={formData.performedByPublicId}
              onValueChange={value =>
                inputHandler('performedByPublicId', value)
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0 z-[9999999]">
                {Object.keys(staffOptions).length > 0 ? (
                  Object.keys(staffOptions).map(publicId => (
                    <SelectItem key={publicId} value={publicId}>
                      {staffOptions[publicId]}
                    </SelectItem>
                  ))
                ) : (
                  <p>No staffs found</p>
                )}
              </SelectContent>
            </Select>
            {formErrors.performedByPublicId && (
              <small className="text-destructive-500">
                {formErrors.performedByPublicId}
              </small>
            )}
          </div>

          {HORMONE.HORMONE_INPUTS.map(input => (
            <div className="w-full space-y-1" key={input.key}>
              <Label>{input.label}</Label>
              <Input
                className="no-spinner"
                type="number"
                trailingIcon={
                  <p className="text-neutral-500 font-semibold text-[14px]">
                    {input.unit}
                  </p>
                }
                value={formData[input.key] ?? ''}
                onChange={e =>
                  inputHandler(
                    input.key,
                    e.target.value ? Number(e.target.value) : null
                  )
                }
              />
              {input.helperText && (
                <p className="text-neutral-500">{input.helperText}</p>
              )}
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={handleSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Edit' ? 'Save Hormone Level' : 'Add Hormone Level'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default HormoneLevelModal;
