export const ERROR_TITLE = {
  GENERIC_FAILURE_TITLE: 'Failed',
  GENERIC_APPTS: 'Failed to fetch appointments',
  GENERIC_DOCS: 'Failed to fetch document',
  GENERIC_PRESC: 'Failed to fetch prescriptions',
  GENERIC_DOWNLOAD_FILE: 'Failed to download file',
  GENERIC_PATIENT_FETCH: 'Failed to fetch patient',
  GENERIC_PARTNER_FETCH: 'Failed to fetch partner',
  GENERIC_PATIENT_SEARCH: 'Failed to search patients',
  GENERIC_PATIENT_CREATE: 'Failed to create patient',
  GENERIC_PATIENT_UPDATE: 'Failed to update patient',
  GENERIC_PATIENT_DELETE: 'Failed to delete patient',
  GENERIC_PATIENT_ALLERGY_FETCH: 'Failed to fetch patient allergies',
  GENERIC_ENABLE_BROWSER_NOTIFICATION: 'Failed to enable notification',
  GENERIC_STAFF_FETCH: 'Failed to fetch the staff',
  GENERIC_STAFF_ADD: 'Failed to add new staff',
  GENERIC_STAFF_UPDATE: 'Failed to update staff',
  GENERIC_STAFF_DELETE: 'Failed to delete staff',
  GENERIC_TEAM_ADD: 'Failed to add new team',
  GENERIC_TEAM_UPDATE: 'Failed to update team',
  GENERIC_TEAM_DELETE: 'Failed to delete team',
  GENERIC_TEAM_FETCH: 'Failed to fetch the team',
  GENERIC_LOCATION_ADD: 'Failed to add new location',
  GENERIC_LOCATION_UPDATE: 'Failed to update location',
  GENERIC_LOCATION_DELETE: 'Failed to delete location',
  GENERIC_PATHWAY_FETCH: 'Failed to fetch the pathway',
  GENERIC_PATHWAY_ADD: 'Failed to add new pathway',
  GENERIC_PATHWAY_UPDATE: 'Failed to update pathway',
  GENERIC_PATHWAY_DELETE: 'Failed to delete pathway',
  GENERIC_SERVICE_ADD: 'Failed to add new service',
  GENERIC_SERVICE_UPDATE: 'Failed to update service',
  GENERIC_SERVICE_DELETE: 'Failed to delete service',
  GENERIC_RESOURCES_FETCH: 'Failed to fetch the resources',
  GENERIC_RESOURCE_ADD: 'Failed to add new resource',
  GENERIC_RESOURCE_UPDATE: 'Failed to update resource',
  GENERIC_RESOURCE_DELETE: 'Failed to delete resource',
  GENERIC_SITE_ADD: 'Failed to add site',
  GENERIC_SITE_UPDATE: 'Failed to update site',
  GENERIC_SITE_DELETE: 'Failed to delete site',
  GENERIC_SITE_FETCH: 'Failed to fetch site data',
  GENERIC_ALL_SITE_FETCH: 'Failed to fetch all sites',
  GENERIC_SIGNED_OUT: 'Signed out',
  GENERIC_NOTE_ADD: 'Failed to add note',
  GENERIC_PATIENT_DETAILS_CREATE: 'Failed to create patient details',
  GENERIC_PATIENT_DETAILS_UPDATE: 'Failed to update patient details',
  GENERIC_PATIENT_ADD_TO_PATHWAY: 'Failed to add to the pathway',
  GENERIC_LAB_TEST_CREATE: 'Failed to book TDL Lab test',
  GENERIC_TREATMENT_ADD: 'Failed to add treatment',
  GENERIC_TREATMENT_UPDATE: 'Failed to update treatment',
  GENERIC_TREATMENT_DELETE: 'Failed to delete treatment',
  GENERIC_ALL_TREATMENT_FETCH: 'Failed to fetch all treatments',
  GENERIC_TREATMENT_CYCLE_START: 'Failed to start treatment cycle',
  GENERIC_TREATMENT_CYCLE_UPDATE: 'Failed to update treatment cycle',
  GENERIC_TREATMENT_CYCLE_DELETE: 'Failed to delete treatment cycle',
  GENERIC_ALL_TREATMENT_CYCLE_FETCH: 'Failed to fetch all treatment cycles',
  GENERIC_RESTART_TREATMENT_CYCLE: 'Failed to restart treatment cycle',
  GENERIC_CANCEL_TREATMENT_CYCLE: 'Failed to cancel treatment cycle',
  GENERIC_END_TREATMENT_CYCLE: 'Failed to end treatment cycle',
  GENERIC_FETCH_TREATMENT_CYCLE: 'Failed to fetch treatment cycle',
  GENERIC_EGG_COLLECTION_ADD: 'Failed to add egg collection',
  GENERIC_EGG_COLLECTION_UPDATE: 'Failed to update egg collection',
  GENERIC_EGG_COLLECTION_DELETE: 'Failed to delete egg collection',
  GENERIC_ALL_EGG_COLLECTION_FETCH: 'Failed to fetch all egg collections',
  GENERIC_SEMEN_ANALYSIS_ADD: 'Failed to add semen analysis',
  GENERIC_SEMEN_ANALYSIS_UPDATE: 'Failed to update semen analysis',
  GENERIC_SEMEN_ANALYSIS_DELETE: 'Failed to delete semen analysis',
  GENERIC_ALL_SEMEN_ANALYSIS_FETCH: 'Failed to fetch all semen analyses',
  GENERIC_HORMONE_LEVEL_ADD: 'Failed to add hormone level',
  GENERIC_HORMONE_LEVEL_UPDATE: 'Failed to update hormone level',
  GENERIC_EMBRYOLOGY_ADD: 'Failed to add embryo',
  GENERIC_EMBRYOLOGY_UPDATE: 'Failed to update embryo',
  GENERIC_EMBRYOLOGY_DELETE: 'Failed to delete embryology record',
  GENERIC_HORMONE_LEVEL_DELETE: 'Failed to delete hormone level',
  GENERIC_FOLLICLE_SCAN_DELETE: 'Failed to delete follicle scan',
  GENERIC_FOLLICLE_SCAN_ADD: 'Failed to add follicle scan',
  GENERIC_FOLLICLE_SCAN_UPDATE: 'Failed to update follicle scan',
  GENERIC_EMBRYOS: 'Failed to get embryos',
  GENERIC_EMBRYO_TRANSFER: 'Failed to update embryo transfer',
};

export const SUCCESS_TITLE = {
  GENERIC_SUCCESS_TITLE: 'Success',
  GENERIC_STAFF_ADD: 'Successfully added new staff',
  GENERIC_STAFF_UPDATE: 'Successfully updated staff',
  GENERIC_STAFF_DELETE: 'Successfully deleted staff',
  GENERIC_TEAM_ADD: 'Successfully added new team',
  GENERIC_TEAM_UPDATE: 'Successfully updated team',
  GENERIC_TEAM_DELETE: 'Successfully deleted team',
  GENERIC_LOCATION_ADD: 'Successfully added new location',
  GENERIC_LOCATION_UPDATE: 'Successfully updated location',
  GENERIC_LOCATION_DELETE: 'Successfully deleted location',
  GENERIC_PATHWAY_ADD: 'Successfully added new pathway',
  GENERIC_PATHWAY_UPDATE: 'Successfully updated pathway',
  GENERIC_PATHWAY_DELETE: 'Successfully deleted pathway',
  GENERIC_SERVICE_ADD: 'Successfully added new service',
  GENERIC_SERVICE_UPDATE: 'Successfully updated service',
  GENERIC_SERVICE_DELETE: 'Successfully deleted service',
  GENERIC_RESOURCE_ADD: 'Successfully added new resource',
  GENERIC_RESOURCE_UPDATE: 'Successfully updated resource',
  GENERIC_RESOURCE_DELETE: 'Successfully deleted resource',
  GENERIC_SITE_ADD: 'Successfully added site',
  GENERIC_SITE_UPDATE: 'Successfully updated site',
  GENERIC_SITE_DELETE: 'Successfully deleted site',
  GENERIC_SIGNED_OUT: 'Signed out',
  GENERIC_NOTE_ADD: 'Successfully added note',
  GENERIC_PATIENT_CREATE: 'Successfully created patient',
  GENERIC_PATIENT_UPDATE: 'Successfully updated patient',
  GENERIC_PATIENT_DETAILS_CREATE: 'Successfully added patient details',
  GENERIC_PATIENT_DETAILS_UPDATE: 'Successfully updated patient details',
  GENERIC_PATIENT_ADD_TO_PATHWAY: 'Successfully added to the pathway',
  GENERIC_LAB_TEST_CREATE: 'Successfully booked TDL Lab test',
  GENERIC_TREATMENT_ADD: 'Successfully added treatment',
  GENERIC_TREATMENT_UPDATE: 'Successfully updated treatment',
  GENERIC_TREATMENT_DELETE: 'Successfully deleted treatment',
  GENERIC_TREATMENT_CYCLE_START: 'Successfully started treatment cycle',
  GENERIC_TREATMENT_CYCLE_UPDATE: 'Successfully updated treatment cycle',
  GENERIC_TREATMENT_CYCLE_DELETE: 'Successfully deleted treatment cycle',
  GENERIC_RESTART_TREATMENT_CYCLE: 'Successfully restarted treatment cycle',
  GENERIC_CANCEL_TREATMENT_CYCLE: 'Successfully cancelled treatment cycle',
  GENERIC_END_TREATMENT_CYCLE: 'Successfully ended treatment cycle',
  GENERIC_EGG_COLLECTION_ADD: 'Successfully added egg collection',
  GENERIC_EGG_COLLECTION_UPDATE: 'Successfully updated egg collection',
  GENERIC_EGG_COLLECTION_DELETE: 'Successfully deleted egg collection',
  GENERIC_SEMEN_ANALYSIS_ADD: 'Successfully added semen analysis',
  GENERIC_SEMEN_ANALYSIS_UPDATE: 'Successfully updated semen analysis',
  GENERIC_SEMEN_ANALYSIS_DELETE: 'Successfully deleted semen analysis',
  GENERIC_HORMONE_LEVEL_ADD: 'Successfully added hormone level',
  GENERIC_HORMONE_LEVEL_UPDATE: 'Successfully updated hormone level',
  GENERIC_EMBRYOLOGY_ADD: 'Successfully added embryology record',
  GENERIC_EMBRYOLOGY_UPDATE: 'Successfully updated embryo',
  GENERIC_EMBRYOLOGY_DELETE: 'Successfully deleted embryo',
  GENERIC_HORMONE_LEVEL_DELETE: 'Successfully deleted hormone level',
  GENERIC_FOLLICLE_SCAN_DELETE: 'Successfully deleted follicle scan',
  GENERIC_FOLLICLE_SCAN_ADDED: 'Successfully added follicle scan',
  GENERIC_FOLLICLE_SCAN_UPDATED: 'Successfully updated follicle scan',
  GENERIC_EMBRYO_TRANSFER: 'Successfully updated embryo transfer',
  OUTCOME: 'Failed to create outcome',
};

export const ERROR_MESSAGE = {
  PASSWORD_UPDATE_FAILED: 'Failed to update the password',
  LOGIN_FAILED: 'Failed to login',
  GENERIC_TRY_AGAIN: 'Please try again later or contact your admin.',
  GENERIC_SIGN_OUT: 'Failed to sign out. Please try again',
  MEDICATION_ADDED: 'Failed to add medication.',
  MEDICATION_DISCONTINUE: 'Failed to discontinue medication.',
};

export const SUCCESS_MESSAGE = {
  PASSWORD_UPDATE_SUCCEEDED: 'Password updated successfully.',
  GENERIC_SIGN_OUT: 'You have been signed out successfully',
  MEDICATION_ADDED: 'Successfully added medication.',
  MEDICATION_DISCONTINUE: 'Successfully discontinued medication.',
  OUTCOME: 'Outcome created successfully!',
};
