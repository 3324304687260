import React from 'react';
import { OnboardingFlow } from '../layout';

import {
  ForgetPassword,
  SignIn,
  TwoFactorAuth,
  ConfirmTwoFactor,
  Overview,
  Patients,
  PatientProfile,
  CreateTest,
  Labs,
  ScannerPage,
  Admin,
  ResourceBooking,
  EmailManager,
  ClinicBoard,
  Settings,
  Unauthorized,
  // ConsultantList,
  BookingLayout,
  LocationsList,
} from '../pages';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/NavigationConstants';
import RequireAuth from './auths/RequireAuth';
import {
  ResourceSchedule,
  patientInfoTabsRouting,
  scannerRoutes,
  clinicManagerTabsRouting,
} from '../components/sections';
import LabResults from '../components/sections/Patient/PatientInfoTabs/Labs/LabResults';
import StaffProfilePictureCamera from '../components/sections/Admin/ClinicManager/Staff/StaffProfilePictureCamera';
import ProfilePicCamera from '../components/sections/Patient/PatientProfile/ProfilePicCamera';
import BookingJourney from '../components/common/Journeys/BookingJourney';

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route element={<OnboardingFlow />}>
        <Route
          path={ROUTES.ROUTE_TO_BASE}
          element={<Navigate to={'signIn'} />}
        />
        <Route path={ROUTES.ROUTE_TO_SIGN_IN} element={<SignIn />} />
        <Route
          path={ROUTES.ROUTE_TO_UPDATE_PASSWORD}
          element={<ForgetPassword />}
        />
        <Route path={ROUTES.ROUTE_TO_SET_2FA} element={<TwoFactorAuth />} />
        <Route
          path={ROUTES.ROUTE_TO_CONFIRM_2FA}
          element={<ConfirmTwoFactor />}
        />
      </Route>
      <Route element={<BookingLayout />}>
        <Route path={ROUTES.BOOKING.CONSULTANTS} element={<BookingJourney />} />
        <Route path={ROUTES.BOOKING.LOCATIONS} element={<LocationsList />} />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedRoles={[
              'ROLE_SUPERADMIN',
              'ROLE_ADMIN',
              'ROLE_SECRETARY',
              'ROLE_CONSULTANT',
            ]}
          />
        }
      >
        <Route path={ROUTES.ROUTE_TO_OVERVIEW} element={<Overview />} />
        <Route path={ROUTES.ROUTE_TO_PATIENT} element={<Patients />} />
        <Route path={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}:id`}>
          <Route element={<PatientProfile />}>{patientInfoTabsRouting()}</Route>
          <Route
            path={ROUTES.ROUTE_TO_SCANNER.SCANNER}
            element={<ScannerPage />}
          >
            {scannerRoutes()}
          </Route>
        </Route>
        <Route
          path={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}:id${ROUTES.ROUTE_TO_PATIENT_PROFILE_PICTURE_CAMERA}`}
          element={<ProfilePicCamera />}
        />
        <Route path={`${ROUTES.LABS}:id`} element={<Overview />}>
          {patientInfoTabsRouting()}
        </Route>
        <Route
          path={ROUTES.ROUTE_TO_RESOURCE_BOOKING_CALENDAR}
          element={<ResourceBooking />}
        />
        <Route
          path={ROUTES.ROUTE_TO_RESOURCE_BOOKING_STAFF_SCHEDULE}
          element={<ResourceSchedule />}
        />
        <Route path={ROUTES.LABS.BASE} element={<Labs />} />
        <Route path={ROUTES.LABS.CREATE} element={<CreateTest />} />
        <Route path={ROUTES.LABS.VIEW} element={<LabResults />} />
        <Route path={ROUTES.CLINIC_BOARD} element={<ClinicBoard />} />
        <Route path={ROUTES.ADMIN.BASE}>
          <Route element={<Admin />}>{clinicManagerTabsRouting()}</Route>
          <Route
            path={`${ROUTES.ADMIN.STAFFS}/${ROUTES.ADMIN.STAFF_TAKE_PICTURE}`}
            element={<StaffProfilePictureCamera />}
          />
        </Route>

        <Route path={ROUTES.EMAIL_MANAGER} element={<EmailManager />} />
        <Route path={ROUTES.SETTINGS} element={<Settings />} />
      </Route>
      <Route path={ROUTES.ROUTE_TO_UNAUTHORIZED} element={<Unauthorized />} />
    </Routes>
  );
};

export default ProjectRoutes;
