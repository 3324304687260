import { URL } from '../requestConstructor';

export const getAllTreatments = async () => {
  try {
    const response = await URL.get('/treatment/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getTreatment = async (publicId: string) => {
  try {
    const response = await URL.get(`/treatment/get?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const createTreatment = async (data: any) => {
  try {
    const response = await URL.post('/treatment/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const updateTreatment = async (data: any) => {
  try {
    const response = await URL.put('/treatment/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const deleteTreatment = async (publicId: any) => {
  try {
    const response = await URL.delete(`/treatment/delete?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getLatestObservation = async (publicId: string) => {
  try {
    const response = await URL.get(
      `/observations/get/latest?treatmentCyclePublicId=${publicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
