import React, { useState } from 'react';
import {
  findMaxHormoneResultDate,
  generateTimeLine,
} from '../../../../../utils/Helper';
import { PenIcon } from 'lucide-react';
import { DeleteIcon, OutlinedAddIcon } from '../../../../../assets/icons';
import { Button } from '../../../../../components/UI';
import HormoneLevelModal from './HormoneLevelModal';
import { THEME } from '../../../../../constants/ColorConstants';
import { deleteHormoneLevel } from '../../../../..//services/api/endPoints/hormoneLevel';
import { notify, AlertDialog } from '../../../../../components/common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import {
  formatToYYYYMMDD,
  getReadableDate,
} from '../../../../../utils/date.utl';
import { HORMONE } from '../../../../../constants/constants';

interface HormoneDetailsProps {
  startDate: string;
  hormoneLevels: HormoneLevel[];
  setHormoneLevels: (levels: HormoneLevel[]) => void;
  timeline: TimeLine[];
  setTimeLine: (timeline: TimeLine[]) => void;
  dateToHormoneLevelMap: Record<string, HormoneLevel>;
  setDateToHormoneLevelMap: (map: Record<string, HormoneLevel>) => void;
}

const HormoneDetails: React.FC<HormoneDetailsProps> = ({
  startDate,
  hormoneLevels,
  setHormoneLevels,
  timeline,
  setTimeLine,
  dateToHormoneLevelMap,
  setDateToHormoneLevelMap,
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [selectedHormoneLevel, setSelectedHormoneLevel] =
    useState<HormoneLevel | null>(null);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const addModalHandler = (date?: string) => {
    if (date) setSelectedDate(date);
    setAddModalOpen(true);
  };

  const editModalHandler = (date: string) => {
    setSelectedHormoneLevel(dateToHormoneLevelMap[date]);
    setEditModalOpen(true);
  };

  const updateHormoneLevel = (data: HormoneLevel) => {
    const newToHormoneLevelMap = { ...dateToHormoneLevelMap };
    const key = formatToYYYYMMDD(new Date(data.resultDate));
    const selectedKey = formatToYYYYMMDD(
      new Date(selectedHormoneLevel!.resultDate)
    );
    if (selectedKey !== key) {
      delete newToHormoneLevelMap[selectedKey];
    }
    newToHormoneLevelMap[key] = data;
    setDateToHormoneLevelMap(newToHormoneLevelMap);
    const newHormoneLevels = [...hormoneLevels];
    const updateIndex = newHormoneLevels.findIndex(
      val => val.publicId === data.publicId
    );
    newHormoneLevels[updateIndex] = data;
    const maxDate = findMaxHormoneResultDate(newHormoneLevels);
    setTimeLine(generateTimeLine(startDate, maxDate));
    setHormoneLevels(newHormoneLevels);
  };

  const addHormoneLevel = (data: HormoneLevel) => {
    const newHormoneLevels = [...hormoneLevels];
    newHormoneLevels.push(data);
    const maxDate = findMaxHormoneResultDate(newHormoneLevels);
    setTimeLine(generateTimeLine(startDate, maxDate));
    setHormoneLevels(newHormoneLevels);
    const newDataToHormoneLevelMap = { ...dateToHormoneLevelMap };
    const key = formatToYYYYMMDD(new Date(data.resultDate));
    newDataToHormoneLevelMap[key] = data;
    setDateToHormoneLevelMap(newDataToHormoneLevelMap);
  };

  const deleteHormoneLevelModalHandler = (date: string) => {
    setSelectedHormoneLevel(dateToHormoneLevelMap[date]);
    setDeleteModal(true);
  };
  const closeDeleteModalHandler = async () => {
    setSelectedHormoneLevel(null);
    setDeleteModal(false);
    setIsDeleting(false);
  };

  const deleteHandler = async () => {
    if (!selectedHormoneLevel) return;
    setIsDeleting(true);
    const [, error] = await deleteHormoneLevel(selectedHormoneLevel.publicId);

    if (!error) {
      const newHormoneLevelMap = { ...dateToHormoneLevelMap };
      const key = formatToYYYYMMDD(new Date(selectedHormoneLevel.resultDate));
      delete newHormoneLevelMap[key];
      const newHormoneLevels = [...hormoneLevels].filter(
        hormoneLevel => hormoneLevel.publicId !== selectedHormoneLevel.publicId
      );
      setDateToHormoneLevelMap(newHormoneLevelMap);
      setHormoneLevels(newHormoneLevels);
      const maxDate = findMaxHormoneResultDate(newHormoneLevels);
      setTimeLine(generateTimeLine(startDate, maxDate));
      notify.success({
        title: SUCCESS_TITLE.GENERIC_HORMONE_LEVEL_DELETE,
        message: `Hormone levels  for the date ${getReadableDate(
          selectedHormoneLevel.resultDate
        )} has been deleted`,
      });
      closeDeleteModalHandler();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_HORMONE_LEVEL_DELETE,
        message: error?.message || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsDeleting(false);
    }
  };

  return (
    <>
      {addModalOpen && (
        <HormoneLevelModal
          addHormoneLevel={addHormoneLevel}
          type="Create"
          selectedResultDate={selectedDate}
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            setSelectedDate(null);
          }}
        />
      )}
      {editModalOpen && selectedHormoneLevel && (
        <HormoneLevelModal
          updateHormoneLevel={updateHormoneLevel}
          type="Edit"
          open={editModalOpen}
          selectedHormoneLevel={selectedHormoneLevel}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedHormoneLevel(null);
          }}
        />
      )}

      {deleteModal && selectedHormoneLevel && (
        <AlertDialog
          type="delete"
          open={deleteModal}
          onClose={closeDeleteModalHandler}
          onAction={deleteHandler}
          alertTitle={`Deleting hormone levels for the date ${getReadableDate(
            selectedHormoneLevel.resultDate
          )}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete hormone levels for the date{' '}
          {getReadableDate(selectedHormoneLevel.resultDate)}?
        </AlertDialog>
      )}

      <>
        <div className="w-full border border-neutral-100 rounded">
          <div className="w-full flex">
            <div className="border-r">
              <div className="border-b border-neutral-100 bg-neutral-200 h-[70px]  w-[170px] p-3 flex items-center justify-center">
                <p className="font-semibold">Timeline</p>
              </div>
              <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
                <p className="font-semibold">Hormones</p>
              </div>
              {HORMONE.HORMONE_INPUTS.map(({ shortLabel, key, color }) => (
                <div
                  key={key}
                  className={`border-b h-[70px] w-[170px] p-3 flex items-center ${color}`}
                >
                  <p className="font-semibold">{`${shortLabel}`}</p>
                </div>
              ))}

              <div className="h-[70px]  w-[170px] p-3 flex items-center bg-orange-300">
                <p className="font-semibold">Action</p>
              </div>
            </div>

            <div className="w-full overflow-y-hidden ">
              <div className="flex flex-row">
                {timeline.map((time, index) => {
                  return (
                    <div key={`${time.label}-${index}`} className="border-r">
                      <div
                        className={'border-b border-neutral-100 h-[70px] p-3'}
                      >
                        <p className="text-neutral-500 font-semibold">
                          {time.label}
                        </p>
                        <p className="text-neutral-500 text-[10px] text-nowrap">
                          {time.date}
                        </p>
                        <p className="text-neutral-500 text-[10px] text-nowrap">
                          {time.day}
                        </p>
                      </div>
                      <div
                        className={
                          'border-b border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                        }
                      >
                        {dateToHormoneLevelMap[time.date] ? (
                          <Button
                            variant={'link'}
                            onClick={() => editModalHandler(time.date)}
                          >
                            <PenIcon size={15} stroke={THEME.PRIMARY_500} />
                          </Button>
                        ) : (
                          <Button
                            variant={'link'}
                            onClick={() => addModalHandler(time.date)}
                          >
                            <OutlinedAddIcon stroke={THEME.PRIMARY_500} />
                          </Button>
                        )}
                      </div>
                      {HORMONE.HORMONE_INPUTS.map(({ key }) => (
                        <div
                          key={key}
                          className="border-neutral-100 h-[70px] border-b p-3 flex items-center justify-center"
                        >
                          {dateToHormoneLevelMap[time.date]?.[key] || ''}
                        </div>
                      ))}

                      <div
                        className={
                          'border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                        }
                      >
                        {dateToHormoneLevelMap[time.date] && (
                          <Button
                            variant={'link'}
                            onClick={() =>
                              deleteHormoneLevelModalHandler(time.date)
                            }
                          >
                            <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="flex items-center justify-center px-3">
                  <Button variant={'link'} onClick={() => addModalHandler()}>
                    <OutlinedAddIcon stroke={THEME.PRIMARY_500} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default HormoneDetails;
