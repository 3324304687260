/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { CrossIcon, RightArrow } from '../../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
} from '../../../UI';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createPatient,
  getAllAllergies,
  getPatientByPublicId,
  searchPatient,
  updatePatient,
} from '../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../utils/Helper';
import DebouncedSearchInput from '../../../../components/UI/DebouncedSearchInput';
import {
  DatePicker,
  notify,
  SelectWithInputAndAddOption,
} from '../../../../components/common';
import { InputFieldType, Section } from '../../../../types/form/type.d';
import {
  formFilled,
  getFormFormatById,
  updateFormFilled,
} from '../../../../services/api';
import SectionForm from './SectionForm';
import {
  createErrorMessage,
  createFormFillFields,
  setNestedState,
} from '../../../../utils/data.helper';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../constants/NotificationConstants';
import { isObject, isString } from 'lodash';
import {
  AddressForm,
  Genders,
  Titles,
} from '../../../../utils/patient.form.helper';
import { getNYearsBackDate } from '../../../../utils/date.utl';
import {
  CONSTANT,
  HEIGHT_UNITS,
  WEIGHT_UNITS,
} from '../../../../constants/constants';

const AddPatientModal: React.FC<AddPatientModalProps> = ({
  open,
  onClose,
  ...props
}: AddPatientModalProps) => {
  const { patientDetailsFormFormat } = useSelector<RootState, ClientEntities>(
    state => state.client.client
  );
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allAllergies, setAllAllergies] = useState<string[]>([]);
  const [patientFormFormat, setPatientFormFormat] = useState<any>();
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>(
    {}
  );
  const [dynamicErrors, setDynamicErrors] = useState<Record<string, string>>(
    {}
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [patientPublicId, setPatientPublicId] = useState(null);
  const [patientDetailsValuesMap, setPatientDetailsValuesMap] = useState<
    Record<string, any>
  >({});
  const [patientPartner, setPatientPartner] = useState<PatientInformation>();
  const [formData, setFormData] = useState<CreatePatientFormInterface>({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNo: '',
    email: '',
    title: '',
    partnerPublicId: '',
    ownerStaffPublicId: '',
    allergies: '',
    allergiesList: [],
    weight: 0,
    weightUnit: 'KG',
    heightCM: 0,
    heightINCH: {
      feet: 0,
      inch: 0,
    },
    heightUnit: 'CM',
  });
  const [patientData, setPatientData] = useState<CreatePatientInterface>({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNo: '',
    email: '',
    title: '',
    partnerPublicId: '',
    ownerStaffPublicId: '',
    allergies: '',
    allergiesList: [],
  });

  const fetchPatientData = async (id: string) => {
    const data = await getPatientByPublicId(id);
    if (data) {
      return data;
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_FETCH,
        message: 'Failed to fetch patient data',
      });
      return null;
    }
  };

  const onPartnerSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      return [];
    }
  };

  const onStaffSearch = async (search: string): Promise<StaffInformation[]> => {
    try {
      return staffs.filter(staff =>
        staff.fullName?.toLowerCase().includes(search.toLowerCase())
      );
    } catch (err) {
      return [];
    }
  };

  const getAllergies = async () => {
    try {
      const [res, err] = await getAllAllergies();

      if (err) {
        setAllAllergies([]);
        return;
      } else {
        const allergies: string[] = res.map((allergy: string) => {
          return allergy;
        });
        setAllAllergies(allergies);
      }
    } catch (err) {
      setAllAllergies([]);
    }
  };

  useEffect(() => {
    getAllergies();
    const getAllFormFormatData = async () => {
      const [response] = await getFormFormatById(
        patientDetailsFormFormat?.publicId
      );
      if (response) {
        setPatientFormFormat(response);
        if (props.type === 'Create') {
          const patientDetailsValuesMap: Record<string, any> = {};
          for (const section of response.sections) {
            for (const field of section.fields) {
              patientDetailsValuesMap[field.publicId] = '';
            }
          }
          setPatientDetailsValuesMap(patientDetailsValuesMap);
        }
      }
    };
    getAllFormFormatData();
  }, [patientDetailsFormFormat?.publicId]);

  useEffect(() => {
    const setPatientInformation = async () => {
      if (props.type !== 'Edit' || !props?.patientId) return;

      const res = await fetchPatientData(props?.patientId);
      if (res && res.data) {
        if (res.data.partnerPublicId) {
          const partner = await fetchPatientData(res.data.partnerPublicId);
          setPatientPartner({
            ...partner.data,
            fullName: getFullName(partner.data),
          });
        }
        setPatientData(res.data);
        setPatientDetailsValuesMap(
          res.data.patientDetailsForm?.formFieldValuesMap
        );
      }
    };
    setPatientInformation();
  }, []);

  useEffect(() => {
    if (patientData) {
      setFormData({
        title: patientData.title ?? '',
        firstName: patientData.firstName ?? '',
        lastName: patientData.lastName ?? '',
        gender: patientData.gender ?? '',
        dateOfBirth: patientData.dateOfBirth
          ? new Date(patientData.dateOfBirth)
          : getNYearsBackDate(18),
        email: patientData.email ?? '',
        phoneNo: patientData.phoneNo ?? '',
        address: {
          street1: patientData.address?.street1 ?? '',
          street2: patientData.address?.street2 ?? '',
          city: patientData.address?.city ?? '',
          state: patientData.address?.state ?? '',
          country: patientData.address?.country ?? '',
          postalCode: patientData.address?.postalCode ?? '',
        },
        partnerPublicId: '',
        ownerStaffPublicId: patientData.ownerStaff?.publicId ?? '',
        allergies: patientData.allergies ?? '',
        allergiesList: patientData.allergies
          ? patientData.allergies.split(',')
          : [],
        weight: patientData.weight ?? 0,
        weightUnit: patientData.weightUnit ?? WEIGHT_UNITS.KG,
        heightUnit: patientData.heightUnit ?? HEIGHT_UNITS.CM,
        heightCM:
          patientData.heightUnit === 'CM' ? (patientData.height ?? 0) : 0,
        heightINCH:
          patientData.heightUnit === 'INCH'
            ? {
                feet: patientData.height
                  ? Math.floor(patientData.height / 12)
                  : 0,
                inch: patientData.height ? patientData.height % 12 : 0,
              }
            : {
                feet: 0,
                inch: 0,
              },
      });
    }
  }, [patientData]);
  const onInputHandler = (publicId: string, value: any) => {
    setPatientDetailsValuesMap(prevData => ({
      ...prevData,
      [publicId]: value,
    }));
    removeErrors(publicId, dynamicErrors, setDynamicErrors);
  };

  const removeErrors = (name: any, state: any, setState: any) => {
    if (!state || !state[name]) return;
    const errors = { ...state };
    delete errors[name];
    setState(errors);
  };

  const validateForm = () => {
    const errors: Record<string, any> = {};
    if (!formData.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!formData.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!formData.gender) {
      errors.gender = 'Gender is required';
    }
    if (!formData.dateOfBirth) {
      errors.dateOfBirth = 'Date of Birth is required';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    } else {
      if (!formData.email.match(CONSTANT.EMAIL_REGEX)) {
        errors.email = 'Provide a valid email';
      }
    }
    if (!formData.phoneNo) {
      errors.phoneNo = 'Contact No is required';
    }
    if (!formData.ownerStaffPublicId) {
      errors.ownerStaffPublicId = 'Consultant is required';
    }

    AddressForm.forEach((field: InputFieldType) => {
      const fieldName = `address.${field.id}`;
      if (field.required && !formData.address[field.id]) {
        errors[fieldName] = `${field.label} is required`;
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getRequestData = () => {
    const {
      allergiesList,
      heightCM,
      heightINCH,
      heightUnit,
      weight,
      weightUnit,
      ...rest
    } = { ...formData };
    const requestData: CreatePatientInterface = {
      ...rest,
    };
    if (allergiesList && allergiesList.length > 0) {
      requestData['allergies'] = allergiesList.join(',');
    }

    if (heightUnit === HEIGHT_UNITS.CM && heightCM && heightCM > 0) {
      requestData['height'] = heightCM;
      requestData['heightUnit'] = heightUnit;
    } else {
      if (heightINCH && (heightINCH.feet !== 0 || heightINCH.inch !== 0)) {
        requestData['height'] = heightINCH
          ? Number(heightINCH.feet) * 12 + Number(heightINCH.inch)
          : 0;
        requestData['heightUnit'] = heightUnit;
      }
    }
    if (weightUnit && weight && weight > 0) {
      (requestData['weight'] = weight),
        (requestData['weightUnit'] = weightUnit);
    }
    return requestData;
  };
  const handleSubmitStep1 = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const [response, error] = await createPatient(getRequestData());
    if (response) {
      setPatientPublicId(response?.publicId);
      notify.success({
        title: SUCCESS_TITLE.GENERIC_PATIENT_CREATE,
        message: `Patient '${response.publicId}' added successfully'`,
      });
      setIsLoading(false);
      if (!patientDetailsFormFormat?.publicId) {
        onClose();
        return;
      }
      setCurrentStep(2);
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_CREATE,
        message: error ? error : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsLoading(false);
    }
  };

  const handleUpdateStep1 = async (e: React.FormEvent) => {
    e.preventDefault();
    if (props.type !== 'Edit') return;
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const [response, error] = await updatePatient({
      ...getRequestData(),
      publicId: patientData.publicId,
    });
    if (response) {
      setPatientPublicId(response?.publicId);
      notify.success({
        title: SUCCESS_TITLE.GENERIC_PATIENT_UPDATE,
        message: `Patient '${patientData.publicId}' updated successfully`,
      });
      setIsLoading(false);
      props.onUpdate(response);
      if (!patientDetailsFormFormat?.publicId) {
        onClose();
        return;
      }
      setCurrentStep(2);
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_UPDATE,
        message: error ? error : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsLoading(false);
    }
  };
  const cleanup = () => {
    setCurrentStep(1);
    setPatientPublicId(null);
    setIsLoading(false);
    onClose();
  };

  const handleSubmitStep2 = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      patientFormFormat?.sections &&
      Object.keys(patientDetailsValuesMap) &&
      patientPublicId
    ) {
      const request = {
        patientPublicId: patientPublicId,
        formFormatPublicId: patientFormFormat?.publicId,
        formFieldValues: createFormFillFields(patientDetailsValuesMap),
      };
      const [response, error] = await formFilled(request);
      if (response) {
        const [res, error] = await updatePatient({
          publicId: patientPublicId,
          patientDetailsFormPublicId: response?.publicId,
        });
        if (res) {
          notify.success({
            title: SUCCESS_TITLE.GENERIC_PATIENT_DETAILS_CREATE,
            message: `Additional details are added for patient '${patientPublicId}'`,
          });
          cleanup();
        } else {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_CREATE,
            message: error.data,
          });
        }
      } else if (isString(error?.data)) {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_CREATE,
          message: error.data,
        });
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_CREATE,
          message: 'Please update the patient for additional information',
        });
        setIsLoading(false);
        return;
      } else if (isObject(error?.data)) {
        notify.error(
          createErrorMessage(
            error.data.formFieldValidationErrors,
            patientFormFormat
          )
        );
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_CREATE,
          message: 'Please update the patient for additional information',
        });
        setIsLoading(false);
        setDynamicErrors(error.data.formFieldValidationErrors);
        return;
      }
    }
  };
  const handleUpdateStep2 = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      patientFormFormat?.sections &&
      Object.keys(patientDetailsValuesMap) &&
      patientPublicId
    ) {
      const request = {
        patientPublicId: patientPublicId,
        publicId: patientData.patientDetailsForm?.publicId,
        formFormatPublicId: patientFormFormat.publicId,
        formFieldValues: createFormFillFields(patientDetailsValuesMap),
      };
      const [response, error] = await updateFormFilled(request);
      if (response) {
        const [res, error] = await updatePatient({
          publicId: patientPublicId,
          patientDetailsFormPublicId: response?.publicId,
        });
        if (res) {
          notify.success({
            title: SUCCESS_TITLE.GENERIC_PATIENT_DETAILS_UPDATE,
            message: `Additional details are updated for patient '${patientPublicId}'`,
          });
          cleanup();
        } else {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_UPDATE,
            message: error.data,
          });
        }
      } else if (isString(error?.data)) {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_UPDATE,
          message: error.data,
        });
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_UPDATE,
          message: 'Please update the patient for additional information',
        });
        setIsLoading(false);
        return;
      } else if (isObject(error?.data)) {
        notify.error(
          createErrorMessage(
            error.data.formFieldValidationErrors,
            patientFormFormat
          )
        );
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_DETAILS_UPDATE,
          message: 'Please update the patient for additional information',
        });
        setIsLoading(false);
        setDynamicErrors(error.data.formFieldValidationErrors);
        return;
      }
    }
  };
  const handleSelectChange = (
    name: keyof CreatePatientInterface,
    value: string | Date | string[]
  ) => {
    if (name === 'allergiesList' && !(value instanceof Date)) {
      setFormData(prevData => ({
        ...prevData,
        [name]: [...value],
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value instanceof Date ? value.toISOString() : value,
      }));
    }
    removeErrors(name, validationErrors, setValidationErrors);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => setNestedState(prevData, name, value));
    removeErrors(name, validationErrors, setValidationErrors);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormData(prevData => ({
        ...prevData,
        dateOfBirth: date,
      }));
      removeErrors('dateOfBirth', validationErrors, setValidationErrors);
    }
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={cleanup}>
        <DialogContent className="p-0 tablet:w-[800px] md:w-[887px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              {currentStep === 1
                ? props.type === 'Create'
                  ? 'Add New Patient'
                  : 'Update patient'
                : props.type === 'Create'
                  ? 'Add Patient Details'
                  : 'Update patient details'}
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={cleanup}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <form
              onSubmit={
                currentStep === 1 ? handleSubmitStep1 : handleSubmitStep2
              }
            >
              {currentStep === 1 ? (
                <div className="p-4 space-y-4">
                  {/* Basic Information */}
                  <h1 className="text-[14px] text-neutral-900 font-medium">
                    Basic Information
                  </h1>
                  <div className="grid grid-cols-7 gap-5">
                    <div className="space-y-1">
                      <Label>Title</Label>
                      <Select
                        name="title"
                        required={false}
                        onValueChange={value =>
                          handleSelectChange('title', value)
                        }
                        value={formData.title}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Title" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {Titles.map((title, index) => (
                            <SelectItem key={index} value={title}>
                              {title}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="col-span-3 space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        First Name
                      </Label>
                      <Input
                        required
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={formData.firstName}
                        hasError={!!validationErrors.firstName}
                      />
                      {validationErrors.firstName && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-span-3 space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        Last Name
                      </Label>
                      <Input
                        required
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={formData.lastName}
                        hasError={!!validationErrors.lastName}
                      />
                      {validationErrors.lastName && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        Gender
                      </Label>

                      <Select
                        onValueChange={value =>
                          handleSelectChange('gender', value)
                        }
                        value={formData.gender}
                      >
                        <SelectTrigger
                          className="w-full"
                          hasError={!!validationErrors.gender}
                        >
                          <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {Genders.map((gender, index) => (
                            <SelectItem key={index} value={gender}>
                              {gender}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {validationErrors.gender && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.gender}
                        </p>
                      )}
                    </div>
                    <div className="space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        Date of Birth
                      </Label>
                      <DatePicker
                        variant="fullDate"
                        handleDateChange={handleDateChange}
                        initialValue={formData.dateOfBirth}
                        hasError={!!validationErrors.dateOfBirth}
                      />
                      {validationErrors.dateOfBirth && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.dateOfBirth}
                        </p>
                      )}
                    </div>

                    <div className="space-y-1">
                      <DebouncedSearchInput
                        label="Partner"
                        onSearch={onPartnerSearch}
                        onSelect={option =>
                          handleSelectChange(
                            'partnerPublicId',
                            option?.publicId
                          )
                        }
                        initialValue={patientPartner}
                      />
                    </div>
                    {staffs && (
                      <div className="space-y-1">
                        <DebouncedSearchInput
                          label={'Consultant'}
                          required={true}
                          onSearch={onStaffSearch}
                          onSelect={option =>
                            handleSelectChange(
                              'ownerStaffPublicId',
                              option?.publicId
                            )
                          }
                          initialValue={patientData.ownerStaff}
                          hasError={!!validationErrors.ownerStaffPublicId}
                        />
                        {validationErrors.ownerStaffPublicId && (
                          <p className="text-destructive-500 text-sm">
                            {validationErrors.ownerStaffPublicId}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="space-y-1">
                      <Label htmlFor="allergies">Allergies</Label>
                      <SelectWithInputAndAddOption
                        name="type"
                        inputPlaceholder="Enter allergy"
                        values={
                          formData.allergiesList ? formData.allergiesList : []
                        }
                        options={allAllergies}
                        setOptions={setAllAllergies}
                        setValues={(values: string[]) =>
                          handleSelectChange('allergiesList', values)
                        }
                        type="multi"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="space-y-1">
                      <Label htmlFor="allergies">Weight</Label>
                      <div className="flex w-full gap-2">
                        <Input
                          name="weight"
                          onChange={handleChange}
                          value={formData.weight}
                          type="number"
                          wrapperClass="w-full"
                        />
                        <Select
                          name="weightUnit"
                          required={false}
                          onValueChange={value =>
                            handleSelectChange('weightUnit', value)
                          }
                          value={formData.weightUnit}
                        >
                          <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent className="bg-shades-0 z-[999999]">
                            {Object.keys(WEIGHT_UNITS).map(
                              (weightUnit, index) => (
                                <SelectItem key={index} value={weightUnit}>
                                  {weightUnit}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <Label htmlFor="allergies">Height</Label>
                      <div className="flex w-full gap-2">
                        {formData.heightUnit === 'CM' && (
                          <Input
                            name="heightCM"
                            onChange={handleChange}
                            value={formData.heightCM}
                            type="number"
                            wrapperClass="w-full"
                          />
                        )}
                        {formData.heightUnit === 'INCH' && (
                          <>
                            <div className="flex w-full items-center gap-1">
                              <Input
                                name="heightINCH.feet"
                                onChange={handleChange}
                                value={formData.heightINCH?.feet}
                                type="number"
                                wrapperClass="w-full"
                              />
                              <p>FEET</p>
                            </div>
                            <Input
                              name="heightINCH.inch"
                              onChange={handleChange}
                              value={formData.heightINCH?.inch}
                              type="number"
                              wrapperClass="w-full"
                            />
                          </>
                        )}
                        <Select
                          name="heightUnit"
                          required={false}
                          onValueChange={value =>
                            handleSelectChange('heightUnit', value)
                          }
                          value={formData.heightUnit}
                        >
                          <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent className="bg-shades-0 z-[999999]">
                            {Object.keys(HEIGHT_UNITS).map(
                              (heightUnit, index) => (
                                <SelectItem key={index} value={heightUnit}>
                                  {heightUnit}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <hr />

                  {/* Contact Details */}
                  <h1 className="text-[14px] text-neutral-900 font-medium">
                    Contact Details
                  </h1>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        Email
                      </Label>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                        hasError={!!validationErrors.email}
                      />
                      {validationErrors.email && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.email}
                        </p>
                      )}
                    </div>
                    <div className="space-y-1">
                      <Label
                        className={
                          'after:content-["*"] after:text-destructive-500'
                        }
                      >
                        Contact No
                      </Label>

                      <Input
                        placeholder="Phone Number"
                        name="phoneNo"
                        onChange={handleChange}
                        className="flex-1"
                        value={formData.phoneNo}
                        hasError={!!validationErrors.phoneNo}
                      />

                      {validationErrors.phoneNo && (
                        <p className="text-destructive-500 text-sm">
                          {validationErrors.phoneNo}
                        </p>
                      )}
                    </div>
                  </div>
                  <hr />

                  {/* Address */}
                  <h1 className="text-[14px] text-neutral-900 font-medium">
                    Address
                  </h1>
                  <div className="grid grid-cols-2 gap-5">
                    {AddressForm.map((item: InputFieldType) => (
                      <div className="space-y-1" key={item.id}>
                        <Label
                          className={
                            item.required
                              ? 'after:content-["*"] after:text-destructive-500'
                              : ''
                          }
                        >
                          {item.label}
                        </Label>
                        <Input
                          placeholder={item.placeholder}
                          id={'address.' + item.id}
                          type={item.type}
                          name={'address.' + item.id}
                          onChange={handleChange}
                          value={formData.address[item.id]}
                          hasError={!!validationErrors[`address.${item.id}`]}
                        />
                        {validationErrors[`address.${item.id}`] && (
                          <p className="text-destructive-500 text-sm">
                            {validationErrors[`address.${item.id}`]}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {patientFormFormat?.sections.map((section: Section) => (
                    <div className="p-4 space-y-4" key={section?.publicId}>
                      <hr />
                      <h1 className="text-[14px] text-neutral-900 font-medium">
                        {section.label}
                      </h1>
                      <SectionForm
                        fields={
                          patientFormFormat?.sections[section.sectionIndex]
                            .fields
                        }
                        onInput={onInputHandler}
                        name={
                          patientFormFormat?.sections.length > 1
                            ? patientFormFormat?.sections[section.sectionIndex]
                                .name
                            : ''
                        }
                        data={patientDetailsValuesMap}
                        validationErrors={dynamicErrors}
                      />
                    </div>
                  ))}
                </>
              )}
            </form>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={cleanup}
            >
              Close
            </Button>
            {currentStep === 1 ? (
              <Button
                size={'small'}
                type={'submit'}
                onClick={
                  props.type === 'Edit' ? handleUpdateStep1 : handleSubmitStep1
                }
                variant={'primary'}
                className="w-full"
                isLoading={isLoading}
                disabled={isLoading}
                trailingIcon={
                  patientDetailsFormFormat?.publicId && <RightArrow />
                }
              >
                {!patientDetailsFormFormat?.publicId
                  ? 'Save'
                  : 'Save & Continue'}
              </Button>
            ) : (
              <Button
                size={'small'}
                type={'submit'}
                onClick={
                  props.type === 'Edit' ? handleUpdateStep2 : handleSubmitStep2
                }
                variant={'primary'}
                className="w-full"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Save
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddPatientModal;
