import React from 'react';
import { Button } from '../../../components/UI';
import Profile from '../../../components/UI/Profile';
import { RightArrow } from '../../../assets/icons';

interface ConsultantCardProps {
  user: UserEntities;
}

const ConsultantCard: React.FC<ConsultantCardProps> = ({ user }) => {
  return (
    <div className="consultant-card  px-[16px] border-b border-neutral-100   justify-between items-center py-[12px]">
      <Profile user={user} />

      <p className="py-[10px]">
        {
          'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidid ut labore incidid labore Cardiology Heart failure treatment Ambulatory blood pressure recording'
        }
      </p>
      <Button
        size={'large'}
        variant={'secondary'}
        className="w-full"
        trailingIcon={<RightArrow />}
      >
        Book Appointment
      </Button>
    </div>
  );
};

export default ConsultantCard;
