import { URL } from '../requestConstructor';

export const getAllEmbryologies = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/embryo/get?treatmentCyclePublicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createEmbryology = async (data: CreateEmbryologyRequest) => {
  try {
    const response = await URL.post('/embryo/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateEmbryology = async (data: UpdateEmbryologyRequest) => {
  try {
    const response = await URL.put('/embryo/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteEmbryology = async (publicId: string) => {
  try {
    const response = await URL.delete(`/embryo/delete?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const getEmbryosByEggCollectionId = async (eggCollectionId: string) => {
  try {
    const response = await URL.get(
      `/embryo/get?eggCollectionPublicId=${eggCollectionId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createEmbryoTransfer = async (data: EmbryoTransferRecord) => {
  try {
    const response = await URL.post('/embryo/transfer/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateEmbryoTransfer = async (data: EmbryoTransferRecord) => {
  try {
    const response = await URL.put('/embryo/transfer/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getTransferredEmbryos = async (
  treatmentCyclePublicId: string
): Promise<[EmbryoTransferRecord[] | null, any]> => {
  try {
    const response = await URL.get(
      `/embryo/transfer/get?treatmentCyclePublicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
