/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { getAppointmentReadableDateTime } from '../../../../../utils/date.utl';

const ShowTransferredEmbryosModal = ({
  open,
  onClose,
  transferredEmbryos,
}: ShowTransferredEmbryosModalProps) => {
  console.log(transferredEmbryos);
  const [displayData, setDisplayData] = useState<
    | {
        label: string;
        value: any;
      }[]
    | null
  >(null);
  useEffect(() => {
    const data = [
      { label: 'Transfer ID', value: transferredEmbryos.publicId },
      {
        label: 'Transfer date',
        value: transferredEmbryos.transferredOn
          ? getAppointmentReadableDateTime(
              new Date(transferredEmbryos.transferredOn)
            )
          : '-',
      },
      { label: 'Requested by', value: transferredEmbryos.requestedBy.fullName },

      { label: 'Performed by', value: transferredEmbryos.signedOffBy.fullName },
      {
        label: 'Signed off by',
        value: transferredEmbryos.performedBy.fullName,
      },

      {
        label: 'Treatment cycle name',
        value: transferredEmbryos.treatmentCycle.treatment.name ?? '-',
      },
      {
        label: 'Embryos transferred',
        value:
          transferredEmbryos.embryosTransferred
            .map((obj: { [x: string]: any }) => obj['publicId'])
            .filter((value: any) => typeof value === 'string')
            .join(', ') || '-',
      },
      {
        label: 'Compliant transfer',
        value: transferredEmbryos.compliantTransfer ? 'Yes' : 'No',
      },
      {
        label: 'Reason for non-compliantTransfer',
        value: transferredEmbryos.reasonForNonCompliance ?? '-',
      },
      {
        label: 'TA scan performed',
        value: transferredEmbryos.taScanPerformed ? 'Yes' : 'No',
      },
      {
        label: 'Findings',
        value: transferredEmbryos.findings ?? '-',
      },
      {
        label: 'Flash seen',
        value: transferredEmbryos.flashSeen ?? '-',
      },
      {
        label: 'Catheter',
        value: transferredEmbryos.catheter ?? '-',
      },
      {
        label: 'Bladder status',
        value: transferredEmbryos.bladderStatus ?? '-',
      },
      {
        label: 'Uterus position',
        value: transferredEmbryos.uterusPosition ?? '-',
      },
      {
        label: 'Ease of transfer',
        value: transferredEmbryos.easeOfTransfer ?? '-',
      },
      {
        label: 'Medications administered',
        value: transferredEmbryos.medicationsAdministered ?? '-',
      },
      {
        label: 'General comments',
        value: transferredEmbryos.generalComments ?? '-',
      },
    ];
    setDisplayData(data);
  }, []);
  const renderData = (label: string, value: string) => {
    return (
      <>
        <div className="cols-span-1">
          <p className="text-[14px] font-semibold text-neutral-500">{label}</p>
        </div>
        <div className="cols-span-1">
          <p className="text-[14px] font-semibold text-neutral-900">{value}</p>
        </div>
      </>
    );
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0   w-[75vw] md:w-[65vw] flex flex-col gap-0 border border-neutral-100 max-h-[90%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Transferred Embryo details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="grid grid-cols-2  p-4  gap-2">
            {displayData &&
              displayData.map(data => renderData(data.label, data.value))}
          </div>
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ShowTransferredEmbryosModal;
