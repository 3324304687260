import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: EggCollectionState = { eggCollections: [] };

export const eggCollectionSlice = createSlice({
  name: 'eggCollections',
  initialState,
  reducers: {
    addEggCollections: (state, action: PayloadAction<EggCollectionType[]>) => {
      state.eggCollections = action.payload;
    },
    deleteEggCollection: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      state.eggCollections = state.eggCollections.filter(
        eggCollection => eggCollection.publicId !== action.payload.publicId
      );
    },
    addEggCollection: (state, action: PayloadAction<EggCollectionType>) => {
      state.eggCollections.push(action.payload);
    },
    updateEggCollection: (state, action: PayloadAction<EggCollectionType>) => {
      const eggCollectionIndex = state.eggCollections.findIndex(
        eggCollection => eggCollection.publicId === action.payload.publicId
      );
      state.eggCollections[eggCollectionIndex] = action.payload;
    },
  },
});

export default eggCollectionSlice.reducer;
export const {
  addEggCollections,
  deleteEggCollection,
  addEggCollection,
  updateEggCollection,
} = eggCollectionSlice.actions;
