/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useState } from 'react';
import { Button, Slider } from '../../UI';
import {
  FilterIcon,
  DotMenu,
  NotePenIcon,
  PaperIcon,
  LockIcon,
  DeleteIcon,
  DirectedArrowIcon,
  OutlinedAddIcon,
} from '../../../assets/icons';
import { DataTable, ActionDropdown } from '../../common';
import { ColumnDef } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import defaultImage from '../../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import { THEME } from '../../../constants/ColorConstants';

const Staff = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );

  const staffColumn: ColumnDef<StaffInformation>[] = [
    {
      accessorKey: 'fullName',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          <div>
            <img
              src={row.getValue('profilePic') || defaultImage}
              className="rounded-full w-[32px] h-[32px] inline mr-2"
            />
            {row.getValue('fullName')}
          </div>
        );
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'username',
      header: 'Username',
    },
    {
      id: 'actions',
      cell: () => {
        const dropDownMenu: {
          label: string;
          Icon: ReactNode;
          action: () => void;
        }[] = [
          {
            label: 'View Details',
            Icon: (
              <div className="rotate-45">
                <DirectedArrowIcon />
              </div>
            ),
            action: () => {},
          },
          {
            label: 'Update Staff',
            Icon: <NotePenIcon />,
            action: () => {},
          },
          {
            label: 'Update Staff',
            Icon: <NotePenIcon />,
            action: () => {},
          },
          {
            label: 'Manage Permission',
            Icon: <PaperIcon />,
            action: () => {},
          },
          {
            label: 'Reset Password',
            Icon: <LockIcon />,
            action: () => {},
          },
          {
            label: 'Delete Staff',
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            action: () => {},
          },
        ];
        return (
          <div className="flex justify-end">
            <ActionDropdown drownDownItems={dropDownMenu}>
              <button className="w-8 h-8 p-0 outline-none border-none">
                <span className="sr-only">Open menu</span>
                <DotMenu />
              </button>
            </ActionDropdown>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full rounded-md border border-neutral-100">
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">Staffs</h2>
        <div className="flex gap-3 items-center justify-center">
          <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button>
          <Button
            variant={'secondary'}
            size={'xmall'}
            trailingIcon={<OutlinedAddIcon stroke={THEME.PRIMARY_600} />}
          >
            Add Staff
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="max-h-[520px]">
        <DataTable columns={staffColumn} data={staffs} isLoading={isLoading} />
      </Slider>
    </div>
  );
};

export default Staff;
