/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  CrossIcon,
  DeleteIcon,
  DotMenu,
  NotePenIcon,
  // FilterIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
  RedoIcon,
  EndIcon,
  DirectedArrowIcon,
} from '../../../../../assets/icons';
import { Button, Slider, buttonVariants } from '../../../../UI';
import { ActionDropdown, DataTable, AlertDialog } from '../../../../common';
import { Link, useParams } from 'react-router-dom';
import {
  cancelTreatmentCycle,
  deleteTreatmentCycle,
  getAllTreatmentCyclesByPatientPublicId,
  restartTreatmentCycle,
} from '../../../../../services/api';
import { getReadableDate, getSimpleDate } from '../../../../../utils/date.utl';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { THEME } from '../../../../../constants/ColorConstants';
import TreatmentCycleModal from './TreatmentCycleModal';
import { useDispatch } from 'react-redux';
import EndTreatmentCycleModal from './EndTreatmentCycleModal';
import { sortTreatmentCycles } from '../../../../../utils/Helper';

const TreatmentCycle = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // const { treatmentCycles } = useSelector<RootState, TreatmentCycleState>(
  //   state => state.treatmentCycles
  // );
  const [isDeleteModalOpen, setIsDeleModalOpen] = useState<boolean>(false);
  const [selectedTreatmentCycle, setSelectedTreatmentCycle] =
    useState<TreatmentCycle | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isStartTreatmentCycleModalOpen, setIsStartTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const [isUpdateTreatmentCycleModalOpen, setIsUpdateTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const [isEndTreatmentCycleModalOpen, setIsEndTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [treatmentCycles, setTreatmentCycles] = useState<TreatmentCycle[]>([]);
  useEffect(() => {
    const getAllTreatmentCycles = async () => {
      if (!id) return;
      setIsLoading(true);
      const [res, error] = await getAllTreatmentCyclesByPatientPublicId(id);
      if (res) {
        setTreatmentCycles(sortTreatmentCycles(res));
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_TREATMENT_CYCLE_FETCH,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getAllTreatmentCycles();
  }, []);

  const treatmentCyclesColumn: ColumnDef<TreatmentCycle>[] = [
    {
      accessorKey: 'treatment.name',
      header: 'Treatment',
      cell: ({ row }) => {
        return (
          <p className="space-x-2">
            <span>{row.original.treatment.name}</span>
            {row.original.endDate ? (
              <span className="rounded-xl border border-neutral-500 px-1.5 text-neutral-500 bg-neutral-100">
                Completed
              </span>
            ) : row.original.cancelledDate ? (
              <span className="rounded-xl border border-destructive-500 bg-destructive-100 px-1.5 text-destructive-500">
                Cancelled
              </span>
            ) : (
              <span className="rounded-xl border border-success-500 bg-success-100 px-1.5 text-success-500">
                Active
              </span>
            )}
          </p>
        );
      },
    },
    {
      accessorKey: 'startDate',

      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Start date
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <>{getReadableDate(row.original.startDate)}</>;
      },
    },
    {
      accessorKey: 'endData',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            End date
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return row.original.endDate ? (
          <>{getReadableDate(row.original.endDate)}</>
        ) : (
          <></>
        );
      },
    },
    {
      accessorKey: 'consultant.fullName',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Consultant
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
    },
    {
      id: 'action',
      cell: ({ row }) => {
        const dropDownMenu: dropDownMenuItem[] = [
          {
            label: row.original.cancelledDate ? 'Restart' : 'Cancel',
            Icon: row.original.cancelledDate ? <RedoIcon /> : <CrossIcon />,
            action: () => {
              toggleCancelRestart(
                row.original.publicId,
                row.original.cancelledDate ? true : false,
                row.original.treatment.name
              );
            },
            disabled: !!row.original.endDate,
          },
          {
            label: 'Delete',
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            action: () => {
              setSelectedTreatmentCycle(row.original);
              setIsDeleModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end gap-8">
            <div className="flex gap-2">
              <Link
                className={buttonVariants({
                  variant: 'outlined',
                  size: 'xmall',
                })}
                to={'view/' + row.original.publicId}
              >
                View <OpenEyeIcon2 />
              </Link>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<NotePenIcon stroke="currentColor" />}
                isLoading={false}
                onClick={() => {
                  setSelectedTreatmentCycle(row.original);
                  setIsUpdateTreatmentCycleModalOpen(true);
                }}
                disabled={!!row.original.endDate}
              >
                Edit
              </Button>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                isLoading={false}
                onClick={() => {
                  setSelectedTreatmentCycle(row.original);
                  setIsEndTreatmentCycleModalOpen(true);
                }}
                disabled={!!row.original.endDate}
                trailingIcon={<EndIcon />}
              >
                End
              </Button>
              <ActionDropdown
                drownDownItems={dropDownMenu}
                side="bottom"
                align="end"
                sideOffset={3}
                alignOffset={18}
              >
                <button className="w-8 h-8 p-0 outline-none border-none">
                  <span className="sr-only">Open menu</span>
                  <DotMenu />
                </button>
              </ActionDropdown>
            </div>
          </div>
        );
      },
    },
  ];
  const toggleCancelRestartTreatmentCycle = (treatmentId: string) => {
    let newTreatmentCycles = [...treatmentCycles];
    const treatmentIndex = newTreatmentCycles.findIndex(
      treatmentCycle => treatmentCycle.publicId === treatmentId
    );
    if (treatmentCycles[treatmentIndex].cancelledDate) {
      delete newTreatmentCycles[treatmentIndex].cancelledDate;
    } else {
      newTreatmentCycles[treatmentIndex].cancelledDate = new Date().toString();
    }
    setTreatmentCycles(
      sortTreatmentCycles(newTreatmentCycles as TreatmentCycle[])
    );
  };
  const addTreatmentCycle = (newTreatmentCycle: TreatmentCycle) => {
    const newTreatmentCycles = [...treatmentCycles, newTreatmentCycle];

    setTreatmentCycles(
      sortTreatmentCycles(newTreatmentCycles as TreatmentCycle[])
    );
  };
  const updateTreatmentCycle = (newTreatmentCycle: TreatmentCycle) => {
    const newTreatmentCycles = [...treatmentCycles];
    const treatmentIndex = newTreatmentCycles.findIndex(
      treatmentCycle => treatmentCycle.publicId === newTreatmentCycle.publicId
    );
    newTreatmentCycles[treatmentIndex] = newTreatmentCycle;
    setTreatmentCycles(
      sortTreatmentCycles(newTreatmentCycles as TreatmentCycle[])
    );
  };
  const toggleCancelRestart = async (
    treatmentId: string,
    isCanceled: boolean,
    treatmentName: string
  ) => {
    if (isCanceled) {
      const [, error] = await restartTreatmentCycle(treatmentId);
      if (!error) {
        toggleCancelRestartTreatmentCycle(treatmentId);
        notify.success({
          title: SUCCESS_TITLE.GENERIC_RESTART_TREATMENT_CYCLE,
          message: `Treatment cycle '${treatmentName}'  has been restarted.`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_RESTART_TREATMENT_CYCLE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else {
      const [, error] = await cancelTreatmentCycle(treatmentId);
      if (!error) {
        toggleCancelRestartTreatmentCycle(treatmentId);
        notify.success({
          title: SUCCESS_TITLE.GENERIC_CANCEL_TREATMENT_CYCLE,
          message: `Treatment cycle '${treatmentName}'  has been cancelled.`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_CANCEL_TREATMENT_CYCLE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedTreatmentCycle(null);
    setIsDeleModalOpen(false);
  };
  const deleteTreatmentCycleForPatient = async () => {
    if (!selectedTreatmentCycle) return;
    setIsDeleting(true);
    const [, error] = await deleteTreatmentCycle(
      selectedTreatmentCycle.publicId
    );
    if (!error) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_TREATMENT_CYCLE_DELETE,
        message: `Treatment cycle '${selectedTreatmentCycle.treatment.name}' has been deleted.`,
      });
      setTreatmentCycles(prev =>
        prev.filter(
          treatmentCycle =>
            treatmentCycle.publicId !== selectedTreatmentCycle.publicId
        )
      );
      closeDeleteModalHandler();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_TREATMENT_CYCLE_DELETE,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsDeleting(false);
    }
  };

  return (
    <>
      {isStartTreatmentCycleModalOpen && (
        <TreatmentCycleModal
          type="Create"
          open={isStartTreatmentCycleModalOpen}
          onClose={() => setIsStartTreatmentCycleModalOpen(false)}
          addTreatmentCycle={addTreatmentCycle}
        />
      )}
      {isUpdateTreatmentCycleModalOpen && selectedTreatmentCycle && (
        <TreatmentCycleModal
          type="Edit"
          open={isUpdateTreatmentCycleModalOpen}
          onClose={() => {
            setIsUpdateTreatmentCycleModalOpen(false);
            setSelectedTreatmentCycle(null);
          }}
          treatmentCycle={selectedTreatmentCycle}
          updateTreatmentCycle={updateTreatmentCycle}
        />
      )}
      {isEndTreatmentCycleModalOpen && selectedTreatmentCycle && (
        <EndTreatmentCycleModal
          open={isEndTreatmentCycleModalOpen}
          onClose={() => {
            setIsEndTreatmentCycleModalOpen(false);
            setSelectedTreatmentCycle(null);
          }}
          treatmentCycle={selectedTreatmentCycle}
          updateTreatmentCycle={updateTreatmentCycle}
        />
      )}
      {isDeleteModalOpen && selectedTreatmentCycle && (
        <AlertDialog
          type="delete"
          open={isDeleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteTreatmentCycleForPatient}
          alertTitle={`Deleting treatment cycle  ${selectedTreatmentCycle.treatment.name}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete treatment cycle{' '}
          {selectedTreatmentCycle.treatment.name}?
        </AlertDialog>
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            Treatment cycle
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setIsStartTreatmentCycleModalOpen(true)}
            >
              Start New Cycle
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={treatmentCyclesColumn}
            data={treatmentCycles}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default TreatmentCycle;
