/* eslint-disable indent */
import React, { useState } from 'react';
import { PenIcon } from 'lucide-react';
import {
  calculateAverage,
  getCommutativeSize,
} from '../../../../../../../utils/Helper';
import { DeleteIcon, OutlinedAddIcon } from '../../../../../../../assets/icons';
import { Button } from '../../../../../../../components/UI';
import { THEME } from '../../../../../../../constants/ColorConstants';
import { AlertDialog, notify } from '../../../../../../../components/common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../../../constants/NotificationConstants';
import {
  formatToYYYYMMDD,
  getReadableDate,
} from '../../../../../../../utils/date.utl';
import AddFollicleScanModal from './AddFollicleScanModal';
import { deleteFollicleScan } from '../../../../../../../services/api';

const FollicleScan: React.FC<FollicleScanProps> = ({
  follicleScans,
  setFollicleScans,
  timeline,
  dateToFollicleScanMap,
  setDateToFollicleScanMap,
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [selectedFollicleScan, setSelectedFollicleScan] =
    useState<FollicleScan | null>(null);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const addModalHandler = (date?: string) => {
    if (date) setSelectedDate(date);
    setAddModalOpen(true);
  };

  const editModalHandler = (date: string) => {
    setSelectedFollicleScan(dateToFollicleScanMap[date]);
    setEditModalOpen(true);
  };

  const updateFollicleScan = (data: FollicleScan) => {
    const newToFollicleScanMap = { ...dateToFollicleScanMap };
    const key = formatToYYYYMMDD(new Date(data.examDate));
    const selectedKey = formatToYYYYMMDD(
      new Date(selectedFollicleScan!.examDate)
    );
    if (selectedKey !== key) {
      delete newToFollicleScanMap[selectedKey];
    }
    newToFollicleScanMap[key] = data;
    setDateToFollicleScanMap(newToFollicleScanMap);
    const newFollicleScans = [...follicleScans];
    setFollicleScans(newFollicleScans);
  };

  const addFollicleScan = (data: FollicleScan) => {
    const newFollicleScans = [...follicleScans];
    newFollicleScans.push(data);
    setFollicleScans(newFollicleScans);

    const key = formatToYYYYMMDD(new Date(data.examDate));
    const newDataToFollicleScanMap = { ...dateToFollicleScanMap };
    newDataToFollicleScanMap[key] = data;
    setDateToFollicleScanMap(newDataToFollicleScanMap);
  };

  const deleteFollicleScanModalHandler = (date: string) => {
    setSelectedFollicleScan(dateToFollicleScanMap[date]);
    setDeleteModal(true);
  };
  const closeDeleteModalHandler = async () => {
    setSelectedFollicleScan(null);
    setDeleteModal(false);
    setIsDeleting(false);
  };

  const deleteHandler = async () => {
    if (!selectedFollicleScan) return;
    setIsDeleting(true);
    const [, error] = await deleteFollicleScan(selectedFollicleScan.publicId);

    if (!error) {
      const newFollicleScanMap = { ...dateToFollicleScanMap };
      const key = formatToYYYYMMDD(new Date(selectedFollicleScan.examDate));
      delete newFollicleScanMap[key];
      const newFollicleScans = [...follicleScans].filter(
        FollicleScan => FollicleScan.publicId !== selectedFollicleScan.publicId
      );
      setDateToFollicleScanMap(newFollicleScanMap);
      setFollicleScans(newFollicleScans);
      notify.success({
        title: SUCCESS_TITLE.GENERIC_FOLLICLE_SCAN_DELETE,
        message: `Follicle scan for the date ${getReadableDate(
          selectedFollicleScan.examDate
        )} has been deleted`,
      });
      closeDeleteModalHandler();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_FOLLICLE_SCAN_DELETE,
        message: error?.message || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsDeleting(false);
    }
  };

  return (
    <>
      {addModalOpen && (
        <AddFollicleScanModal
          addFollicleScan={addFollicleScan}
          type="Create"
          selectedExamDate={selectedDate}
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            setSelectedDate(null);
          }}
        />
      )}
      {editModalOpen && selectedFollicleScan && (
        <AddFollicleScanModal
          updateFollicleScan={updateFollicleScan}
          type="Edit"
          open={editModalOpen}
          selectedFollicleScan={selectedFollicleScan}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedFollicleScan(null);
          }}
        />
      )}

      {deleteModal && selectedFollicleScan && (
        <AlertDialog
          type="delete"
          open={deleteModal}
          onClose={closeDeleteModalHandler}
          onAction={deleteHandler}
          alertTitle={`Deleting follicle scans for the date ${getReadableDate(
            selectedFollicleScan.examDate
          )}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete follicle scans for the date{' '}
          {getReadableDate(selectedFollicleScan.examDate)}?
        </AlertDialog>
      )}

      <div className="w-full border border-neutral-100 rounded">
        <div className="w-full flex">
          <div className="border-r">
            <div className="border-b border-neutral-100 bg-neutral-200 h-[70px]  w-[170px] p-3 flex items-center justify-center">
              <p className="font-semibold">Timeline</p>
            </div>
            <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Follicle Scan</p>
            </div>
            <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Endometrial thickness</p>
            </div>
            <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Follicles - avg</p>
            </div>
            <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Follicles R|L</p>
            </div>
            <div className="border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Lead follicles (Right)</p>
            </div>
            <div className=" border-b h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Lead follicles (Left)</p>
            </div>
            <div className="h-[70px]  w-[170px] p-3 flex items-center">
              <p className="font-semibold">Action</p>
            </div>
          </div>

          <div className="w-full overflow-y-hidden ">
            <div className="flex flex-row">
              {timeline.map((time, index) => (
                <div key={`${time.label}-${index}`} className="border-r">
                  <div className={'border-b border-neutral-100 h-[70px] p-3'}>
                    <p className="text-neutral-500 font-semibold text-[14px]">
                      {time.label}
                    </p>
                    <p className="text-neutral-500 text-[10px] text-nowrap">
                      {time.date}
                    </p>
                    <p className="text-neutral-500 text-[10px] text-nowrap">
                      {time.day}
                    </p>
                  </div>
                  <div
                    className={
                      'border-b border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                    }
                  >
                    {dateToFollicleScanMap[time.date] ? (
                      <Button
                        variant={'link'}
                        onClick={() => editModalHandler(time.date)}
                      >
                        <PenIcon size={15} stroke={THEME.PRIMARY_500} />
                      </Button>
                    ) : (
                      <Button
                        variant={'link'}
                        onClick={() => addModalHandler(time.date)}
                      >
                        <OutlinedAddIcon stroke={THEME.PRIMARY_500} />
                      </Button>
                    )}
                  </div>
                  <div
                    className={
                      'border-b border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                    }
                  >
                    {(() => {
                      const avg = calculateAverage(
                        dateToFollicleScanMap[time.date]?.endometrium
                          ?.thickness1 || 0,
                        dateToFollicleScanMap[time.date]?.endometrium
                          ?.thickness2 || 0,
                        dateToFollicleScanMap[time.date]?.endometrium
                          ?.thickness3 || 0
                      );

                      return avg && avg.toFixed(2) !== '0.00'
                        ? avg.toFixed(2)
                        : '';
                    })()}
                  </div>
                  <div
                    className={
                      'border-b border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                    }
                  >
                    {dateToFollicleScanMap[time.date]?.follicles?.length || ''}
                  </div>
                  <div
                    className={
                      'border-b border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                    }
                  >
                    {dateToFollicleScanMap[time.date]?.follicles
                      ? dateToFollicleScanMap[time.date]?.follicles?.filter(
                          i => !i.left
                        )?.length +
                        '|' +
                        dateToFollicleScanMap[time.date]?.follicles?.filter(
                          i => i.left
                        )?.length
                      : ''}
                  </div>
                  <div
                    className={
                      'border-neutral-100 h-[70px] border-b p-3 flex items-center justify-center'
                    }
                  >
                    {getCommutativeSize(
                      dateToFollicleScanMap[time.date]?.follicles?.filter(
                        i => !i.left
                      )
                    ) || ''}
                  </div>
                  <div
                    className={
                      'border-neutral-100 h-[70px] border-b p-3 flex items-center justify-center'
                    }
                  >
                    {getCommutativeSize(
                      dateToFollicleScanMap[time.date]?.follicles?.filter(
                        i => i.left
                      )
                    ) || ''}
                  </div>
                  <div
                    className={
                      'border-neutral-100 h-[70px] p-3 flex items-center justify-center'
                    }
                  >
                    {dateToFollicleScanMap[time.date] && (
                      <Button
                        variant={'link'}
                        onClick={() =>
                          deleteFollicleScanModalHandler(time.date)
                        }
                      >
                        <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <div className="flex items-center justify-center px-3">
                <Button variant={'link'} onClick={() => addModalHandler()}>
                  <OutlinedAddIcon stroke={THEME.PRIMARY_500} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollicleScan;
