export const CONSTANT = {
  FEMALE: 'Female',
  BLOCKED: 'Blocked',
  EMAIL_REGEX:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};

export const RESULT_STATUS = {
  PENDING: 'Pending',
  RESULT_AVAILABLE: 'Results Available',
};

export const PATHWAY_DEADLINE_STATUS = {
  DEADLINE_CROSSED: 'Deadline crossed',
};

export const ALERT = {
  SUCCESS: {},
  FAILURE: {},
};

export const PAYMENT_STATUS = {
  PAID: 'Paid',
  NOTPAID: 'notPaid',
};

export const dayNames = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const HEIGHT_UNITS = {
  CM: 'CM',
  INCH: 'INCH',
};

export const WEIGHT_UNITS = {
  KG: 'KG',
  LBS: 'LBS',
};

export const day1Day2Options: string[] = [
  '2PN',
  '1PN',
  '3PN',
  '0PN',
  'Methaphase I',
  'GV',
  'Fractured zona',
];

export const day3ToDay8Options: string[] = [
  '6AA',
  '5AA',
  '4AA',
  '3AA',
  '2AA',
  '1AA',
  '6AB',
  '5AB',
  '4AB',
  '3AB',
  '2AB',
  '1AB',
  '6BB',
  '5BB',
  '4BB',
  '3BB',
  '2BB',
  '1BB',
  '6BC',
  '5BC',
  '4BC',
  '3BC',
  '2BC',
  '1BC',
  '6CC',
  '5CC',
  '4CC',
  '3CC',
  '2CC',
  '1CC',
];

export const fateOptions: string[] = [
  'Cryo',
  'Discard',
  'Donate',
  'Not defined',
];

export const ploidyOptions: string[] = [
  '2N(diploid)',
  '3N (triploid)',
  '4N(tetraploid)',
  'Aneuploid',
  'Mosaic',
  'N(haploid)',
  'Other',
];

export const embryologyTableLabels: string[] = [
  'Embryo ID',
  'Ooctye maturity',
  'Egg collection',
  'Cycle',
  'Date collected thawed',
  'Day 1',
  'Day 2',
  'Day 3',
  'Day 4',
  'Day 5',
  'Day 6',
  'Day 7',
  'Day 8',
  'Ploidy',
  'Fate',
  'Straw',
  'Notes',
  'Action',
];

export const EndometriumOptions: string[] = [
  'Smith: Grade A',
  'Smith: Grade B',
  'Smith: Grade C',
  'Smith: Grade D',
  'Menstrual',
  'Proliferative / Degenerative',
  'Triple Layer',
  'Secretory',
];
export const thicknessLabels: Record<string, string> = {
  thickness1: 'M1',
  thickness2: 'M2',
  thickness3: 'M3',
};

export const follicleLabels: Record<string, string> = {
  diameter1: 'D1(mm)',
  diameter2: 'D2(mm)',
  diameter3: 'D3(mm)',
};

export const MEDICATION_CONSTANTS = {
  DOSAGE_FREQUENCIES: [
    {
      label: 'Once',
      value: 0,
    },
    {
      label: 'Once a day',
      value: 1,
    },
    {
      label: 'Every other day',
      value: 2,
    },
    {
      label: 'Every third day',
      value: 3,
    },
    {
      label: 'Every fourth day',
      value: 4,
    },
    {
      label: 'Every fifth day',
      value: 5,
    },
    {
      label: 'Every sixth day',
      value: 6,
    },
    {
      label: 'Once a week',
      value: 7,
    },
  ],
};

const HORMONE_INPUTS: HormoneInputData[] = [
  {
    label: 'FSH level',
    shortLabel: 'FSH level',
    unit: 'mlU/mL',
    key: 'fsh',
    color: 'bg-destructive-400',
  },
  {
    label: 'E2/Estrogen level',
    unit: 'pmol/L',
    key: 'e2',
    helperText: 'Reference range: 30.0 - 400.0',
    color: 'bg-primary-400',
    shortLabel: 'E2 level',
  },
  {
    label: 'Progesterone level',
    unit: 'mlU/mL',
    key: 'p4',
    color: 'bg-indigo-400',
    shortLabel: 'Progesterone level',
  },
  {
    label: 'LH level',
    unit: 'nmol/L',
    key: 'lh',
    color: 'bg-purple-400',
    shortLabel: 'LH level',
  },
  {
    label: 'Human chorionic gonadotropin (hCG)',
    unit: 'mlU/mL',
    key: 'hcg',
    color: 'bg-neutral-400',
    shortLabel: 'hCG level',
  },
  {
    label: 'Anti-Müllerian hormone (AMH)',
    unit: 'ng/mL',
    key: 'amh',
    color: 'bg-success-400',
    shortLabel: 'AMH level',
  },
  {
    label: 'Thyroid-stimulating hormone (TSH)',
    unit: 'mIU/L',
    key: 'tsh',
    color: 'bg-warning-400',
    shortLabel: 'TSH level',
  },
  {
    label: 'Prolactin',
    unit: 'ng/mL',
    key: 'prolactin',
    color: 'bg-info-400',
    shortLabel: 'Prolactin level',
  },
];

export const HORMONE = {
  HORMONE_INPUTS,
};
