import React, { useState } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';

import { FormatType } from '../../../../types/form/type.d';
import { PATIENT_ROUTES } from '../../../../constants/NavigationConstants';
import EmbryoTransfer from './Embryology/EmbryoTransfer';
import { Labs } from '../../../../pages';
import Appointment from './Appointment/Appointment';
import AppointmentReportView from './Appointment/AppointmentReportView';
import Documents from './Documents/Documents';
import DocumentView from './Documents/DocumentView';
import EggCollections from './EggCollection/EggCollections';
import Embryology from './Embryology/Embryology';
import HandWrittenNotes from './HandWrittenNotes/HandWrittenNotes';
import LabResults from './Labs/LabResults';
import LabTestForm from './Labs/LabTestForm';
import CreateMedicalNotes from './MedicalNotes/CreateMedicalNotes';
import MedicalNotes from './MedicalNotes/MedicalNotes';
import MedicalReportView from './MedicalNotes/MedicalReportView';
import Notes from './Notes/Notes';
import PrescriptionForm from './Prescription/PrescriptionForm';
import Prescriptions from './Prescription/Prescriptions';
import PrescriptionView from './Prescription/PrescriptionView';
import Recommended from './Recommended/Recommended';
import SemenAnalysis from './SemenAanalysis/SemenAnalysis';
import TreatmentCycle from './TreatmentCycle/TreatmentCycle';
import TreatmentCycleView from './TreatmentCycle/TreatmentCycleView';

const PatientInfoTabsRouting = () => {
  const patient = useSelector<RootState, PatientInformation>(
    state => state.patient.patient
  );

  const [key, setKey] = useState<number>(0);

  const resetForm = () => {
    setKey(prevKey => prevKey + 1);
  };

  return (
    <>
      <Route
        index
        element={<Navigate to={PATIENT_ROUTES.ADMIN_FORMS.HOME} replace />}
      />
      <Route
        path={PATIENT_ROUTES.ADMIN_FORMS.HOME}
        element={
          <MedicalNotes
            key={FormatType.ADMIN_NOTE}
            type={FormatType.ADMIN_NOTE}
          />
        }
      />
      <Route
        path={`${PATIENT_ROUTES.ADMIN_FORMS.CREATE}/:formatId`}
        element={<CreateMedicalNotes />}
      />
      <Route
        path={`${PATIENT_ROUTES.ADMIN_FORMS.VIEW}/:publicId`}
        element={<MedicalReportView />}
      />
      <Route
        path={PATIENT_ROUTES.MEDICAL_REPORTS.HOME}
        element={<MedicalNotes key={FormatType.MEDICAL_NOTE} />}
      />
      <Route
        path={`${PATIENT_ROUTES.MEDICAL_REPORTS.CREATE}/:formatId`}
        element={<CreateMedicalNotes />}
      />
      <Route
        path={`${PATIENT_ROUTES.MEDICAL_REPORTS.VIEW}/:publicId`}
        element={<MedicalReportView />}
      />
      <Route
        path={PATIENT_ROUTES.HANDWRITTEN_NOTES.HOME}
        element={
          <MedicalNotes
            key={FormatType.ADMIN_NOTE}
            type={FormatType.ADMIN_NOTE}
          />
        }
      />
      <Route
        path={`${PATIENT_ROUTES.HANDWRITTEN_NOTES.CREATE}/:formatId`}
        element={<HandWrittenNotes />}
      />
      <Route path={PATIENT_ROUTES.RECOMMENDED.HOME} element={<Recommended />} />
      <Route path={PATIENT_ROUTES.APPOINTMENT.HOME} element={<Appointment />} />
      <Route
        path={`${PATIENT_ROUTES.APPOINTMENT.VIEW}/:reportId`}
        element={<AppointmentReportView />}
      />
      <Route
        path={PATIENT_ROUTES.TREATMENT_CYCLE.HOME}
        element={<TreatmentCycle />}
      />
      <Route
        path={`${PATIENT_ROUTES.TREATMENT_CYCLE.VIEW}/:treatmentCycleId`}
        element={<TreatmentCycleView />}
      />
      <Route
        path={`${PATIENT_ROUTES.TREATMENT_CYCLE.VIEW}/:treatmentCycleId/${PATIENT_ROUTES.EGG_COLLECTION.HOME}`}
        element={<EggCollections />}
      />
      <Route
        path={`${PATIENT_ROUTES.TREATMENT_CYCLE.VIEW}/:treatmentCycleId/${PATIENT_ROUTES.SEMEN_ANALYSIS.HOME}`}
        element={<SemenAnalysis />}
      />
      <Route
        path={`${PATIENT_ROUTES.TREATMENT_CYCLE.VIEW}/:treatmentCycleId/${PATIENT_ROUTES.EMBRYOLOGY.HOME}`}
        element={<Embryology />}
      />
      <Route
        path={`${PATIENT_ROUTES.TREATMENT_CYCLE.VIEW}/:treatmentCycleId/${PATIENT_ROUTES.EMBRYOLOGY.HOME}/${PATIENT_ROUTES.EMBRYOLOGY.EMBRYO_TRANSFER}`}
        element={<EmbryoTransfer />}
      />
      <Route path={PATIENT_ROUTES.LABS.HOME} element={<Labs />} />
      <Route
        path={PATIENT_ROUTES.LABS.ADD}
        element={
          <LabTestForm key={key} patient={patient} onReset={resetForm} />
        }
      />
      <Route path={PATIENT_ROUTES.LABS.VIEW} element={<LabResults />} />
      <Route
        path={PATIENT_ROUTES.PRESCRIPTION.HOME}
        element={<Prescriptions />}
      />
      <Route
        path={PATIENT_ROUTES.PRESCRIPTION.CREATE}
        element={<PrescriptionForm />}
      />
      <Route
        path={`${PATIENT_ROUTES.PRESCRIPTION.VIEW}/:prescriptionId`}
        element={<PrescriptionView />}
      />
      {/* TODO: Need to add id based routing */}
      <Route path={PATIENT_ROUTES.DOCUMENTS.HOME} element={<Documents />} />
      <Route
        path={`${PATIENT_ROUTES.DOCUMENTS.VIEW}/:fileId`}
        element={<DocumentView />}
      />
      <Route path={PATIENT_ROUTES.NOTES.HOME} element={<Notes />} />
    </>
  );
};

export default PatientInfoTabsRouting;
