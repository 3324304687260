import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
  Textarea,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import {
  createEggCollection,
  updateEggCollection,
} from '../../../../../services/api/endPoints/eggCollection';
import {
  addEggCollection,
  updateEggCollection as updateEggCollectionRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../common/Alert';
import { DatePicker } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

const EggCollectionModal = ({
  open,
  onClose,
  ...props
}: AddAndEditEggCollectionModalProps) => {
  const { treatmentCycleId } = useParams();
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { id } = useParams();
  const patient = useSelector<RootState, PatientState>(state => state.patient);
  const partner = useSelector<RootState, PartnerState>(state => state.partner);
  const [formData, setFormData] = useState<EggCollectionForm>({
    collectionDate: new Date(),
    performedByPublicId: user.staff.publicId,
    embryologyNotes: '',
    patientPublicId: !patient.patient.partnerPublicId ? id! : '',
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);

  useEffect(() => {
    const setInitialData = async () => {
      if (!id) return;
      if (props.type === 'Edit') {
        const currentFormData = {
          collectionDate: props.eggCollection.collectionDate
            ? new Date(props.eggCollection.collectionDate)
            : new Date(),
          performedByPublicId: props.eggCollection.performedBy.publicId,
          embryologyNotes: props.eggCollection.embryologyNotes ?? '',
          follicles: props.eggCollection.follicles,
          eggs: props.eggCollection.eggs,
          matureEggs: props.eggCollection.matureEggs,
          inseminatedEggs: props.eggCollection.inseminatedEggs,
          patientPublicId: props.eggCollection.patient.publicId,
        };
        setFormData(currentFormData);
      }
    };
    setInitialData();
  }, []);

  const inputHandler = (name: keyof EggCollectionForm, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      delete errors[name];
    }
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors = { ...formErrors };
    if (!formData.performedByPublicId || formData.performedByPublicId === '') {
      errors.performedByPublicId = 'Performed by - staff is required';
    }
    if (!formData.patientPublicId || formData.patientPublicId === '') {
      errors.patientPublicId = 'Patient is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async () => {
    if (!isValidInput()) return;
    if (props.type === 'Create') {
      const data = {
        treatmentCyclePublicId: treatmentCycleId,
        ...formData,
      };
      setIsLoading(true);
      const [res, error] = await createEggCollection(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EGG_COLLECTION_ADD,
          message: `Egg collection '${res.publicId}' has been added`,
        });
        dispatch(addEggCollection(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_EGG_COLLECTION_ADD,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    } else if (props.type === 'Edit') {
      const data = {
        publicId: props.eggCollection.publicId,
        ...formData,
      };
      setIsLoading(true);
      const [res, error] = await updateEggCollection(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EGG_COLLECTION_UPDATE,
          message: `Egg collection '${res.publicId}' has been updated`,
        });
        dispatch(updateEggCollectionRedux(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_EGG_COLLECTION_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              {props.type === 'Edit'
                ? 'Edit egg collection'
                : 'Add egg collection'}
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-4 space-y-4">
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Performed by - staff
                </Label>
                <Select
                  name="performedByPublicId"
                  value={formData.performedByPublicId}
                  onValueChange={value =>
                    inputHandler('performedByPublicId', value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {staffs.length > 0 ? (
                      staffs.map(staff => (
                        <SelectItem key={staff.publicId} value={staff.publicId}>
                          {staff.fullName}
                        </SelectItem>
                      ))
                    ) : (
                      <p>No staffs found to perform egg collection</p>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.performedByPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.performedByPublicId}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Patient
                </Label>
                <Select
                  name="patientPublicId"
                  value={formData.patientPublicId}
                  onValueChange={value =>
                    inputHandler('patientPublicId', value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    <SelectItem value={patient.patient.publicId}>
                      {patient.patient.fullName}
                    </SelectItem>
                    {partner.partner.publicId && (
                      <SelectItem value={partner.partner.publicId}>
                        {partner.partner.fullName} - partner
                      </SelectItem>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.patientPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.patientPublicId}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Collection data
                </Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value =>
                    inputHandler('collectionDate', value)
                  }
                  initialValue={formData.collectionDate}
                  hasError={!!formErrors.collectionDate}
                />
                {formErrors.collectionDate && (
                  <small className="text-destructive-500">
                    {formErrors.collectionDate}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label>Embryology notes</Label>
                <Textarea
                  value={formData.embryologyNotes}
                  onChange={e =>
                    inputHandler('embryologyNotes', e.target.value)
                  }
                />
              </div>

              <div className="w-full space-y-1">
                <Label>Follicles</Label>
                <Input
                  className="no-spinner"
                  value={formData.follicles}
                  type="number"
                  onChange={e => inputHandler('follicles', e.target.value)}
                />
              </div>
              <div className="w-full space-y-1">
                <Label>Eggs collected</Label>
                <Input
                  value={formData.eggs}
                  className="no-spinner"
                  type="number"
                  onChange={e => inputHandler('eggs', e.target.value)}
                />
              </div>
              <div className="w-full space-y-1">
                <Label>Eggs matured</Label>
                <Input
                  value={formData.matureEggs}
                  className="no-spinner"
                  type="number"
                  onChange={e => inputHandler('matureEggs', e.target.value)}
                />
              </div>
              <div className="w-full space-y-1">
                <Label>Eggs inseminated</Label>
                <Input
                  className="no-spinner"
                  value={formData.inseminatedEggs}
                  type="number"
                  onChange={e =>
                    inputHandler('inseminatedEggs', e.target.value)
                  }
                />
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              variant={'primary'}
              onClick={handleSubmit}
              className="w-full"
              isLoading={isLoading}
              disabled={isLoading}
            >
              {props.type === 'Edit'
                ? 'Edit egg collection'
                : 'Add egg collection'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EggCollectionModal;
