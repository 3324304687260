/* eslint-disable quotes */
import { format } from 'date-fns';

const getNextDate = (date: Date) => {
  const nextDate = new Date(date);
  nextDate.setDate(date.getDate() + 1);
  return nextDate;
};

const getPreviousDate = (date: Date) => {
  const previousDate = new Date(date);
  previousDate.setDate(date.getDate() - 1);
  return previousDate;
};

const getTimestamp = () => {
  const currentDate = new Date();
  const timestampInMs = currentDate.getTime();
  return timestampInMs;
};

const addDaysToDateTime = (
  dateTimeString: Date,
  days: number
): { newDate: string; isPast: boolean } => {
  // Convert the dateTimeString to a Date object
  const startDate = new Date(dateTimeString);

  // Calculate the end date by adding days to the start date
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + days);

  // Check if the endDate is greater than the current date (Date.now())
  const isPast = endDate.getTime() < Date.now();

  // Get the locale options for date and time formatting
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',

    hour12: false,
  };

  // Convert the Date object to a formatted string using the locale options
  const formattedDateTime = endDate.toLocaleString(undefined, options);

  // Return an object with the new date value and a boolean indicating if it's in the past or not
  return {
    newDate: formattedDateTime,
    isPast: isPast,
  };
};

const formatToDateTimeCalendar = (date: Date): string => {
  try {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      // timeZone: 'UTC', // Use the desired time zone here
    };

    return new Intl.DateTimeFormat('en-UK', options).format(date).toUpperCase();
  } catch (err) {
    return '';
  }
};

const addMinutesToDateTime = (
  dateString: string,
  additionalMin: number
): string => {
  try {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() + additionalMin * 60000); // 60000 milliseconds in a minute

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      // timeZone: 'UTC', // Use the desired time zone here
    };

    return new Intl.DateTimeFormat('en-UK', options)
      .format(newDate)
      .toUpperCase();
  } catch (err) {
    console.error(dateString, err);
    return '';
  }
};
const getHumanReadableTime = (time: string | number): string => {
  const inputDate = new Date(time);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('en-UK', options).format(inputDate);
};

//input "2024-10-07", new Date('2024-10-07T12:00:00Z'), etc
//output 7 Oct 2024
const getReadableDate = (dateString: string | Date): string => {
  try {
    const inputDate = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      // weekday: 'short',
      month: 'short',
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-UK', options).format(inputDate) || '';
  } catch (err) {
    return '';
  }
};

//input  new Date('2024-10-07T12:00:00Z'), etc
//output 7 Oct 2024
const getReadableDateString = (date: Date): string => {
  try {
    const inputDate = date;
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      //weekday: 'short',
      month: 'short',
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-UK', options).format(inputDate) || '';
  } catch (err) {
    return '';
  }
};
// new Date('2024-10-08T14:30:00'),string 'HH:mm:ss' the output would be "02:30 PM".
const getReadableTime = (input: Date | string): string => {
  try {
    let inputDate: Date;

    // Check if input is a string in 'HH:mm:ss' format
    if (typeof input === 'string' && /^\d{2}:\d{2}:\d{2}$/.test(input)) {
      const [hours, minutes] = input.split(':').map(Number);
      const today = new Date();
      inputDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hours,
        minutes
      );
    } else if (input instanceof Date) {
      inputDate = input;
    } else {
      throw new Error('Invalid input');
    }

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    return (
      new Intl.DateTimeFormat('en-UK', options)
        .format(inputDate)
        .toLocaleUpperCase() || ''
    );
  } catch (err) {
    return '';
  }
};

const getAppointmentReadableDateTime = (date: Date): string => {
  return format(date, "d MMM yyyy 'at' h:mm aa");
};

//input  new Date('2024-10-07T12:00:00Z'), etc
//output 7 Oct 2024
const getSimpleDate = (date: Date): string => {
  return format(date, 'd MMM yyyy');
};

const formatDateTime = (start: Date, end: Date) => {
  const startDateTime = format(start, 'EEE, MMM d • HH:mm');
  const endTime = format(end, 'HH:mm');
  return `${startDateTime} - ${endTime}`;
};

const getNYearsBackDate = (n: number) => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - n);
  return currentDate;
};

// Input: new Date('2024-10-07')
// Output: "2024-10-07"
// Input: new Date('2021-02-01T10:00:00Z')
// Output: "2021-02-01"
const formatToYYYYMMDD = (date: Date): string => {
  const dateFormatter = new Intl.DateTimeFormat('en-UK', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const [day, month, year] = dateFormatter.format(date).split('/');
  return `${year}-${month}-${day}`;
};

const getLatestRecord = (records: any[], key: string) => {
  if (!records || records.length === 0) {
    return null;
  }

  let latestRecord = records[0];
  let latestDate: Date = new Date(latestRecord[key]);

  for (const record of records) {
    const currentDate: Date = new Date(record[key]);

    if (currentDate > latestDate) {
      latestRecord = record;
      latestDate = currentDate;
    }
  }

  return latestRecord;
};

const getLatestDayValue = (obj: { [key: string]: any }) => {
  let latestDayValue: string | null = null;
  let latestDayKey: string | null = null;
  let latestDayNumber = 0;

  for (const key in obj) {
    if (key.startsWith('day') && !isNaN(Number(key.slice(3)))) {
      const dayNumber = Number(key.slice(3));
      if (dayNumber > latestDayNumber) {
        latestDayKey = key;
        latestDayValue = obj[key];
        latestDayNumber = dayNumber;
      }
    }
  }

  return [latestDayKey, latestDayValue];
};
export {
  getReadableDate,
  getReadableTime,
  getHumanReadableTime,
  addMinutesToDateTime,
  formatToDateTimeCalendar,
  addDaysToDateTime,
  getTimestamp,
  getPreviousDate,
  getNextDate,
  getSimpleDate,
  getAppointmentReadableDateTime,
  formatDateTime,
  getNYearsBackDate,
  formatToYYYYMMDD,
  getReadableDateString,
  getLatestRecord,
  getLatestDayValue,
};
