/* eslint-disable indent */
import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';

import {
  getAppointmentReadableDateTime,
  getLatestDayValue,
} from '../../../../../utils/date.utl';
import { DataTable } from '../../../../../components/common';
import { ColumnDef } from '@tanstack/react-table';

const ShowEmbryosModal = ({
  open,
  onClose,
  embryos,
}: ShowEmbryosModalProps) => {
  const columns: ColumnDef<EmbryologyType>[] = [
    {
      accessorKey: 'publicId',
      header: 'Embryo Id',
      cell: ({ row }) => {
        return (
          <p className="space-x-2">
            <span>{row.original.publicId}</span>
          </p>
        );
      },
    },
    {
      header: 'Latest day',
      cell: ({ row }) => {
        const [dayKey, dayValue] = getLatestDayValue(row.original);
        return (
          <p className="space-x-2">
            <span>
              {dayKey} - {dayValue}
            </span>
          </p>
        );
      },
    },
    {
      accessorKey: 'dateCollectedThawed',

      header: 'Date Thawed',
      cell: ({ row }) => {
        return (
          <>
            {getAppointmentReadableDateTime(
              new Date(row.original.dateCollectedThawed)
            )}
          </>
        );
      },
    },
    {
      header: 'Transferred on',
      cell: ({ row }) => {
        return (
          <>
            {row.original.transfer?.transferredOn
              ? getAppointmentReadableDateTime(
                  new Date(row.original.transfer?.transferredOn)
                )
              : ''}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[65vw] md:w-[55vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Embryos
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider variant={'vertical'} className="">
            <DataTable columns={columns} data={embryos} isLoading={false} />
          </Slider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowEmbryosModal;
