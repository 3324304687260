import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataTable, notify } from '../../../../../components/common';
import { Slider, Button } from '../../../../../components/UI';
import {
  DirectedArrowIcon,
  LeftArrowIcon,
  NotePenIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { THEME } from '../../../../../constants/ColorConstants';
import { getReadableDate } from '../../../../../utils/date.utl';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';
import { getTransferredEmbryos } from '../../../../../services/api/endPoints/embryology';
import EmbryoTransferModal from './EmbryoTransferModal';
import ShowTransferredEmbryosModal from './ShowTransferredEmbryosModal';

const EmbryoTransfer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transferredEmbryos, setTransferredEmbryos] = useState<
    EmbryoTransferRecord[]
  >([]);
  const [selectedTransferredEmbryo, setSelectedTransferredEmbryo] =
    useState<EmbryoTransferRecord | null>(null);
  const navigate = useNavigate();
  const { treatmentCycleId } = useParams();
  const location = useLocation();
  const [showEmbryosModalOpen, setShowEmbryosModalOpen] =
    useState<boolean>(false);
  const [isTransferEmbryoModalOpen, setIsTransferEmbryoModalOpen] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const getEmbryoTransfer = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: 'Treatment cycle not found',
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getTransferredEmbryos(treatmentCycleId);
      if (res) {
        setTransferredEmbryos(res);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };

    getEmbryoTransfer();
  }, []);

  const embryoTransferColumn = useMemo<ColumnDef<EmbryoTransferRecord>[]>(
    () => [
      {
        accessorKey: 'publicId',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Public ID
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return row.original.publicId ? (
            <>
              <span>{row.original.publicId}</span>
            </>
          ) : (
            ''
          );
        },
      },
      {
        accessorKey: 'embryosTransferred',
        header: 'Transferred Embryos',
        cell: ({ row }) => {
          return row.original.embryosTransferred ? (
            <>
              <span>{row.original.embryosTransferred.length}</span>
            </>
          ) : (
            '-'
          );
        },
      },
      {
        accessorKey: 'transferredOn',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Transferred On
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return row.original.transferredOn ? (
            <>{getReadableDate(row.original.transferredOn)}</>
          ) : (
            ''
          );
        },
      },
      {
        accessorKey: 'performedBy.fullName',
        header: 'Performed by',
      },
      {
        accessorKey: 'signedOffBy.fullName',
        header: 'Signed off by',
      },

      {
        id: 'action',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end gap-8">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  size={'xmall'}
                  trailingIcon={<OpenEyeIcon2 />}
                  onClick={() => {
                    setSelectedTransferredEmbryo(row.original);
                    setShowEmbryosModalOpen(true);
                  }}
                >
                  View
                </Button>
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  size={'xmall'}
                  trailingIcon={<NotePenIcon stroke="currentColor" />}
                  onClick={() => {
                    setSelectedTransferredEmbryo(row.original);
                    setIsEditing(true);
                  }}
                  disabled={!!location?.state?.isReadOnly}
                >
                  Edit
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isTransferEmbryoModalOpen && (
        <EmbryoTransferModal
          onClose={() => setIsTransferEmbryoModalOpen(false)}
          isOpen={isTransferEmbryoModalOpen}
        />
      )}
      {selectedTransferredEmbryo && isEditing && (
        <EmbryoTransferModal
          transferredEmbryo={selectedTransferredEmbryo}
          isOpen={isEditing}
          onClose={() => {
            setSelectedTransferredEmbryo(null);
            setIsEditing(false);
          }}
        />
      )}
      {showEmbryosModalOpen && selectedTransferredEmbryo && (
        <ShowTransferredEmbryosModal
          open={showEmbryosModalOpen}
          transferredEmbryos={selectedTransferredEmbryo}
          onClose={() => setShowEmbryosModalOpen(false)}
        />
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Transferred Embryos
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setIsTransferEmbryoModalOpen(true)}
              disabled={!!location?.state?.isReadOnly}
            >
              Transfer Embryo
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={embryoTransferColumn}
            data={transferredEmbryos}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default EmbryoTransfer;
