import React from 'react';
import ProfileImage from '../common/ProfilePicture';
import { getFullName } from '../../utils/Helper';

const Profile: React.FC<{
  user: UserEntities;
}> = ({ user }) => {
  return (
    <div className="flex gap-2 items-center">
      <ProfileImage user={user.staff} width="32px" height="32px" />
      <div className="flex flex-col">
        <p className="text-shades-100 text-[14px]">{getFullName(user.staff)}</p>
        <p className="text-neutral-500 text-[14px] captalized">
          {user.staff.type}
        </p>
      </div>
    </div>
  );
};

export default Profile;
