import { URL } from '../requestConstructor';

export const getAllSemenAnalyses = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/semenAnalysis/get?treatmentCyclePublicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createSemenAnalysis = async (data: CreateSemenAnalysisRequest) => {
  try {
    const response = await URL.post('/semenAnalysis/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateSemenAnalysis = async (data: UpdateSemenAnalysisRequest) => {
  try {
    const response = await URL.put('/semenAnalysis/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSemenAnalysis = async (publicId: string) => {
  try {
    const response = await URL.delete(
      `/semenAnalysis/delete?publicId=${publicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
