/* eslint-disable indent */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Textarea,
} from '../../../../../UI';
import { CrossIcon } from '../../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../../common/Alert';
import { DatePicker, SelectWithInputAndAddOption } from '../../../../../common';
import {
  ERROR_TITLE,
  SUCCESS_MESSAGE,
  SUCCESS_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createMedicineRecord,
  getAllMedicationDosage,
  getAllMedicationNames,
  getAllMedicationTypes,
} from '../../../../../../services/api';
import { MEDICATION_CONSTANTS } from '../../../../../../constants/constants';
import { getReadableDate } from '../../../../../../utils/date.utl';

interface AddMedicationModalProps {
  setMedications: React.Dispatch<React.SetStateAction<Medication[]>>;

  onClose: () => void;
  selectedMedication: Medication | null;
  type: 'View' | 'Create' | 'Edit';
}
const AddMedicationModal: React.FC<AddMedicationModalProps> = ({
  setMedications,
  onClose,
  selectedMedication,
  type,
}) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { treatmentCycleId } = useParams();

  const initialMedicationData: MedicationCreate = {
    treatmentCyclePublicId: treatmentCycleId || '',
    prescribedByPublicId: user.staff.publicId,
    medicationName: null,
    type: null,
    dosage: '',
    dosageUnit: '',
    startDate: new Date(),
    dosageFrequency: 0,
    dailyDosageFrequency: 0,
    additionalInstructions: '',
    dailyDosage1Time: '00:00:00',
    dailyDosage2Time: null,
    dailyDosage3Time: null,
    dailyDosage4Time: null,
    interval: 0,
  };

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medicationNames, setMedicationNames] = useState<string[]>([]);
  const [medicationTypes, setMedicationTypes] = useState<string[]>([]);
  const [medicationDosageUnit, setMedicationDosageUnit] = useState<string[]>(
    []
  );
  const [formData, setFormData] = useState<MedicationCreate>(
    initialMedicationData
  );

  useEffect(() => {
    if (selectedMedication) {
      setFormData(selectedMedication as MedicationCreate);
    } else {
      setFormData(initialMedicationData);
    }
  }, [selectedMedication]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          medicationNamesResponse,
          medicationTypesResponse,
          medicationDosageResponse,
        ] = await Promise.all([
          getAllMedicationNames(),
          getAllMedicationTypes(),
          getAllMedicationDosage(),
        ]);
        setMedicationNames(medicationNamesResponse[0]);
        setMedicationTypes(medicationTypesResponse[0]);
        setMedicationDosageUnit(medicationDosageResponse[0]);
      } catch (error) {
        console.error('Error fetching medication data:', error);
      }
    };

    fetchData();
  }, []);

  const inputHandler = (name: keyof MedicationCreate, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const handleTimeChange = (
    name: keyof MedicationCreate,
    time: Date | null
  ) => {
    if (!time) return;
    const formattedTime = time.toTimeString().slice(0, 8);
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: formattedTime,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = useCallback(() => {
    const errors: Record<string, string> = {};
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    setIsLoading(true);
    try {
      const [res, error] = await createMedicineRecord(formData);

      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SUCCESS_TITLE,
          message: SUCCESS_MESSAGE.MEDICATION_ADDED,
        });
        setMedications(prev => [...prev, res]);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: error.data,
        });
      }
    } catch (error: any) {
      notify.error({
        title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
        message: error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isValidMedicationKey = (
    key: string,
    obj: Partial<MedicationCreate>
  ): key is keyof MedicationCreate => {
    return key in obj;
  };
  return (
    <Dialog open={!!type} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[55vw] md:w-[40vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {type} Medication
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>

        <div className="flex-1 p-4 space-y-4 overflow-scroll">
          {selectedMedication?.discontinuedOn && (
            <Label className="text-destructive-500">
              Discontinued on{' '}
              {getReadableDate(selectedMedication.discontinuedOn)}
            </Label>
          )}
          <div className="w-full space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Medication Name
            </Label>
            <SelectWithInputAndAddOption
              name="type"
              type="single"
              inputPlaceholder="Enter medication"
              value={formData.medicationName || ''}
              options={medicationNames}
              setValue={value => inputHandler('medicationName', value)}
              setOptions={setMedicationNames}
            />
            {/* className="w-full no-spinner" */}
            <datalist id="options">
              {medicationNames.map((option, index) => (
                <option key={index} value={option} />
              ))}
            </datalist>
          </div>
          <div className="w-full space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Medication Type
            </Label>
            <SelectWithInputAndAddOption
              name="type"
              type="single"
              inputPlaceholder="Enter medication type"
              value={formData.type || ''}
              options={medicationTypes}
              setValue={value => inputHandler('type', value)}
              setOptions={setMedicationTypes}
            />
          </div>
          <div className="w-full space-y-1 md:flex md:space-x-4 md:space-y-0">
            <div className="flex-1 space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Dosage
              </Label>
              <Input
                value={formData.dosage}
                type="text"
                className="w-full no-spinner"
                onChange={e => inputHandler('dosage', e.target.value)}
              />
            </div>

            <div className="flex-1 space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Dosage Unit
              </Label>
              <SelectWithInputAndAddOption
                name="type"
                type="single"
                inputPlaceholder="Enter unit"
                value={formData.dosageUnit || ''}
                options={medicationDosageUnit}
                setValue={value => inputHandler('dosageUnit', value)}
                setOptions={setMedicationDosageUnit}
              />
            </div>
          </div>

          <hr />
          <Label>Schedule</Label>
          <div className="w-full space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Start Date
            </Label>
            <DatePicker
              variant="fullDate"
              handleDateChange={value => inputHandler('startDate', value)}
              initialValue={formData.startDate}
              hasError={!!formErrors.startDate}
            />
            {formErrors.startDate && (
              <small className="text-destructive-500">
                {formErrors.startDate}
              </small>
            )}
          </div>
          <div className="w-full  space-y-1">
            <Label>How Often</Label>
            <Select
              name="howOften"
              value={JSON.stringify(formData.dosageFrequency)}
              onValueChange={value => {
                const newDosageFrequency = parseInt(value);
                let newFormData = {
                  ...formData,
                  dosageFrequency: newDosageFrequency,
                };
                if (newDosageFrequency > 0) {
                  newFormData = {
                    ...newFormData,
                    dailyDosageFrequency: 1,
                  };
                }

                setFormData(newFormData);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0 z-[9999999]">
                {MEDICATION_CONSTANTS.DOSAGE_FREQUENCIES.map(freq => (
                  <SelectItem
                    key={freq.label}
                    value={JSON.stringify(freq.value)}
                  >
                    {freq.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {formErrors.flashSeen && (
              <small className="text-destructive-500">
                {formErrors.flashSeen}
              </small>
            )}
          </div>
          {formData.dosageFrequency !== 0 && (
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                End Date
              </Label>
              <DatePicker
                variant="fullDate"
                handleDateChange={value => inputHandler('endDate', value)}
                initialValue={formData.endDate}
                hasError={!!formErrors.endDate}
              />
              {formErrors.endDate && (
                <small className="text-destructive-500">
                  {formErrors.endDate}
                </small>
              )}
            </div>
          )}
          {formData.dosageFrequency > 0 && (
            <>
              <hr />
              <Label>Daily Schedule</Label>
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Dosage
                </Label>
                <Select
                  name="dosage"
                  value={JSON.stringify(formData.dailyDosageFrequency)}
                  onValueChange={value =>
                    setFormData({
                      ...formData,
                      dailyDosageFrequency: parseInt(value),
                    })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {[1, 2, 3, 4].map(freq => (
                      <SelectItem key={freq} value={JSON.stringify(freq)}>
                        {freq}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
          {formData.dosageFrequency === 0 &&
            formData.dailyDosageFrequency === 0 && (
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Time for Dosage
                </Label>
                <DatePicker
                  variant="time"
                  handleDateChange={value =>
                    handleTimeChange('dailyDosage1Time', value)
                  }
                  initialValue={
                    (formData['dailyDosage1Time'] as string) || null
                  }
                  hasError={!!formErrors['dailyDosage1Time']}
                />
                {formErrors['dailyDosage1Time'] && (
                  <small className="text-destructive-500">
                    {formErrors['dailyDosage1Time']}
                  </small>
                )}
              </div>
            )}
          {formData.dailyDosageFrequency > 0 &&
            Array.from({ length: formData.dailyDosageFrequency }, (_, i) => {
              const dosageKey = `dailyDosage${i + 1}Time`;
              if (isValidMedicationKey(dosageKey, formData)) {
                return (
                  <div key={i} className="w-full space-y-1">
                    <Label className="after:content-['*'] after:text-destructive-500">
                      Time for Dosage {i + 1}
                    </Label>
                    <DatePicker
                      variant="time"
                      handleDateChange={value =>
                        handleTimeChange(dosageKey, value)
                      }
                      initialValue={(formData[dosageKey] as string) || null}
                      hasError={!!formErrors[dosageKey]}
                    />
                    {formErrors[dosageKey] && (
                      <small className="text-destructive-500">
                        {formErrors[dosageKey]}
                      </small>
                    )}
                  </div>
                );
              }
              return null;
            })}

          <hr />
          <div className="w-full space-y-1">
            <Label>Additional Notes</Label>
            <Textarea
              placeholder="Enter Observations"
              id="observations"
              name="observations"
              value={formData.additionalInstructions || ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                inputHandler('additionalInstructions', e.target.value)
              }
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            size={'small'}
            variant={'primary'}
            onClick={handleSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddMedicationModal;
