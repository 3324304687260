import React from 'react';

const RenderData = (props: RenderDataProps) => {
  if (props.type === 'single') {
    return (
      <div className="grid grid-cols-2">
        <div className="cols-span-1">
          {typeof props.label === 'string' ? (
            <p className="text-[14px] font-semibold text-neutral-500">
              {props.label}
            </p>
          ) : (
            props.label
          )}
        </div>
        <div className="cols-span-1">
          {typeof props.value === 'string' ? (
            <p className="text-[14px] font-semibold text-neutral-900">
              {props.value}
            </p>
          ) : (
            props.value
          )}
        </div>
      </div>
    );
  }
  if (props.type === 'multi') {
    return (
      <>
        {props.values.map((item, index) => (
          <>
            <div className="cols-span-1" key={index}>
              {typeof item.label === 'string' ? (
                <p className="text-[14px] font-semibold text-neutral-500">
                  {item.label}
                </p>
              ) : (
                item.label
              )}
            </div>
            <div className="cols-span-1">
              {typeof item.value === 'string' ? (
                <p className="text-[14px] font-semibold text-neutral-900">
                  {item.value}
                </p>
              ) : (
                item.value
              )}
            </div>
          </>
        ))}
      </>
    );
  }
  return null;
};

export default RenderData;
