import React from 'react';
import { Button } from '../UI';
import { DeleteIcon } from '../../assets/icons';
import { THEME } from '../../constants/ColorConstants';
const ActionADelete = React.memo<ActionClickIconProps>(
  ({ handleClick, classNames }) => {
    return (
      <Button
        className={classNames}
        leadingIcon={<DeleteIcon stroke={THEME.DESTRUCTIVE_500} />}
        variant={'link'}
        size={'xmall'}
        onClick={handleClick}
      ></Button>
    );
  }
);

export default ActionADelete;
