import { URL } from '../requestConstructor';

export const getAllTreatmentCyclesByPatientPublicId = async (
  patientPublicId: string
) => {
  try {
    const response = await URL.get(
      `/treatmentCycle/get?patientPublicId=${patientPublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/treatmentCycle/get?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
export const createTreatmentCycle = async (data: any) => {
  try {
    const response = await URL.post('/treatmentCycle/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const updateTreatmentCycle = async (data: any) => {
  try {
    const response = await URL.put('/treatmentCycle/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const deleteTreatmentCycle = async (treatmentCyclePublicId: any) => {
  try {
    const response = await URL.delete(
      `/treatmentCycle/delete?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const cancelTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.put(
      `/treatmentCycle/cancel?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const restartTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.put(
      `/treatmentCycle/restart?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getAllDonors = async () => {
  try {
    const response = await URL.get('/treatmentCycle/donor/get');
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
export const getFollicleScans = async (params: {
  publicId?: string;
  treatmentCyclePublicId?: string;
}) => {
  try {
    const { publicId, treatmentCyclePublicId } = params;

    let url = '/follicleScan/get?';
    if (publicId) {
      url += `publicId=${publicId}`;
    } else if (treatmentCyclePublicId) {
      url += `treatmentCyclePublicId=${treatmentCyclePublicId}`;
    } else {
      throw new Error('At least one parameter must be provided');
    }

    const response = await URL.get(url);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const createFollicleScan = async (data: CreateFollicleScanRequest) => {
  try {
    const response = await URL.post('/follicleScan/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateFollicleScan = async (data: UpdateFollicleScanRequest) => {
  try {
    const response = await URL.put('/follicleScan/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteFollicleScan = async (follicleScanPublicId: string) => {
  try {
    const response = await URL.delete(
      `/follicleScan/delete?publicId=${follicleScanPublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
