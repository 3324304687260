import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Slider,
} from '../../../../../UI';
import { CrossIcon } from '../../../../../../assets/icons';
import {
  getReadableDate,
  getReadableTime,
} from '../../../../../../utils/date.utl';
import RenderData from '../../../../../../components/common/RenderData';
import { MEDICATION_CONSTANTS } from '../../../../../../constants/constants';

interface ViewMedicationModalProps {
  onClose: () => void;
  selectedMedication: Medication | null;
  type: 'View' | 'Create' | 'Edit';
}
const ViewMedicationModal: React.FC<ViewMedicationModalProps> = ({
  onClose,
  selectedMedication,
  type,
}) => {
  return (
    <Dialog open={!!type} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[55vw] md:w-[40vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {type} Medication
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1 gap-2" variant={'vertical'}>
          <div className="p-3 grid  gap-3">
            <div className="space-y-2 ">
              {selectedMedication?.discontinuedOn && (
                <RenderData
                  type="single"
                  label={'Discontinued On'}
                  value={
                    getReadableDate(selectedMedication?.discontinuedOn) ?? '-'
                  }
                />
              )}
              <RenderData
                type="single"
                label={'Medicine Name'}
                value={selectedMedication?.medicationName}
              />
              <RenderData
                type="single"
                label={'Medicine Type'}
                value={selectedMedication?.type}
              />
              <RenderData
                type="single"
                label={'Dosage'}
                value={
                  selectedMedication?.dosage +
                  ' ' +
                  selectedMedication?.dosageUnit
                }
              />
            </div>
            <hr />
            <div className="space-y-2 ">
              {selectedMedication?.startDate && (
                <RenderData
                  type="single"
                  label={'Start date'}
                  value={getReadableDate(selectedMedication?.startDate) ?? '-'}
                />
              )}
              {selectedMedication?.endDate && (
                <RenderData
                  type="single"
                  label={'End date'}
                  value={getReadableDate(selectedMedication?.endDate) ?? '-'}
                />
              )}
              {selectedMedication?.dosageFrequency != null && (
                <RenderData
                  type="single"
                  label={'How often'}
                  value={
                    MEDICATION_CONSTANTS.DOSAGE_FREQUENCIES[
                      selectedMedication?.dosageFrequency
                    ].label
                  }
                />
              )}
              {selectedMedication?.dailyDosage1Time && (
                <RenderData
                  type="single"
                  label={'Dosage 1 at'}
                  value={getReadableTime(selectedMedication?.dailyDosage1Time)}
                />
              )}
              {selectedMedication?.dailyDosage2Time && (
                <RenderData
                  type="single"
                  label={'Dosage 2 at'}
                  value={getReadableTime(selectedMedication?.dailyDosage2Time)}
                />
              )}
              {selectedMedication?.dailyDosage3Time && (
                <RenderData
                  type="single"
                  label={'Dosage 3 at'}
                  value={getReadableTime(selectedMedication?.dailyDosage3Time)}
                />
              )}
              {selectedMedication?.dailyDosage4Time && (
                <RenderData
                  type="single"
                  label={'Dosage 4 at'}
                  value={getReadableTime(selectedMedication?.dailyDosage4Time)}
                />
              )}
              <RenderData
                type="single"
                label={'Additional instructions'}
                value={selectedMedication?.additionalInstructions}
              />
            </div>
          </div>
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ViewMedicationModal;
