import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  allAppointments,
  authSlice,
  clientSlice,
  femaleSlice,
  loaderSlice,
  locationSlice,
  loginSlice,
  partnerSlice,
  patientPathwaySlice,
  patientSlice,
  progressNotesSlice,
  roleSlice,
  serviceSlice,
  sitesSlice,
  snackbarSlice,
  supplementsSlice,
  testsSlice,
  treatmentsSlice,
  userSlice,
  staffSlice,
  notificationSlice,
  resourceSlice,
  teamSlice,
  pathwaySlice,
  treatmentCycleSlice,
  eggCollectionSlice,
  semenAnalysisSlice,
} from '../slice';
import resourceScheduleSlice from '../slice/resourceScheduleSlice';
import resourceBookingSlice from '../slice/resourceBookingSlice';
import { REDUCER_ACTIONS } from '../../constants/actions';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'user',
    'auth',
    'allAppointments',
    'sites',
    'roles',
    'tests',
    'treatments',
    'supplements',
    'progressNotes',
    'client',
    'locations',
    'services',
    'staffs',
  ],
};
const reducer = combineReducers({
  user: userSlice,
  auth: authSlice,
  roles: roleSlice,
  patient: patientSlice,
  partner: partnerSlice,
  snackbar: snackbarSlice,
  loader: loaderSlice,
  female: femaleSlice,
  login: loginSlice,
  progressNotes: progressNotesSlice,
  allAppointments: allAppointments,
  sites: sitesSlice,
  tests: testsSlice,
  treatments: treatmentsSlice,
  supplements: supplementsSlice,
  patientPathways: patientPathwaySlice,
  client: clientSlice,
  locations: locationSlice,
  services: serviceSlice,
  staffs: staffSlice,
  notifications: notificationSlice,
  resourceSchedule: resourceScheduleSlice,
  resourceBooking: resourceBookingSlice,
  resources: resourceSlice,
  teams: teamSlice,
  pathways: pathwaySlice,
  treatmentCycles: treatmentCycleSlice,
  eggCollection: eggCollectionSlice,
  semenAnalysis: semenAnalysisSlice,
});

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === REDUCER_ACTIONS.RESET_STORE) {
    state = undefined;
  }
  return reducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof persistedReducer>;
