/* eslint-disable */
import React from 'react';
import { LineGraphType } from '../../types/lineGraph/type';
import { Label } from '../UI';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartOptions,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale
);

const LineGraph = ({
  chartData,
  options,
  yLabel,
  legendsConfig,
  height = 400
}: LineGraphType) => {
  const options2: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        ticks: {
          display: false,
        },
        title: {
          display: false,
        },
        grid: {
          drawTicks: false,
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel,
        },
        beginAtZero: true,
        afterFit: ctx => {
          ctx.width = 65;
        },
      },
    },
  };
  return (
    <div className="w-full flex p-3">
      <div className="w-[170px] p-3 space-y-2" style={{height:`${height}px`}}>
        {legendsConfig.map(label => (
          <div className="gap-2 flex items-center">
            <div
              className="w-[15px] h-[15px]"
              style={{ backgroundColor: label.color }}
            />
            <Label>{label.name}</Label>
          </div>
        ))}
      </div>
      <div className="flex-1 overflow-y-hidden" style={{height:`${height}px`}}>
        <div className="flex w-full overflow-x-auto ">
          <div
            style={{
              height: '382px',
              width: `55px`,
            }}
          >
            <Line data={chartData} options={options2} />
          </div>
          <div className="h-[400px] w-[100vw] overflow-y-auto">
            <div
              style={{
                height: '400px',
                width: `${chartData.labels.length * 100}px`,
              }}
            >
              <Line data={chartData} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
