export enum Permission {
  none = 0,
  read = 1,
  write = 2,
  delete = 3,
}
export enum ActivityFeedType {
  DAILY = 'DAILY',
  PRIORITY = 'PRIORITY',
}

export enum JourneyStep {
  Consultant = 'Consultant',
  Location = 'Location',
  Service = 'Service',
  ScheduleAppointment = 'ScheduleAppointment',
  AppointmentDetails = 'AppointmentDetails',
  Success = 'Success',
}
