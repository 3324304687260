import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  RightArrow,
  OutlinedCirlce,
  LogoType2,
  DashboardSidebarIcon,
  UserSidebarIcon,
  LabSidebarIcon,
  BoardSidebarIcon,
  DownArrow,
  AdminSidebarIcon,
  ClinicManagerSidebarIcon,
  SettingsSidebarIcon,
  BookingCalendarSidebarIcon,
  ScheduleIcon,
  ResourceIconSidebar,
} from '../../assets/icons';
import {
  ROUTES,
  SIDEBAR_MENU_CONSTANT,
} from '../../constants/NavigationConstants';
import { LogOutIcon } from 'lucide-react';
import { notify } from '../../components/common/Alert';
import {
  SUCCESS_MESSAGE,
  SUCCESS_TITLE,
} from '../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import { REDUCER_ACTIONS } from '../../constants/actions';
import { THEME } from '../../constants/ColorConstants';
import { clearCookies } from '../../utils/Helper';

interface SidebarMenuItem {
  name: string;
  path: string;
  Icon: React.FC<{ isActive: boolean }>;
  dropdown?: SidebarMenuItem[];
}

const Sidebar: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [hoverID, setHoverId] = useState<string>('');
  const navigate = useNavigate();
  const [showSideBar, setShowSidebar] = useState<boolean>(
    window.innerWidth >= 1025
  );
  const [isBookingDropdownOpen, setIsBookingDropdownOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const toggleDropdown = useCallback((name: string) => {
    setIsBookingDropdownOpen(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);
  const SIDEBAR_MENU: SidebarMenuItem[] = useMemo(
    () => [
      {
        name: SIDEBAR_MENU_CONSTANT.OVERVIEW,
        path: ROUTES.ROUTE_TO_OVERVIEW,
        Icon: DashboardSidebarIcon,
      },
      {
        name: SIDEBAR_MENU_CONSTANT.PATIENTS,
        path: ROUTES.ROUTE_TO_PATIENT,
        Icon: UserSidebarIcon,
      },
      {
        name: SIDEBAR_MENU_CONSTANT.LABS,
        path: ROUTES.LABS.LABS_WILDCARD,
        Icon: LabSidebarIcon,
      },
      {
        name: SIDEBAR_MENU_CONSTANT.CLINIC_BOARD,
        path: ROUTES.CLINIC_BOARD,
        Icon: BoardSidebarIcon,
      },
      {
        name: SIDEBAR_MENU_CONSTANT.RESOURCE_BOOKING_MENUS.RESOURCE_BOOKING,
        path: '',
        Icon: ResourceIconSidebar,
        dropdown: [
          {
            name: SIDEBAR_MENU_CONSTANT.RESOURCE_BOOKING_MENUS.CALENDAR,
            path: ROUTES.ROUTE_TO_RESOURCE_BOOKING_CALENDAR,
            Icon: BookingCalendarSidebarIcon,
          },
          {
            name: SIDEBAR_MENU_CONSTANT.RESOURCE_BOOKING_MENUS.SCHEDULE,
            path: ROUTES.ROUTE_TO_RESOURCE_BOOKING_STAFF_SCHEDULE,
            Icon: ScheduleIcon,
          },
        ],
      },

      {
        name: SIDEBAR_MENU_CONSTANT.ADMIN_MENUS.ADMIN,
        path: '',
        Icon: AdminSidebarIcon,
        dropdown: [
          {
            name: SIDEBAR_MENU_CONSTANT.ADMIN_MENUS.CLINIC_MANAGER,
            path: ROUTES.ADMIN.BASE,
            Icon: ClinicManagerSidebarIcon,
          },
          // {
          //   name: 'Appearance',
          //   path: ROUTES.ROUTE_TO_BASE,
          //   Icon: AppearanceSidebarIcon,
          // },
          // {
          //   name: 'Forms',
          //   path: ROUTES.ROUTE_TO_BASE,
          //   Icon: FormSidebarIcon,
          // },
          // {
          //   name: 'Email Manager',
          //   path: ROUTES.EMAIL_MANAGER,
          //   Icon: EmailManagerSidebarIcon,
          // },
          {
            name: SIDEBAR_MENU_CONSTANT.ADMIN_MENUS.SETTINGS,
            path: ROUTES.SETTINGS,
            Icon: SettingsSidebarIcon,
          },
        ],
      },
    ],
    []
  );
  const ACTION_MENU: {
    name: string;
    action: () => void;
    Icon: React.FC<{ isActive: boolean }> | React.FC;
  }[] = useMemo(
    () => [
      {
        name: SIDEBAR_MENU_CONSTANT.SIGN_OUT,
        action: () => {
          localStorage.clear();
          sessionStorage.clear();
          clearCookies();
          dispatch({ type: REDUCER_ACTIONS.RESET_STORE });
          navigate(ROUTES.ROUTE_TO_SIGN_IN);
          notify.success({
            title: SUCCESS_TITLE.GENERIC_SIGNED_OUT,
            message: SUCCESS_MESSAGE.GENERIC_SIGN_OUT,
          });
        },
        Icon: LogOutIcon,
      },
    ],
    [dispatch, navigate]
  );

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setShowSidebar(width >= 1025);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={
        'md:flex-1 z-[99999] flex-1 flex flex-col bg-primary-900 h-svh transition-all duration-150 ease-in-out fixed md:relative ' +
        (showSideBar ? 'min-w-[300px] px-[20px]' : 'min-w-[65px] px-[10px]')
      }
    >
      {!showSideBar && (
        <button
          className="absolute bg-primary-900 rounded-full right-[-10px] top-[17px] z-[9999] p-[5px]"
          onClick={() => setShowSidebar(true)}
        >
          <RightArrow stroke={THEME.SHADE_0} />
        </button>
      )}
      <div
        className={
          'py-[16px] flex items-center ' +
          (showSideBar ? 'justify-between' : 'justify-center')
        }
      >
        <div className="flex items-center gap-2">
          <img src={OutlinedCirlce} alt="Outlined Circle" />
          <img
            src={LogoType2}
            alt="Logo"
            className={'transition-all ' + (showSideBar ? 'block' : 'hidden')}
          />
        </div>
        {showSideBar && (
          <button className="-rotate-180" onClick={() => setShowSidebar(false)}>
            <RightArrow stroke={THEME.SHADE_0} />
          </button>
        )}
      </div>
      <ul className="list-none flex flex-col gap-2">
        {SIDEBAR_MENU.map(({ name, path, Icon, dropdown }, index) => (
          <li key={index}>
            {dropdown ? (
              <div
                className={
                  isBookingDropdownOpen[name]
                    ? `bg-[${THEME.PRIMARY_50}] rounded`
                    : ''
                }
              >
                <button
                  id={name}
                  onMouseEnter={e =>
                    setHoverId((e.target as HTMLButtonElement).id)
                  }
                  onMouseLeave={() => setHoverId('')}
                  onClick={() => toggleDropdown(name)}
                  className={
                    isBookingDropdownOpen[name]
                      ? 'flex items-center justify-between font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 w-full' +
                        (location.pathname === path
                          ? ' bg-primary-500 text-shades-100 '
                          : '') +
                        (!showSideBar ? 'justify-center' : '')
                      : 'flex items-center justify-between font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 w-full' +
                        (location.pathname === path
                          ? ' bg-primary-500 text-shades-100 '
                          : '') +
                        (!showSideBar ? 'justify-center' : '')
                  }
                >
                  <div
                    className={`flex items-center gap-2 ${
                      !showSideBar
                        ? 'flex-col justify-center w-[1.5rem]'
                        : 'flex-row items-center w-full justify-between'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="">
                        {isBookingDropdownOpen[name] ? (
                          <Icon isActive={false} />
                        ) : (
                          <Icon
                            isActive={
                              hoverID === name || location.pathname === path
                            }
                          />
                        )}
                      </div>
                      <span
                        className={
                          'transition-all ' + (showSideBar ? 'block' : 'hidden')
                        }
                      >
                        {name}
                      </span>
                    </div>
                    {isBookingDropdownOpen[name] ? (
                      !showSideBar ? (
                        <div
                          className="flex justify-center w-full"
                          style={{ transform: 'rotate(180deg)' }}
                        >
                          <DownArrow stroke={THEME.SHADE_0} />
                        </div>
                      ) : (
                        <DownArrow stroke={THEME.SHADE_0} />
                      )
                    ) : showSideBar ? (
                      <RightArrow stroke={THEME.SHADE_0} />
                    ) : (
                      <div className="flex justify-center w-full">
                        <DownArrow stroke={THEME.SHADE_0} />
                      </div>
                    )}
                  </div>
                </button>
                {isBookingDropdownOpen[name] && (
                  <ul className="list-none flex flex-col items-center">
                    {dropdown.map((subItem, subIndex) => (
                      <li key={subIndex} className="w-full">
                        <Link
                          id={subItem.name}
                          onMouseEnter={e =>
                            setHoverId((e.target as HTMLButtonElement).id)
                          }
                          onMouseLeave={() => setHoverId('')}
                          to={subItem.path}
                          className={
                            'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 ' +
                            (location.pathname === subItem.path
                              ? ' bg-primary-500 text-shades-100 '
                              : '') +
                            (!showSideBar ? 'justify-center' : '')
                          }
                        >
                          <div className="">
                            <subItem.Icon
                              isActive={
                                hoverID === subItem.name ||
                                location.pathname === subItem.path
                              }
                            />
                          </div>
                          <span
                            className={
                              'transition-all ' +
                              (showSideBar ? 'block' : 'hidden')
                            }
                          >
                            {subItem.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <Link
                id={name}
                onMouseEnter={e =>
                  setHoverId((e.target as HTMLAnchorElement).id)
                }
                onMouseLeave={() => setHoverId('')}
                to={path}
                className={
                  'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 ' +
                  (location.pathname === path
                    ? ' bg-primary-500 text-shades-100 '
                    : '') +
                  (!showSideBar ? 'justify-center' : '')
                }
              >
                <div className="">
                  <Icon
                    isActive={hoverID === name || location.pathname === path}
                  />
                </div>
                <span
                  className={
                    'transition-all ' + (showSideBar ? 'block' : 'hidden')
                  }
                >
                  {name}
                </span>
              </Link>
            )}
          </li>
        ))}
      </ul>
      <ul className="list-none flex flex-col gap-2 mt-auto mb-3">
        {ACTION_MENU.map(({ name, action, Icon }, index) => (
          <li key={index}>
            <button
              onClick={action}
              className={
                'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-destructive-500 hover:text-shades-0 w-full' +
                (!showSideBar ? 'justify-center' : '')
              }
            >
              <div className="">
                <Icon isActive={false} />
              </div>
              <span
                className={
                  'transition-all ' + (showSideBar ? 'block' : 'hidden')
                }
              >
                {name}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
