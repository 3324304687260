import React from 'react';
import LocationCard from '../Cards/LocationCard';

const locations: UserEntities[] = [
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
];

const LocationsList: React.FC = () => {
  return (
    <div className="consultant-list">
      <h2 className="text-4xl font-medium text-neutral-900">
        Please choose a location
      </h2>
      <div className="consultant-list-grid">
        {locations.map((location, index) => (
          <LocationCard key={index + location.username} location={location} />
        ))}
      </div>
    </div>
  );
};

export default LocationsList;
