/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import List from '../Lists/List';
import ConsultantCard from '../Cards/ConsultantCard';
import { JourneyStep } from '../../../types/enums';
import LocationCard from '../Cards/LocationCard';

const BookingJourney: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<JourneyStep>(
    JourneyStep.Consultant
  );

  const services: any[] = [
    /* Your services data */
  ];
  const schedule = {}; // Your schedule data
  const appointmentDetails = {}; // Your appointment details data

  const renderStepContent = () => {
    switch (currentStep) {
      case JourneyStep.Consultant:
        return (
          <List title="Please choose a consultant">
            {consultants.map((consultant, index) => (
              <ConsultantCard key={index} user={consultant} />
            ))}
          </List>
        );
      case JourneyStep.Location:
        return (
          <List title="Please choose a location">
            {locations.map((location, index) => (
              <LocationCard key={index} location={location} />
            ))}
          </List>
        );
      case JourneyStep.Service:
        return (
          <List title="Please choose a service">
            {services.map((service, index) => (
              <div key={index}>{service.name}</div>
            ))}
          </List>
        );
      case JourneyStep.ScheduleAppointment:
        return (
          <div>
            <h2>Schedule Appointment</h2>
            {/* Render your schedule component */}
          </div>
        );
      case JourneyStep.AppointmentDetails:
        return (
          <div>
            <h2>Appointment Details</h2>
          </div>
        );
      case JourneyStep.Success:
        return (
          <div>
            <h2>Success</h2>
            <p>Your appointment has been successfully scheduled.</p>
          </div>
        );
      default:
        return null;
    }
  };

  const goToNextStep = () => {
    const steps = Object.values(JourneyStep);
    const currentIndex = steps.indexOf(currentStep);
    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const goToPreviousStep = () => {
    const steps = Object.values(JourneyStep);
    const currentIndex = steps.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1]);
    }
  };

  const steps = Object.values(JourneyStep);
  const currentIndex = steps.indexOf(currentStep);
  const hasPreviousStep = currentIndex > 0;
  const hasNextStep = currentIndex < steps.length - 1;

  return (
    <>
      <button onClick={goToPreviousStep} disabled={!hasPreviousStep}>
        Back
      </button>
      {'   '}
      <button onClick={goToNextStep} disabled={!hasNextStep}>
        Next
      </button>
      {renderStepContent()}
    </>
  );
};

export default BookingJourney;

const consultants: UserEntities[] = [
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
];

const locations: UserEntities[] = [
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
  {
    publicId: 'U104548',
    username: 'ranjit',
    email: 'ranjit@swivo.io',
    roles: ['ROLE_SUPERADMIN'],

    staff: {
      publicId: 'S943975',
      siteExternalId: '44386312-dd81-4c1f-b09a-8e46b6e4d41a',
      username: 'ranjit',
      title: 'Mr.',
      firstName: 'Ranjit',
      lastName: 'S',
      signature: 'Mr. Ranjit',
      type: 'Superadmin',
      email: 'ranjit@swivo.io',
      phoneNo: '+91 8805895330',
      gmcNumber: 'GD323421343',
      fullName: 'Mr. Ranjit S',
    },
    createdBy: 'dhananjay.chobhe',
    whenCreated: '2023-05-30T05:48:43.317+00:00',
  },
];
