import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Textarea,
} from '../../../../../../UI';
import { CrossIcon } from '../../../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../../../common/Alert';
import { DatePicker } from '../../../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getReadableDate } from '../../../../../../../utils/date.utl';
import {
  createFollicleScan,
  updateFollicleScan,
} from '../../../../../../../services/api';
import {
  EndometriumOptions,
  thicknessLabels,
} from '../../../../../../../constants/constants';
import FollicleComponent from './FollicleInput';

const AddFollicleScanModal = ({
  open,
  onClose,
  ...props
}: AddAndEditAddFollicleScanModalProps) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { treatmentCycleId } = useParams();
  const [formData, setFormData] = useState<FollicleScanForm>({
    examDate:
      props.type === 'Create'
        ? props.selectedExamDate
          ? new Date(props.selectedExamDate)
          : null
        : null,
    performedByPublicId: user.staff.publicId,
    scanReceivedDate:
      props.type === 'Create'
        ? props.selectedExamDate
          ? new Date(props.selectedExamDate)
          : null
        : null,
    observations: '',
    endometrium: {
      grade: '',
      thickness1: null,
      thickness2: null,
      thickness3: null,
    },
    follicles: [],
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staffOptions, setStaffOptions] = useState<Record<string, string>>({});
  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);
  const [leftFollicle, setLeftFollicle] = useState<FollicleInput[]>([]);
  const [rightFollicle, setRightFollicle] = useState<FollicleInput[]>([]);

  useEffect(() => {
    const setInitialData = async () => {
      let currentFormData: FollicleScanForm = { ...formData };
      const staffOptions: Record<string, string> = {};
      staffs.forEach(staff => {
        staffOptions[staff.publicId] = staff.fullName ?? '';
      });
      setStaffOptions(staffOptions);

      if (props.type === 'Edit') {
        const { selectedFollicleScan } = props;
        currentFormData = {
          examDate: new Date(selectedFollicleScan.examDate),
          performedByPublicId: selectedFollicleScan.performedBy.publicId,
          scanReceivedDate: new Date(selectedFollicleScan.scanReceivedDate),
          observations: selectedFollicleScan.observations,
          endometrium: selectedFollicleScan.endometrium,
          follicles: selectedFollicleScan.follicles,
        };
        setLeftFollicle(
          selectedFollicleScan.follicles?.filter(follicle => follicle.left)
        );
        setRightFollicle(
          selectedFollicleScan.follicles?.filter(follicle => !follicle.left)
        );
      }
      setFormData(currentFormData);
    };
    setInitialData();
  }, []);

  const inputHandler = (
    name: keyof FollicleScanForm | keyof Endometrium,
    value: any
  ) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const inputEndometriumHandler = (
    name: keyof Endometrium,
    value: string | number
  ) => {
    const errors = { ...formErrors };

    setFormData(prev => ({
      ...prev,
      endometrium: {
        ...prev.endometrium,
        [name]: value,
      } as Endometrium,
    }));

    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors: Record<string, string> = {};
    if (!formData.performedByPublicId) {
      errors.performedByPublicId = 'Performed by - staff is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    if (props.type === 'Create') {
      const data = {
        treatmentCyclePublicId: treatmentCycleId!,
        ...formData,
        follicles: [...leftFollicle, ...rightFollicle],
      };
      const [res, error] = await createFollicleScan(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_FOLLICLE_SCAN_ADDED,
          message: `Follicle scan for the date ${getReadableDate(
            res.resultDate
          )} has been added`,
        });
        props.addFollicleScan(res);
        onClose();
      } else {
        notify.error({
          title: SUCCESS_TITLE.GENERIC_FOLLICLE_SCAN_ADDED,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else if (props.type === 'Edit') {
      const data = {
        publicId: props.selectedFollicleScan.publicId,
        ...formData,
        follicles: [...leftFollicle, ...rightFollicle],
      };
      const [res, error] = await updateFollicleScan(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_FOLLICLE_SCAN_UPDATED,
          message: `Follicle scan for the date ${getReadableDate(
            res.resultDate
          )} has been updated`,
        });
        props.updateFollicleScan(res);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_FOLLICLE_SCAN_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[70vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Edit' ? 'Edit Follicle Scan' : 'Add Follicle Scan'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>

        <div className="flex-1 p-4 space-y-4 overflow-scroll">
          <div className="w-full md:w-1/2  space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Exam date
            </Label>
            <DatePicker
              variant="fullDate"
              handleDateChange={value => inputHandler('examDate', value)}
              initialValue={formData.examDate}
              hasError={!!formErrors.examDate}
            />
            {formErrors.examDate && (
              <small className="text-destructive-500">
                {formErrors.examDate}
              </small>
            )}
          </div>{' '}
          <div className="w-full md:w-1/2  space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Scan received at
            </Label>
            <DatePicker
              variant="dateTime"
              handleDateChange={value =>
                inputHandler('scanReceivedDate', value)
              }
              initialValue={formData.scanReceivedDate}
              hasError={!!formErrors.scanReceivedDate}
            />
            {formErrors.scanReceivedDate && (
              <small className="text-destructive-500">
                {formErrors.scanReceivedDate}
              </small>
            )}
          </div>
          <div className="w-full md:w-1/2  space-y-1">
            <Label className="after:content-['*'] after:text-destructive-500">
              Performed by - staff
            </Label>
            <Select
              name="performedByPublicId"
              value={formData.performedByPublicId}
              onValueChange={value =>
                inputHandler('performedByPublicId', value)
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0 z-[9999999]">
                {Object.keys(staffOptions).length > 0 ? (
                  Object.keys(staffOptions).map(publicId => (
                    <SelectItem key={publicId} value={publicId}>
                      {staffOptions[publicId]}
                    </SelectItem>
                  ))
                ) : (
                  <p>No staffs found</p>
                )}
              </SelectContent>
            </Select>
            {formErrors.performedByPublicId && (
              <small className="text-destructive-500">
                {formErrors.performedByPublicId}
              </small>
            )}
          </div>
          <div className="w-full md:w-1/2  space-y-1">
            <Label>Observations</Label>
            <Textarea
              placeholder="Enter Observations"
              id="observations"
              name="observations"
              value={formData.observations || ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                inputHandler('observations', e.target.value)
              }
            />
          </div>
          <hr />
          <Label>Endometrium</Label>
          <div className="w-full md:w-1/2 space-y-1">
            <Label>Grade</Label>
            <Select
              name="endometrium"
              value={formData.endometrium?.grade}
              onValueChange={value => inputEndometriumHandler('grade', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0 z-[9999999]">
                {EndometriumOptions.map(item => (
                  <SelectItem key={item} value={item}>
                    {item}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {formErrors.performedByPublicId && (
              <small className="text-destructive-500">
                {formErrors.performedByPublicId}
              </small>
            )}
          </div>
          <div className="w-full md:w-1/2 space-y-1">
            <Label>Thickness</Label>

            <div className="flex space-x-2 items-center">
              {['thickness1', 'thickness2', 'thickness3'].map(key => (
                <div key={key} className="flex flex-col items-center">
                  <Label>{thicknessLabels[key]}</Label>
                  <Input
                    value={
                      formData.endometrium?.[key as keyof Endometrium] ?? ''
                    }
                    type="number"
                    className="w-16  no-spinner"
                    onChange={e =>
                      inputEndometriumHandler(
                        key as keyof Endometrium,
                        parseFloat(e.target.value) || ''
                      )
                    }
                  />
                </div>
              ))}
              <div className="flex flex-col items-center pl-4">
                <Label>Average</Label>
                <Input
                  value={(() => {
                    const thicknessValues = [
                      formData.endometrium?.thickness1,
                      formData.endometrium?.thickness2,
                      formData.endometrium?.thickness3,
                    ].filter((v): v is number => v != null && v > 0);

                    const total = thicknessValues.reduce(
                      (acc, val) => acc + val,
                      0
                    );
                    const count = thicknessValues.length;
                    const average =
                      count > 0 ? (total / count).toFixed(1) : '0.0';

                    return average;
                  })()}
                  readOnly
                  className="bg-gray-100 w-24"
                />
              </div>
            </div>
          </div>
          <hr />
          <Label>Right follicles</Label>
          {rightFollicle && (
            <FollicleComponent
              formData={rightFollicle}
              setFormData={setRightFollicle}
              isLeft={false}
            />
          )}
          <hr /> <Label>Left follicles</Label>
          {leftFollicle && (
            <FollicleComponent
              formData={leftFollicle}
              setFormData={setLeftFollicle}
              isLeft={true}
            />
          )}
        </div>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={handleSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Edit' ? 'Update Scan' : 'Add Scan'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddFollicleScanModal;
