import React from 'react';
import { Label } from './Input';

interface RadioButtonProps<T> {
  label: string;
  optionLabel: string;
  value: T;
  selectedValue: T | null;
  onChange: (value: T) => void;
}

const RadioButton = <T,>({
  label,
  optionLabel,
  value,
  selectedValue,
  onChange,
}: RadioButtonProps<T>) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="space-x-[6px] flex items-center">
      <input
        type="radio"
        name={label}
        id={`radio-${value}`}
        checked={selectedValue === value}
        onChange={handleChange}
      />
      <Label htmlFor={`radio-${value}`}>{optionLabel}</Label>
    </div>
  );
};

export default RadioButton;
