/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
interface ContextType {
  onEditModification: (idx: number) => void;
  onRetakeModification: (idx: number) => void;
  onInstantEdit: (img: string) => void;
  onInstantReatake: () => void;
  onSaveImage: (outputImg: string) => void;
  imageToBeModified: string | null;
  imageIdxToBeModified: number | null;
  images: { orignalImg: string; croppedImg: string }[];
  resetContext: () => void;
}
const ScannerContext = createContext<ContextType>({
  onEditModification: () => {},
  onRetakeModification: () => {},
  onInstantEdit: () => {},
  onInstantReatake: () => {},
  onSaveImage: () => {},
  imageToBeModified: '',
  images: [],
  imageIdxToBeModified: null,
  resetContext: () => {},
});

const ScannerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [images, setImages] = useState<
    { orignalImg: string; croppedImg: string }[]
  >([]);
  const [imageIdxToBeModified, setImageIdxToBeModified] = useState<
    number | null
  >(null); // either image will be retake or edited again
  const [imageToBeModified, setImageToBeModified] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  // function that will help for updating the final results
  const onRetakeModification = (idx: number) => {
    setImageIdxToBeModified(idx);
  };
  const onEditModification = (idx: number) => {
    setImageIdxToBeModified(idx);
    setImageToBeModified(images[idx].orignalImg);
  };
  // Function that will help for instant modification
  const onInstantEdit = (img: string) => {
    setImageToBeModified(img);
  };
  const onInstantReatake = () => {
    setImageToBeModified(null);
  };

  const onSaveImage = (outputImg: string) => {
    const newImages: { orignalImg: string; croppedImg: string }[] = [...images];
    // cause index can be 0 so in JS it is treated as falsy that is the reason for extra check
    if (imageIdxToBeModified === 0 || imageIdxToBeModified) {
      newImages[imageIdxToBeModified].croppedImg = outputImg;
      setImageIdxToBeModified(null);
      setImageToBeModified(null);
      setImages(newImages);
      navigate('preview');
    } else {
      newImages.push({
        orignalImg: imageToBeModified ? imageToBeModified : outputImg,
        croppedImg: outputImg,
      });
      setImages(newImages);
      setImageToBeModified(null);
    }
  };

  const resetContext = () => {
    setImages([]);
    setImageToBeModified(null);
    setImageIdxToBeModified(null);
  };

  const context: ContextType = {
    onEditModification,
    onRetakeModification,
    onInstantEdit,
    onInstantReatake,
    onSaveImage,
    imageToBeModified,
    images,
    imageIdxToBeModified,
    resetContext,
  };
  return (
    <ScannerContext.Provider value={context}>
      {children}
    </ScannerContext.Provider>
  );
};

export const useScanner: () => ContextType = () => {
  const ctx = useContext<ContextType>(ScannerContext);
  if (!ctx) {
    throw 'useScanner must be used in Scanner Context';
  }
  return ctx;
};

export default ScannerContextProvider;
