import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
  Button,
  Input,
  Checkbox,
} from '../../../../UI';
import { CrossIcon, SearchIcon } from '../../../../../assets/icons';
import {
  searchPatient,
  updatePatient,
} from '../../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../../utils/Helper';
import { notify } from '../../../../common/Alert';
import { ERROR_TITLE } from '../../../../../constants/NotificationConstants';
import Skeleton from 'react-loading-skeleton';
const AddPartnerModal = ({
  open,
  onClose,
  currentPatientPublicId,
  fetchNewPatient,
  patientPartnerId,
}: {
  open: boolean;
  onClose: () => void;
  currentPatientPublicId: string;
  fetchNewPatient: () => void;
  patientPartnerId?: string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [patients, setPatients] = useState<
    {
      fullName: string;
      publicId: string;
    }[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    patientPartnerId
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true);
      await searchPatient(search)
        .then(res => {
          const data: {
            fullName: string;
            publicId: string;
          }[] = [];
          res.data.forEach((patient: PatientLite) => {
            patient.publicId !== currentPatientPublicId &&
              data.push({
                fullName: getFullName(patient),
                publicId: patient.publicId,
              });
          });
          setPatients(data);
          setIsLoading(false);
        })
        .catch(err => {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
            message: err.data,
          });
          setIsLoading(false);
        });
    };

    if (search.length > 3) {
      handleSearch();
    }
  }, [search]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  const addPatientHandler = async () => {
    if (patientPartnerId === selectedValue) return;
    setIsUpdating(true);
    const patientData = {
      publicId: currentPatientPublicId,
      partnerPublicId: selectedValue ? selectedValue : '',
    };
    const [res, error] = await updatePatient(patientData);
    if (res) {
      notify.success({
        title: 'Successfully updated patient partner',
      });
    } else {
      notify.error({
        title: 'Failed to update patient partner',
        message: error.data,
      });
      setIsUpdating(false);
      return;
    }
    setIsUpdating(false);
    fetchNewPatient();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[424px] flex flex-col gap-0 border border-neutral-100 h-[440px]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {patientPartnerId ? 'Update partner' : 'Add partner'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <div className={patients.length > 0 ? '' : 'p-3'}>
          <Input
            leadingIcon={<SearchIcon />}
            placeholder="Search for Patient"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(event.target.value)
            }
            className={
              patients.length > 0 ? 'rounded-none border-0 border-b' : 'rounded'
            }
          />
        </div>
        <Slider className="flex-1" variant={'vertical'}>
          {isLoading ? (
            <>
              {Array.from({ length: 5 }, (_, index) => {
                return (
                  <>
                    <div key={index} className="px-3 py-2">
                      <Skeleton width={300} height={20} />
                      <Skeleton width={100} height={10} />
                    </div>
                  </>
                );
              })}{' '}
            </>
          ) : patients.length > 0 ? (
            patients.map(patient => (
              <PartnerItem
                key={patient.publicId}
                patientId={patient.publicId}
                name={patient.fullName}
                isSelected={patient.publicId === selectedValue}
                handleChange={handleChange}
              />
            ))
          ) : (
            <p className="text-[14px] text-neutral-900 text-center">
              No search result
            </p>
          )}
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            size={'small'}
            isLoading={isUpdating}
            disabled={isUpdating || isLoading}
            onClick={addPatientHandler}
            className="w-full"
          >
            {patientPartnerId ? 'Update' : 'Add'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const PartnerItem = ({
  name,
  patientId,
  isSelected,
  handleChange,
}: {
  name: string;
  patientId: string;
  isSelected: boolean;
  handleChange: (value: string) => void;
}) => (
  <div className="flex justify-between px-3 py-2">
    <div>
      <p className="font-medium text-neutral-900 text-[14px]">{name}</p>
      <p className="text-neutral-500 text-[12px]">{patientId}</p>
    </div>
    <Checkbox
      name={name}
      id={patientId}
      checked={isSelected}
      value={patientId}
      onCheckedChange={() => handleChange(patientId)}
    />
  </div>
);

export default AddPartnerModal;
