/* eslint-disable indent */
import {
  BoardIcon,
  ClockIcon,
  Dot,
  LocationIcon,
  OutlinedUserIcon,
} from '../../../assets/icons';
import {
  Slider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from '../../UI';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Filter as SessionFilter } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAppointments,
  updateAppointment,
} from '../../../redux/slice/todayAppointments';
import { getAppointmentsWithDate } from '../../../services/api/endPoints/bookings';
import { RootState } from '@/redux/store/store';
import {
  getNextDate,
  formatToDateTimeCalendar,
  getHumanReadableTime,
} from '../../../utils/date.utl';
import { getFullName } from '../../../utils/Helper';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/NavigationConstants';
import AllocateResourceOverviewModal from './AllocateResourceOverviewModal';
import ResourceIcon from '../../../assets/icons/ResourceIcon';

const allowedRoles = ['ConsultantManager', 'Superadmin'];
const Sessions = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { allAppointments } = useSelector<RootState, AllAppointmentState>(
    state => state.allAppointments
  );
  const locationsState = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const servicesState = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const staffsState = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const [filteredAppointments, setFilteredAppointments] =
    useState<SelectedDatesAppointment | null>(null);
  const [selectedDateAppointments, setSelectedDateAppointments] =
    useState<SelectedDatesAppointment | null>();
  const [allFilters, setAllFilters] = useState<SessionFilterType[]>();

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      setIsLoading(true);
      setDate(newDate);
    }
  };

  const filterAppointments = useCallback(
    (showCancelled: boolean, data: SelectedDatesAppointment) => {
      if (data) {
        const filteredData = showCancelled
          ? data.appointments
          : data.appointments.filter(appointment => !appointment.cancelled);
        setFilteredAppointments({
          appointments: filteredData,
          date: data.date,
        });
      }
      updateLoading(false);
    },
    []
  );

  const updateLoading = (value: boolean) => {
    setTimeout(() => setIsLoading(value), 500);
  };

  const returnedFilteredData = (
    data: SelectedDatesAppointment,
    value: boolean
  ) => {
    filterAppointments(value, data);
  };

  const fetchAppointments = async (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`;

    const fromDate = formattedDate;
    const toDate = getNextDate(new Date(formattedDate))
      .toISOString()
      .split('T')[0];

    const existingIndex = allAppointments?.findIndex(
      (item: { date: string }) => item.date === fromDate
    );

    if (existingIndex !== -1) {
      await setSelectedDateAppointments(allAppointments[existingIndex]);
    }

    try {
      const [res] = await getAppointmentsWithDate({
        fromDate,
        toDate,
      });
      if (res) {
        const data: Appointment[] = [];

        res.forEach((row: Appointment) => {
          const appointmentStartTime = new Date(row.appointmentTime);
          const durationMinutes = row.service.duration
            ? Number(row.service.duration)
            : 15;
          const appointmentEndTime = row.appointmentEndTime
            ? new Date(row.appointmentEndTime)
            : new Date(
                appointmentStartTime.getTime() + durationMinutes * 60000
              );

          data.push({
            ...row,
            fullName: row.patient.fullName,
            serviceName: row.service.name,
            startTime: formatToDateTimeCalendar(appointmentStartTime),
            endTime: formatToDateTimeCalendar(appointmentEndTime),
            appointmentTime: appointmentStartTime,
            appointmentEndTime: appointmentEndTime,
          });
        });

        dispatch(setAppointments({ appointments: data, date: fromDate }));
        setSelectedDateAppointments({ appointments: data, date: fromDate });
      } else {
        updateLoading(false);
      }
    } catch (error) {
      updateLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async (date: Date) => {
      await fetchAppointments(date);
    };

    if (date) {
      fetchData(date);
    }
  }, [date]);

  useEffect(() => {
    if (selectedDateAppointments) {
      filterAppointments(false, selectedDateAppointments);
    }
    updateLoading(false);
  }, [allAppointments, filterAppointments, selectedDateAppointments]);

  useEffect(() => {
    const createFilter = (
      label: string,
      key: string,
      placeholder: string,
      options: { value: string; isSelected: boolean; label: string }[],
      isBoolean = false
    ): SessionFilterType => ({
      label,
      key,
      placeholder,
      isBoolean,
      options,
    });

    const locationFilter = createFilter(
      'Location',
      'location.publicId',
      'Select a location',
      locationsState.map(item => ({
        value: item.publicId,
        isSelected: false,
        label: item.name,
      }))
    );

    const serviceFilter = createFilter(
      'Service',
      'service.publicId',
      'Select a service',
      servicesState.map(item => ({
        value: item.publicId,
        isSelected: false,
        label: item.name,
      }))
    );

    const consultantFilter = allowedRoles.includes(user.staff.type)
      ? createFilter(
          'Consultant',
          'staff.publicId',
          'Select a consultant',
          staffsState.map(item => ({
            value: item.publicId,
            isSelected: false,
            label: getFullName(item),
          }))
        )
      : null;

    const sessionFilter = createFilter(
      'Session',
      'cancelled',
      'Select an option',
      [
        { isSelected: false, value: 'false', label: 'Active' },
        { isSelected: false, value: 'true', label: 'Cancelled' },
      ],
      true
    );

    const filters: SessionFilterType[] = [locationFilter, serviceFilter];

    if (consultantFilter) {
      filters.push(consultantFilter);
    }

    filters.push(sessionFilter);
    setAllFilters(filters);
  }, [
    locationsState,
    servicesState,
    staffsState,
    allowedRoles,
    user.staff.type,
  ]);

  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>();

  const updateFilteredAppointments = useCallback(
    (updatedAppointment: Appointment) => {
      setFilteredAppointments(prevState => {
        if (prevState) {
          return {
            ...prevState,
            appointments: prevState.appointments.map(appt =>
              appt.publicId === updatedAppointment.publicId
                ? updatedAppointment
                : appt
            ),
          };
        }
        return null;
      });
    },
    []
  );
  const updateAppointmentFunction = (appointmentEvent: AppointmentEvent) => {
    const updatedAppointment = {
      ...selectedAppointment,
      ...appointmentEvent,
      startTime: formatToDateTimeCalendar(appointmentEvent.appointmentTime),
      endTime: formatToDateTimeCalendar(appointmentEvent.appointmentEndTime),
    };

    dispatch(updateAppointment(updatedAppointment as Appointment));

    updateFilteredAppointments(updatedAppointment as Appointment);
  };

  return (
    <div className="sm:h-full md:h-full lg:h-full xl:custom-height rounded-md border border-neutral-100 flex flex-col">
      <div className="flex-none shadow-sm">
        <div className="flex justify-between items-center  bg-white z-50 rounded-t-lg ">
          <h2 className="font-semibold text-[16px] text-neutral-900 px-[12px] py-[14px]">
            Upcoming Sessions
          </h2>
          <div className="flex gap-3 items-center justify-center pr-2">
            {allFilters && selectedDateAppointments && (
              <SessionFilter
                filters={allFilters}
                setAllFilters={setAllFilters}
                initialData={selectedDateAppointments}
                returnFunction={returnedFilteredData}
              />
            )}
            <DatePicker variant="date" handleDateChange={handleDateChange} />
          </div>
        </div>
        {filteredAppointments && !isLoading && (
          <div className="bg-neutral-50 p-[12px] font-semibold text-neutral-900 border-b border-t border-neutral-100 z-455 flex justify-between items-center">
            <div>{getHumanReadableTime(filteredAppointments.date)}</div>
            <div className="flex items-center text-primary-600 text-[0.75rem]">
              <Dot />{' '}
              <p className="ml-2">
                {filteredAppointments.appointments.length === 1
                  ? '1 Session'
                  : filteredAppointments.appointments.length + ' Sessions'}
              </p>
            </div>
          </div>
        )}
      </div>
      <Slider variant={'vertical'} className="flex-auto">
        <div className="flex flex-1 overflow-auto">
          <ul className="w-full">
            {isLoading ? (
              <SessionItemSkeleton />
            ) : (
              filteredAppointments && (
                <>
                  {filteredAppointments.appointments
                    ?.slice()
                    .sort(
                      (a, b) =>
                        new Date(a.appointmentTime).getTime() -
                        new Date(b.appointmentTime).getTime()
                    )
                    .map(session => (
                      <li key={session.publicId}>
                        <SessionItem
                          key={session.publicId}
                          appointment={session}
                          setSelectedAppointment={setSelectedAppointment}
                        />
                      </li>
                    ))}
                </>
              )
            )}
          </ul>
        </div>
      </Slider>
      {selectedAppointment && (
        <AllocateResourceOverviewModal
          event={selectedAppointment}
          open={!!selectedAppointment}
          updateEvent={updateAppointmentFunction}
          onClose={() => setSelectedAppointment(null)}
        />
      )}
    </div>
  );
};

export default Sessions;

const SessionItem: React.FC<SessionItemProps> = ({
  appointment,
  setSelectedAppointment,
}) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);

  return (
    <>
      <SessionDetailsTooltip appointment={appointment}>
        <li className="flex justify-between text-neutral-900 font-medium px-[12px] p-[16px] border-b border-netral-100 text-[14px] cursor-pointer hover:bg-primary-100 transition-all delay-150">
          <div className="columns-2 w-full flex flex-row gap-3 items-start justify-between">
            <div className="w-full flex flex-col gap-3">
              <div className="flex flex-row justify-between">
                <Link
                  className="flex w-1/2"
                  to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${appointment.patient.publicId}`}
                  state={{ from: ROUTES.ROUTE_TO_OVERVIEW }}
                >
                  <p className="text-primary-600  pb-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {appointment.fullName}
                  </p>
                </Link>
                <p className="flex justify-end items-center gap-2 w-1/2 overflow-ellipsis">
                  <ClockIcon /> {appointment.startTime} - {appointment.endTime}
                </p>
              </div>
              <div className="flex flex-row columns-4 gap-4">
                <p className="flex w-1/4 items-center mt-[-0.25rem]">
                  <BoardIcon />{' '}
                  <span className="ml-2 add-ellipsis">
                    {appointment.serviceName || ''}
                  </span>
                </p>
                <p className="flex w-1/4 items-center">
                  <LocationIcon />{' '}
                  <span className="ml-2 add-ellipsis">
                    {appointment.location.name}
                  </span>
                </p>
                <p className="flex w-1/4 items-center">
                  <ResourceIcon />{' '}
                  <span
                    className={
                      appointment.resource
                        ? 'ml-2 add-ellipsis underline underline-offset-2'
                        : 'text-primary-600 ml-2 add-ellipsis'
                    }
                    onClick={() => {
                      setSelectedAppointment(appointment);
                    }}
                  >
                    {appointment.resource
                      ? appointment.resource?.name
                      : 'Allocate resource'}
                  </span>
                </p>
                {appointment.staff &&
                  allowedRoles.includes(user.staff.type) && (
                    <p className="flex w-1/4 items-center">
                      <OutlinedUserIcon />{' '}
                      <span className="ml-2 add-ellipsis">{`${appointment.staff.fullName}`}</span>
                    </p>
                  )}
              </div>
            </div>
          </div>
        </li>
      </SessionDetailsTooltip>
    </>
  );
};

const SessionDetailsTooltip = ({
  appointment,
  children,
}: SessionDetailsTooltipType) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <div>{children}</div>
      </TooltipTrigger>
      <TooltipContent className="z-[99999]" side="top">
        <div className="w-[300px] md:w-[376px] flex flex-col gap-3 font-medium">
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Patient</p>
            <p className="text-[14px] text-neutral-900">
              {appointment.fullName}
            </p>
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Service</p>
            <p className="text-[14px] text-neutral-900">
              {appointment.serviceName}
            </p>
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Location</p>
            <p className="text-[14px] text-neutral-900">
              {appointment.location.name}
            </p>
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Resource</p>
            {appointment.resource?.name ? (
              <p className="text-[14px] text-neutral-900">
                {appointment.resource?.name}
              </p>
            ) : (
              <p className="text-[14px] text-neutral-900">-</p>
            )}
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Consultant</p>
            <p className="text-[14px] text-neutral-900">
              {getFullName(appointment.staff)}
            </p>
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Appointment Time</p>
            <p className="text-[14px] text-neutral-900">
              {appointment.startTime} - {appointment.endTime}
            </p>
          </div>
          <div className="w-full grid grid-cols-2">
            <p className="text-[14px] text-neutral-400">Invoice Status</p>
            {appointment.paymentStatus === 'Paid' && (
              <p className="px-[8px] py-[2px] text-success-500 border border-success-500 rounded rounded-l bg-success-50 w-[60px] text-center">
                Paid
              </p>
            )}
            {/* Not Paid Design Need to be implemented */}
            {appointment.paymentStatus === 'Pending' && (
              <p className="px-[8px] py-[2px] text-warning-500 border border-warning-500 rounded rounded-l bg-warning-50 w-[85px] text-center">
                Pending
              </p>
            )}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const SessionItemSkeleton = () => {
  // Create an array to hold the JSX elements
  const skeletonItems = [];

  // Loop to create multiple skeleton items
  for (let i = 0; i < 5; i++) {
    skeletonItems.push(
      <li
        key={i}
        className="flex justify-between text-neutral-900 font-medium px-[12px] p-[16px] border-t border-neutral-100 text-[14px] cursor-pointer transition-all delay-150"
      >
        <div className="w-full flex flex-row gap-3 items-start justify-between">
          <div>
            <Skeleton width={200} />
            <div className="flex  columns-3 gap-4 mt-2">
              <p className="flex  w-1/3 items-center">
                <BoardIcon />{' '}
                <span className="ml-2">
                  <Skeleton width={120} />
                </span>
              </p>
              <p className="flex w-1/3  items-center">
                <LocationIcon />{' '}
                <span className="ml-2">
                  <Skeleton width={120} />
                </span>
              </p>
              <p className="flex w-1/3 items-center">
                <OutlinedUserIcon />{' '}
                <span className="ml-2">
                  <Skeleton width={120} />
                </span>
              </p>
            </div>
          </div>
          <p className="flex items-center gap-2">
            <ClockIcon /> <Skeleton width={150} />
          </p>
        </div>
      </li>
    );
  }

  // Render the array of skeleton items
  return (
    <SkeletonTheme
      // baseColor={THEME.PRIMARY_600}
      // highlightColor={THEME.PRIMARY_700}
      borderRadius="0.5rem"
      duration={4}
    >
      <li className="bg-neutral-50 p-[12px] font-semibold text-neutral-900 border-b border-t border-neutral-100 sticky top-0 z-99 flex justify-between items-center">
        <div>
          <Skeleton width={100} height={25} />
        </div>
        <div className="flex items-center text-primary-600 text-[0.75rem]">
          <Dot />{' '}
          <p className="ml-2">
            <Skeleton width={64} height={15} />
          </p>
        </div>
      </li>
      {skeletonItems} {/* Render the array of skeleton items */}
    </SkeletonTheme>
  );
};
