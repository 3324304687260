import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  discontinueMedicine,
  getAllMedicationsByTid,
} from '../../../../../../services/api';
import {
  formatToYYYYMMDD,
  getReadableDate,
} from '../../../../../../utils/date.utl';
import AddMedicationModal from '../Medications/AddMedicationModal';
import {
  AlertDialog,
  DataTable,
  DatePicker,
  notify,
} from '../../../../../../components/common';
import { Button, Label } from '../../../../../../components/UI';
import { ColumnDef } from '@tanstack/react-table';
import { Ban } from 'lucide-react';
import { AddIcon, OpenEyeIcon2 } from '../../../../../../assets/icons';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_MESSAGE,
  SUCCESS_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { MEDICATION_CONSTANTS } from '../../../../../../constants/constants';
import ViewMedicationModal from '../Medications/ViewMedicationModal';

const MedicationsView = () => {
  const { treatmentCycleId } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [medications, setMedications] = useState<Medication[]>([]);
  const [discontinueDate, setDiscontinueDate] = useState(new Date());
  const [selectedMedication, setSelectedMedication] =
    useState<Medication | null>(null);
  const [actionSelected, setActionSelected] = useState<
    'View' | 'Create' | 'Edit' | null
  >(null);

  const getMedications = useCallback(async (treatmentId: string) => {
    setIsLoading(true);
    const [response, error] = await getAllMedicationsByTid(treatmentId);
    setIsLoading(false);
    if (error) {
      return;
    }
    setMedications(response);
  }, []);

  useEffect(() => {
    treatmentCycleId && getMedications(treatmentCycleId);
  }, [treatmentCycleId, getMedications]);

  const closeDeleteModalHandler = useCallback(() => {
    setIsDeleting(false);
    setIsDeleteModalOpen(false);
    setSelectedMedication(null);
  }, []);

  const discontinueMedication = useCallback(async () => {
    if (selectedMedication) {
      setIsDeleting(true);

      const [response, error] = await discontinueMedicine(
        selectedMedication.publicId,
        formatToYYYYMMDD(discontinueDate)
      );

      if (error) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: error?.data || ERROR_MESSAGE.MEDICATION_DISCONTINUE,
        });
        setIsDeleting(false);
        return;
      }

      setIsDeleting(false);
      setIsDeleteModalOpen(false);

      // Update the medications with the new response
      setMedications(prevMedications =>
        prevMedications.map(medication =>
          medication.publicId === selectedMedication.publicId
            ? { ...medication, ...response } // Assuming `response` contains updated medication data
            : medication
        )
      );

      notify.success({
        title: SUCCESS_TITLE.GENERIC_SUCCESS_TITLE,
        message: SUCCESS_MESSAGE.MEDICATION_DISCONTINUE,
      });

      closeDeleteModalHandler();
    }
  }, [closeDeleteModalHandler, discontinueDate, selectedMedication]);

  const column: ColumnDef<Medication>[] = [
    {
      accessorKey: 'medicationName',
      header: 'Medicine Name',
    },
    {
      accessorKey: 'dosage',
      header: 'Dosage',
      cell: ({ row }) => {
        return (
          <>
            {row.original.dosage} {row.original.dosageUnit}
          </>
        );
      },
    },
    {
      accessorKey: 'dosageFrequency',
      header: 'Frequency',
      cell: ({ row }) => {
        return (
          <>
            {
              MEDICATION_CONSTANTS.DOSAGE_FREQUENCIES[
                row.original.dosageFrequency
              ].label
            }
          </>
        );
      },
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      cell: ({ row }) => {
        return <>{getReadableDate(row.original.startDate)}</>;
      },
    },
    {
      accessorKey: 'endDate',
      header: 'End Date',
      cell: ({ row }) => {
        return (
          <>{row.original.endDate && getReadableDate(row.original.endDate)}</>
        );
      },
    },
    {
      accessorKey: 'discontinuedOn',
      header: 'Discontinued On',
      cell: ({ row }) => {
        return (
          <>
            {row.original.discontinuedOn &&
              getReadableDate(row.original.discontinuedOn)}
          </>
        );
      },
    },
    {
      id: 'action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-end gap-8">
            <div className="flex gap-2">
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<OpenEyeIcon2 />}
                isLoading={false}
                onClick={() => {
                  setActionSelected('View');
                  setSelectedMedication(row.original);
                }}
              >
                View
              </Button>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<Ban height={16} stroke="currentColor" />}
                isLoading={false}
                onClick={() => {
                  setSelectedMedication(row.original);
                  setIsDeleteModalOpen(true);
                }}
                disabled={!!row.original.discontinuedOn}
              >
                Discontinue
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mt-[30px]">
        <p className="text-[20px] font-bold text-neutral-700">Medication</p>
        <Button
          variant={'primary'}
          className="h-[30px]"
          size={'xmall'}
          trailingIcon={<AddIcon />}
          isLoading={false}
          onClick={() => {
            setActionSelected('Create');
          }}
        >
          Add Medication
        </Button>
      </div>
      {medications.length > 0 && (
        <div className="border-x border-b mt-4">
          <DataTable
            columns={column}
            data={medications}
            isLoading={isLoading}
          />
        </div>
      )}
      {actionSelected === 'Create' && (
        <AddMedicationModal
          setMedications={setMedications}
          selectedMedication={selectedMedication}
          type={actionSelected}
          onClose={() => {
            setActionSelected(null);
            setSelectedMedication(null);
          }}
        />
      )}
      {actionSelected === 'View' && (
        <ViewMedicationModal
          selectedMedication={selectedMedication}
          type={actionSelected}
          onClose={() => {
            setActionSelected(null);
            setSelectedMedication(null);
          }}
        />
      )}
      {isDeleteModalOpen && selectedMedication && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Discontinue' : 'Updating'}
          alertTitle={`Discontinue ${selectedMedication.medicationName}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={discontinueMedication}
          isActionStarted={isDeleting}
        >
          <p>
            Are you sure you want to discontinue the medication{' '}
            <strong>{selectedMedication.medicationName}</strong>? This action
            cannot be undone.
          </p>
          <div className="w-full mt-4">
            <Label className="">
              Please select the date from which the medication should <br />
              be discontinued:
            </Label>
            <DatePicker
              variant="fullDate"
              handleDateChange={value => {
                value && setDiscontinueDate(value);
              }}
              initialValue={discontinueDate}
            />
          </div>
        </AlertDialog>
      )}
    </div>
  );
};

export default MedicationsView;
