import { URL } from '../requestConstructor';

export const getAllHormoneLevel = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/hormoneLevel/get?treatmentCyclePublicId=${treatmentCyclePublicId}`
    );

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const createHormoneLevel = async (data: CreateHormoneLevelRequest) => {
  try {
    const response = await URL.post('/hormoneLevel/create', data);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateHormoneLevel = async (data: UpdateHormoneLevelRequest) => {
  try {
    const response = await URL.put('/hormoneLevel/update', data);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteHormoneLevel = async (publicId: string) => {
  try {
    const response = await URL.delete(
      `/hormoneLevel/delete?publicId=${publicId}`
    );

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};