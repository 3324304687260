import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
  Textarea,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import {
  createSemenAnalysis,
  updateSemenAnalysis,
} from '../../../../../services/api/endPoints/semenAnalysis';
import {
  addSemenAnalysis,
  updateSemenAnalysis as updateSemenAnalysisRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../common/Alert';
import { DatePicker } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import RadioButton from '../../../../../components/UI/RadioButton';

const SemenAnalysisModal = ({
  open,
  onClose,
  ...props
}: AddAndEditSemenAnalysisModalProps) => {
  const { treatmentCycleId, id } = useParams();
  const [formData, setFormData] = useState<SemenAnalysisForm>();

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);
  const patient = useSelector<RootState, PatientState>(state => state.patient);
  const partner = useSelector<RootState, PartnerState>(state => state.partner);
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const examPurposeOptions = useMemo(
    () => ['Diagnostic', 'Freeze', 'Treatment'],
    []
  );

  const collectionLocationOptions = useMemo(
    () => ['Clinic', 'Home', 'Other'],
    []
  );

  const collectionMethodOptions = useMemo(
    () => [
      'Fresh ejaculation',
      'Non-spermicidal condom',
      'PESA',
      'Retrograde',
      'Sperm electro ejaculate',
      'TESA',
      'TESE',
    ],
    []
  );

  const spermSourceOptions = useMemo(() => ['Donor', 'Own sample'], []);

  const appearanceOptions = useMemo(
    () => [
      'Deep Yellow',
      'Green',
      'Light Yellow',
      'Opalescent',
      'Other',
      'Red or Brown',
      'Transparent',
    ],
    []
  );

  const liquefactionOptions = useMemo(
    () => ['Delayed', 'Immediate', 'Incomplete', 'No', 'Normal'],
    []
  );

  const viscosityOptions = useMemo(
    () => ['High Viscosity', 'Low Viscosity', 'Normal', 'Very High Viscosity'],
    []
  );

  const agglutinationOptions = useMemo(
    () => ['Absent', 'Head to head', 'Head to tail', 'Mixed', 'Tail to tail'],
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const setInitialData = async () => {
      if (!id) return;
      let currentFormData: Record<string, any> = {
        patientPublicId: !patient.patient.partnerPublicId ? id : '',
        analysisDate: new Date(),
        analysedByPublicId: user.staff.publicId,
      };

      if (props.type === 'Edit') {
        currentFormData = {
          ...currentFormData,
          analysisDate: props.semenAnalysis?.analysisDate
            ? new Date(props.semenAnalysis.analysisDate)
            : new Date(),
          sampleReceivedDate: props.semenAnalysis?.sampleReceivedDate
            ? new Date(props.semenAnalysis.sampleReceivedDate)
            : new Date(),
          analysedByPublicId: props.semenAnalysis?.analysedBy?.publicId ?? '',
          generalComments: props.semenAnalysis?.generalComments ?? '',
          examPurpose: props.semenAnalysis?.examPurpose ?? '',
          collectionLocation: props.semenAnalysis?.collectionLocation ?? '',
          collectionMethod: props.semenAnalysis?.collectionMethod ?? '',
          daysOfAbstinence: props.semenAnalysis?.daysOfAbstinence,
          completeSample: props.semenAnalysis?.completeSample ?? true,
          spermSource: props.semenAnalysis?.spermSource ?? '',
          appearance: props.semenAnalysis?.appearance ?? '',
          liquefaction: props.semenAnalysis?.liquefaction ?? '',
          viscosity: props.semenAnalysis?.viscosity ?? '',
          volume: props.semenAnalysis?.volume,
          concentration: props.semenAnalysis?.concentration,
          count: props.semenAnalysis?.count,
          acidityPh: props.semenAnalysis?.acidityPh,
          vitality: props.semenAnalysis?.vitality,
          normalMorphology: props.semenAnalysis?.normalMorphology,
          rapidProgressive: props.semenAnalysis?.rapidProgressive,
          slowProgressive: props.semenAnalysis?.slowProgressive,
          nonProgressive: props.semenAnalysis?.nonProgressive,
          immotile: props.semenAnalysis?.immotile,
          roundCellCount: props.semenAnalysis?.roundCellCount,
          agglutination: props.semenAnalysis?.agglutination,
          agglutinationType: props.semenAnalysis?.agglutinationType ?? '',
          marTest: props.semenAnalysis?.marTest ?? false,
          patientPublicId: props.semenAnalysis?.patient?.publicId ?? '',
        };
      }

      setFormData(currentFormData as SemenAnalysisForm);
    };

    setInitialData();
  }, []);

  const isValidInput = () => {
    const errors: Record<string, string> = { ...formErrors };

    if (!formData?.analysisDate) {
      errors.analysisDate = 'Analysis date is required';
    }

    if (!formData?.examPurpose || formData.examPurpose === '') {
      errors.examPurpose = 'Exam purpose is required';
    }

    if (!formData?.appearance || formData.appearance === '') {
      errors.appearance = 'Appearance is required';
    }

    if (!formData?.analysedByPublicId || formData.analysedByPublicId === '') {
      errors.analysedByPublicId = 'Analysed by is required';
    }

    if (!formData?.patientPublicId || formData.patientPublicId === '') {
      errors.analysedByPublicId = 'Patient is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const inputHandler = (name: keyof SemenAnalysisForm, value: any) => {
    const errors = { ...formErrors };
    if (errors[name]) {
      delete errors[name];
    }
    if (!formData) {
      setFormData({
        [name]: value,
      } as SemenAnalysisForm);
    } else {
      const newForm = { ...formData, [name]: value };
      setFormData(newForm);
    }

    setFormErrors(errors);
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    setIsLoading(true);

    if (props.type === 'Create') {
      // Prepare data for creation
      const data = {
        treatmentCyclePublicId: treatmentCycleId,
        ...formData,
      };

      const [res, error] = await createSemenAnalysis(
        data as CreateSemenAnalysisRequest
      );

      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SEMEN_ANALYSIS_ADD,
          message: `Semen analysis '${res.publicId}' has been added`,
        });
        dispatch(addSemenAnalysis(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SEMEN_ANALYSIS_ADD,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else if (props.type === 'Edit') {
      // Prepare data for update
      const data = {
        publicId: props.semenAnalysis.publicId,
        ...formData,
      };

      const [res, error] = await updateSemenAnalysis(
        data as UpdateSemenAnalysisRequest
      );

      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SEMEN_ANALYSIS_UPDATE,
          message: `Semen analysis '${res.publicId}' has been updated`,
        });
        dispatch(updateSemenAnalysisRedux(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SEMEN_ANALYSIS_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[70vw] md:w-[50vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              {props.type === 'Edit'
                ? 'Edit semen analysis'
                : 'Add semen analysis'}
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1 space-y-3" variant={'vertical'}>
            <div className="p-3 grid grid-cols-2 gap-3">
              <div className="space-y-2 ">
                <div className="grid grid-cols-2">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Analysis date
                  </Label>
                  <div>
                    <DatePicker
                      initialValue={formData?.analysisDate}
                      variant="fullDate"
                      hasError={!!formErrors.analysisDate}
                      handleDateChange={value =>
                        inputHandler('analysisDate', value)
                      }
                    />
                    {formErrors.analysisDate && (
                      <small className="text-destructive-500">
                        {formErrors.analysisDate}
                      </small>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Sample received</Label>
                  <DatePicker
                    initialValue={formData?.sampleReceivedDate}
                    variant="fullDate"
                    handleDateChange={value =>
                      inputHandler('sampleReceivedDate', value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Analysed by
                  </Label>
                  <div className="space-y-1">
                    <Select
                      name="performedByPublicId"
                      value={formData?.analysedByPublicId ?? ''}
                      onValueChange={value =>
                        inputHandler('analysedByPublicId', value)
                      }
                    >
                      <SelectTrigger hasError={!!formErrors.analysedByPublicId}>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[9999999]">
                        {staffs.length > 0 ? (
                          staffs.map(staff => (
                            <SelectItem
                              key={staff.publicId}
                              value={staff.publicId}
                            >
                              {staff.fullName}
                            </SelectItem>
                          ))
                        ) : (
                          <p>No staffs found to analyse semen</p>
                        )}
                      </SelectContent>
                    </Select>
                    {formErrors.analysedByPublicId && (
                      <small className="text-destructive-500">
                        {formErrors.analysedByPublicId}
                      </small>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Patient
                  </Label>
                  <div className="space-y-1">
                    <Select
                      name="patientPublicId"
                      value={formData?.patientPublicId ?? ''}
                      onValueChange={value =>
                        inputHandler('patientPublicId', value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[9999999]">
                        <SelectItem value={patient.patient.publicId}>
                          {patient.patient.fullName}
                        </SelectItem>
                        {partner.partner.publicId && (
                          <SelectItem value={partner.partner.publicId}>
                            {partner.partner.fullName} - partner
                          </SelectItem>
                        )}
                      </SelectContent>
                    </Select>
                    {formErrors.patientPublicId && (
                      <small className="text-destructive-500">
                        {formErrors.patientPublicId}
                      </small>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <Label>Collection location</Label>
                  <Select
                    name="collectionLocation"
                    value={formData?.collectionLocation ?? ''}
                    onValueChange={value =>
                      inputHandler('collectionLocation', value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {collectionLocationOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Collection method</Label>
                  <Select
                    name="collectionMethod"
                    value={formData?.collectionMethod ?? ''}
                    onValueChange={value =>
                      inputHandler('collectionMethod', value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {collectionMethodOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="space-y-2">
                <div className="space-y-1">
                  <Label>General comments</Label>
                  <Textarea
                    value={formData?.generalComments}
                    onChange={e =>
                      inputHandler('generalComments', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Exam purpose
                  </Label>
                  <div className="space-y-1">
                    <Select
                      name="examPurpose"
                      value={formData?.examPurpose ?? ''}
                      onValueChange={value =>
                        inputHandler('examPurpose', value)
                      }
                    >
                      <SelectTrigger hasError={!!formErrors.examPurpose}>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[9999999]">
                        {examPurposeOptions.map(option => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {formErrors.examPurpose && (
                      <small className="text-destructive-500">
                        {formErrors.examPurpose}
                      </small>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Days of abstinence</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    value={formData?.daysOfAbstinence}
                    onChange={e =>
                      inputHandler('daysOfAbstinence', e.target.value)
                    }
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        days
                      </p>
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Complete sample</Label>
                  <div className="flex gap-2">
                    <RadioButton
                      selectedValue={formData?.completeSample}
                      optionLabel="Yes"
                      label="completeSample"
                      value={true}
                      onChange={val => inputHandler('completeSample', val)}
                    />
                    <RadioButton
                      selectedValue={formData?.completeSample}
                      optionLabel="No"
                      label="completeSample"
                      value={false}
                      onChange={val => inputHandler('completeSample', val)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Sperm source</Label>
                  <Select
                    name="spermSource"
                    value={formData?.spermSource ?? ''}
                    onValueChange={value => inputHandler('spermSource', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {spermSourceOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <hr />
            <Label className="text-[20px] font-bold p-3">Analysis</Label>
            <div className="p-3 grid grid-cols-2 gap-3">
              <div className="space-y-2">
                <div className="grid grid-cols-2">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Appearance
                  </Label>
                  <div className="space-y-1">
                    <Select
                      name="appearance"
                      value={formData?.appearance ?? ''}
                      onValueChange={value => inputHandler('appearance', value)}
                    >
                      <SelectTrigger hasError={!!formErrors.appearance}>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[9999999]">
                        {appearanceOptions.map(option => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {formErrors.appearance && (
                      <small className="text-destructive-500">
                        {formErrors.appearance}
                      </small>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Liquefaction</Label>
                  <Select
                    name="liquefaction"
                    value={formData?.liquefaction ?? ''}
                    onValueChange={value => inputHandler('liquefaction', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {liquefactionOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Viscosity</Label>
                  <Select
                    name="liquefaction"
                    value={formData?.viscosity ?? ''}
                    onValueChange={value => inputHandler('viscosity', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {viscosityOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Volume (ml)</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    value={formData?.volume}
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        mL
                      </p>
                    }
                    onChange={e => inputHandler('volume', e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Concentration</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    value={formData?.concentration}
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        10<sup>6</sup>/mL
                      </p>
                    }
                    onChange={e =>
                      inputHandler('concentration', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Count</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        M/ejac
                      </p>
                    }
                    value={formData?.count}
                    onChange={e => inputHandler('count', e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Acidity/basicity</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        pH
                      </p>
                    }
                    value={formData?.acidityPh}
                    onChange={e => inputHandler('acidityPh', e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Vitality percent</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.vitality}
                    onChange={e => inputHandler('vitality', e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div className="grid grid-cols-2">
                  <Label>Normal morphology</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.normalMorphology}
                    onChange={e =>
                      inputHandler('normalMorphology', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Rapid progressive</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.rapidProgressive}
                    onChange={e =>
                      inputHandler('rapidProgressive', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Slow progressive</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.slowProgressive}
                    onChange={e =>
                      inputHandler('slowProgressive', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Non progressive</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.nonProgressive}
                    onChange={e =>
                      inputHandler('nonProgressive', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Immotile</Label>
                  <Input
                    type="number"
                    className="no-spinner"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    value={formData?.immotile}
                    onChange={e => inputHandler('immotile', e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Round cell count</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        10<sup>6</sup>/mL
                      </p>
                    }
                    value={formData?.roundCellCount}
                    onChange={e =>
                      inputHandler('roundCellCount', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Agglutination</Label>
                  <Input
                    className="no-spinner"
                    type="number"
                    value={formData?.agglutination}
                    trailingIcon={
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        %
                      </p>
                    }
                    onChange={e =>
                      inputHandler('agglutination', e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2">
                  <Label>Agglutination type</Label>
                  <Select
                    name="agglutinationType"
                    value={formData?.agglutinationType ?? ''}
                    onValueChange={value =>
                      inputHandler('agglutinationType', value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[9999999]">
                      {agglutinationOptions.map(option => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-2">
                  <Label>Complete sample</Label>
                  <div className="flex gap-2">
                    <RadioButton
                      selectedValue={formData?.marTest}
                      optionLabel="Yes"
                      label="marTest"
                      value={true}
                      onChange={val => inputHandler('marTest', val)}
                    />
                    <RadioButton
                      selectedValue={formData?.marTest}
                      optionLabel="No"
                      label="marTest"
                      value={false}
                      onChange={val => inputHandler('marTest', val)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              variant={'primary'}
              onClick={handleSubmit}
              className="w-full"
              isLoading={isLoading}
              disabled={isLoading}
            >
              {props.type === 'Edit'
                ? 'Save semen analysis'
                : 'Add semen analysis'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SemenAnalysisModal;
