import React from 'react';
import { Button } from '../../../components/UI';
import { RightArrow } from '../../../assets/icons';
import locationImage from '../../../assets/images/LoginImg.png';

interface LocationCardProps {
  //   location: LocationEntities;
  location: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LocationCard: React.FC<LocationCardProps> = ({ location }) => {
  return (
    <div className="location-card  px-[16px] border-b border-neutral-100   justify-between items-center py-[12px]">
      {/* <Profile location={location} /> */}
      <img src={locationImage}></img>

      <Button
        size={'large'}
        variant={'secondary'}
        className="w-full"
        trailingIcon={<RightArrow />}
      >
        Location Name
      </Button>
    </div>
  );
};

export default LocationCard;
