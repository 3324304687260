import React, { FC, useState } from 'react';
import MediaSvg from '../../assets/icons/Media.svg';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils';
import { InputProps, LabelProps } from '../../types/uiComponents/type';
import { OpenEyeIcon } from '../../assets/icons';

const inputVariants = cva(
  'w-full px-[16px] py-[12px] border placeholder-neutral-400 rounded-[4px] outline-none disabled:cursor-not-allowed disabled:bg-neutral-100/30 disabled:text-neutral-400',
  {
    variants: {
      variant: {
        default:
          'border-neutral-100 bg-shades-0 hover:border-primary-300 focus:border-primary-500',
        destructive: 'border-destructive-300',
      },
      inputSize: {
        small: 'h-[36px]',
        medium: 'h-[40px]',
        large: 'h-[44px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      inputSize: 'medium',
    },
  }
);

const Label: FC<LabelProps> = ({ className, ...props }) => {
  return (
    <label
      className={cn([
        'text-[14px] font-medium text-neutral-900 whitespace-nowrap',
        className,
      ])}
      {...props}
    />
  );
};

const Input: FC<InputProps> = ({
  inputSize,
  hasError,
  type,
  className,
  leadingIcon,
  trailingIcon,
  wrapperClass,
  helperText,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  if (type === 'password') {
    return (
      <>
        <div className={cn(['relative', wrapperClass])}>
          {leadingIcon && (
            <div className="absolute left-4 top-[13px]">{leadingIcon}</div>
          )}
          <input
            placeholder="Enter your password"
            className={cn(
              inputVariants({
                variant: !hasError ? 'default' : 'destructive',
                inputSize,
                className,
              })
            )}
            type={showPassword ? 'text' : type}
            {...props}
          />
          <img
            src={showPassword ? MediaSvg : OpenEyeIcon}
            alt="show"
            className="absolute text-2xl text-gray-500 cursor-pointer right-4 top-[13px]"
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        {helperText && (
          <p className="mt-1 text-sm text-gray-500">{helperText}</p>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className={cn(['relative', wrapperClass])}>
          {leadingIcon && (
            <div className="absolute left-[0.8rem] top-[12px]">
              {leadingIcon}
            </div>
          )}
          <input
            className={cn(
              inputVariants({
                variant: !hasError ? 'default' : 'destructive',
                inputSize,
                className,
              }),
              leadingIcon && 'pl-[32px]'
            )}
            type={type ? type : 'text'}
            {...props}
          />
          {trailingIcon && (
            <div className="absolute right-4 top-[8px] bg-white">
              {trailingIcon}
            </div>
          )}
        </div>
        {helperText && (
          <p className="mt-1 text-sm text-gray-500">{helperText}</p>
        )}
      </>
    );
  }
};

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
  helperText?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ hasError, helperText, className, ...props }, ref) => {
    return (
      <>
        <textarea
          className={cn(
            'flex min-h-[80px] w-full rounded-md border bg-background px-3 py-2 text-sm  placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 hover:border-primary-300 focus:border-primary-500',
            className,
            hasError && 'border-destructive-300'
          )}
          ref={ref}
          {...props}
        />
        {helperText && (
          <p className="mt-1 text-sm text-gray-500">{helperText}</p>
        )}
      </>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Input, Label, inputVariants, Textarea };
