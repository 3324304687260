/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-unused-vars
import { ERROR_TO_DO } from '../../../types/index';
import { URL } from '../requestConstructor';

export const getTopTestFromTDL = async () => {
  try {
    const response = await URL.get('/lab/tdl/test/top');
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

export const searchTDLTest = async (searchTerm: string) => {
  try {
    const response = await URL.get(
      `lab/tdl/test/search?searchTerm=${searchTerm}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

export const createTDLTest = async (testDetails: ERROR_TO_DO) => {
  try {
    const response = await URL.post('lab/tdl/test/create', testDetails);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const getAllRequestedTests = async (
  fromDate: ERROR_TO_DO,
  toDate: ERROR_TO_DO
) => {
  try {
    const response = await URL.get(
      `lab/tdl/test/request/search?fromDate=${fromDate}&toDate=${toDate}`
    );
    return [response.data, null];
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

export const getAllTestResults = async (fromDate: any, toDate: any) => {
  try {
    const response = await URL.get(
      `/lab/tdl/test/result/search?fromDate=${fromDate}&toDate=${toDate}`
    );
    return [response.data, null];
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

export const getAllTDLTestRequests = async (
  queryParams?: ApiGetTDLRequestedTestsParams
): Promise<[any, any]> => {
  try {
    let finalUrl = '';
    if (queryParams) {
      const queryString = Object.keys(queryParams)
        .map(
          key =>
            `${key}=${queryParams[key as keyof ApiGetTDLRequestedTestsParams]}`
        )
        .join('&');

      finalUrl = `lab/tdl/test/get?${queryString}`;
    } else {
      finalUrl = 'lab/tdl/test/get';
    }
    const response = await URL.get(finalUrl);

    return [response.data, null];
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

interface ApiGetTDLRequestedTestsParams {
  publicId?: string;
  testRequestId?: string;
  testResultId?: string;
  testReferenceId?: string;
  patientPublicId?: string;
  staffPublicId?: string;
  testCode?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;

  // [key: string]: string;
}
