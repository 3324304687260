import React, { useEffect, useState } from 'react';
import { AddIcon } from '../../../../../../../assets/icons';
import ActionDelete from '../../../../../../actionIcons/ActionDelete';
import { Button, Input, Label } from '../../../../../../../components/UI';
import { THEME } from '../../../../../../../constants/ColorConstants';
import {
  getCommutativeSize,
  getTotalGreaterThan16,
  getTotalLessThan10,
} from '../../../../../../../utils/Helper';
import { follicleLabels } from '../../../../../../../constants/constants';

const FollicleComponent: React.FC<FollicleComponentProps> = ({
  formData,
  setFormData,
  isLeft,
}) => {
  const [follicles, setFollicles] = useState<FollicleInput[] | Follicle[]>([]);
  useEffect(() => {
    setFollicles([...formData]);
  }, [formData.length]);

  const addFollicle = () => {
    setFollicles([
      ...follicles,
      {
        diameter1: null,
        diameter2: null,
        diameter3: null,
        volume: null,
        left: isLeft,
      },
    ]);
  };

  const removeFollicle = (index: number) => {
    setFollicles(follicles.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    key: keyof Omit<FollicleInput, 'left'>,
    value: string
  ) => {
    const updatedFollicles = [...follicles];
    updatedFollicles[index] = {
      ...updatedFollicles[index],
      [key]: parseFloat(value),
    };
    setFollicles(updatedFollicles);

    setFormData([...updatedFollicles]);
  };

  const commutativeSize = getCommutativeSize(follicles);
  const totalLessThan10 = getTotalLessThan10(follicles);
  const totalGreaterThan16 = getTotalGreaterThan16(follicles);

  return (
    <div className="flex flex-wrap ">
      <div className="w-full md:w-2/3 sm:w-full space-y-4">
        {follicles &&
          follicles.map((follicle, index) => (
            <div key={index} className="space-y-1">
              <div className="flex space-x-2 items-center">
                <Label className={index === 0 ? 'pt-[1.40rem]' : ''}>
                  Follicle {index + 1}
                </Label>
                {Object.entries(follicleLabels).map(([key, label]) => (
                  <div key={key} className="flex flex-col items-center">
                    {index === 0 && <Label>{label}</Label>}
                    <Input
                      value={
                        follicle[key as keyof Omit<FollicleInput, 'left'>] ?? ''
                      }
                      type="number"
                      className="w-16 no-spinner"
                      onChange={e =>
                        handleInputChange(
                          index,
                          key as keyof Omit<FollicleInput, 'left'>,
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
                <div className="flex flex-col items-center">
                  {index === 0 && <Label>Average</Label>}
                  <Input
                    value={(() => {
                      const thicknessValues = [
                        follicle.diameter1,
                        follicle.diameter2,
                        follicle.diameter3,
                      ].filter((v): v is number => v != null && v > 0);
                      const total = thicknessValues.reduce(
                        (acc, val) => acc + val,
                        0
                      );
                      const count = thicknessValues.length;
                      const average =
                        count > 0 ? (total / count).toFixed(1) : '0.0';
                      return average;
                    })()}
                    readOnly
                    className="border rounded px-2 py-2 bg-gray-100 w-16"
                  />
                </div>
                <div className="flex flex-col items-center">
                  {index === 0 && <Label>Volume (cm³)</Label>}
                  <Input
                    value={follicle.volume ?? ''}
                    type="number"
                    className="w-24 no-spinner"
                    onChange={e =>
                      handleInputChange(index, 'volume', e.target.value)
                    }
                  />
                </div>
                <ActionDelete
                  handleClick={() => removeFollicle(index)}
                  classNames={
                    index === 0
                      ? 'pt-[1.80rem]'
                      : 'pt-[1.55rem]' + 'text-[14px]'
                  }
                />
              </div>
            </div>
          ))}
        <Button
          size={'small'}
          variant={'secondary'}
          onClick={addFollicle}
          trailingIcon={<AddIcon stroke={THEME.PRIMARY_700} />}
        >
          Add Follicle
        </Button>
      </div>
      <div className="w-full md:w-1/3 sm:w-full space-y-4 ">
        {follicles.length > 0 && (
          <div className="  items-center">
            {ReadOnlyInput('Total Count', follicles.length)}
            {ReadOnlyInput('Commutative Size', commutativeSize)}
            {ReadOnlyInput('Total < 10', totalLessThan10)}
            {ReadOnlyInput('Total >16', totalGreaterThan16)}
          </div>
        )}
      </div>
    </div>
  );
};

export default FollicleComponent;

const ReadOnlyInput = (label: string, value: string | number) => {
  return (
    <div className="grid grid-cols-2">
      <p className="font-semibold text-neutral-500">{label}</p>
      <p className="font-semibold text-neutral-900">{value ?? '-'}</p>
    </div>
  );
};
