/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Textarea,
  Input,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import {
  createEmbryology,
  updateEmbryology,
} from '../../../../../services/api/endPoints/embryology';
import { notify } from '../../../../common/Alert';
import { DatePicker } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { getAllEggCollections } from '../../../../../services/api/endPoints/eggCollection';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  day1Day2Options,
  day3ToDay8Options,
  fateOptions,
  ploidyOptions,
} from '../../../../../constants/constants';

const EmbryologyModal: React.FC<EmbryologyModalProps> = ({
  open,
  onClose,
  ...props
}) => {
  const patient = useSelector<RootState, PatientState>(state => state.patient);
  const partner = useSelector<RootState, PartnerState>(state => state.partner);
  const { treatmentCycleId, id } = useParams();
  const [formData, setFormData] = useState<EmbryologyForm>({
    patientPublicId: !patient.patient.partnerPublicId ? id! : '',
    eggCollectionPublicId: '',
    oocyteMaturity: '',
    dateCollectedThawed: new Date(),
    day1: '',
    day2: '',
    day3: '',
    day4: '',
    day5: '',
    day6: '',
    day7: '',
    day8: '',
    fate: '',
    ploidy: '',
    straw: '',
    notes: '',
  });
  const daySelectData: SelectData[] = [
    { label: 'Day 1', value: 'day1', options: day1Day2Options },
    { label: 'Day 2', value: 'day2', options: day1Day2Options },
    { label: 'Day 3', value: 'day3', options: day3ToDay8Options },
    { label: 'Day 4', value: 'day4', options: day3ToDay8Options },
    { label: 'Day 5', value: 'day5', options: day3ToDay8Options },
    { label: 'Day 6', value: 'day6', options: day3ToDay8Options },
    { label: 'Day 7', value: 'day7', options: day3ToDay8Options },
    { label: 'Day 8', value: 'day8', options: day3ToDay8Options },
  ];
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eggCollections, setEggCollections] = useState<EggCollectionType[]>([]);
  useEffect(() => {
    const getEggCollections = async () => {
      if (!treatmentCycleId) return;
      const [res, error] = await getAllEggCollections(treatmentCycleId);
      if (res) {
        setEggCollections(res);
      }
    };
    getEggCollections();
    if (props.type === 'Edit') {
      setFormData({
        patientPublicId: props.embryology.patient.publicId || '',
        eggCollectionPublicId: props.embryology.eggCollection.publicId || '',
        oocyteMaturity: props.embryology.oocyteMaturity || '',
        dateCollectedThawed: props.embryology.dateCollectedThawed
          ? new Date(props.embryology.dateCollectedThawed)
          : new Date(),
        day1: props.embryology.day1 || '',
        day2: props.embryology.day2 || '',
        day3: props.embryology.day3 || '',
        day4: props.embryology.day4 || '',
        day5: props.embryology.day5 || '',
        day6: props.embryology.day6 || '',
        day7: props.embryology.day7 || '',
        day8: props.embryology.day8 || '',
        fate: props.embryology.fate || '',
        ploidy: props.embryology.ploidy || '',
        straw: props.embryology.straw || '',
        notes: props.embryology.notes || '',
      });
    }
  }, []);

  const inputHandler = (name: keyof EmbryologyForm, value: any) => {
    const errors = { ...formErrors };
    if (name === 'patientPublicId') {
      setFormData(prev => ({
        ...prev,
        [name]: value,
        eggCollectionPublicId: '',
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }

    if (errors[name]) {
      delete errors[name];
    }
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors = { ...formErrors };
    if (!formData.patientPublicId) {
      errors.patientPublicId = 'Patient is required';
    }
    if (!formData.eggCollectionPublicId) {
      errors.eggCollectionPublicId = 'Egg collection is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    if (props.type === 'Create') {
      const [res, error] = await createEmbryology({
        treatmentCyclePublicId: treatmentCycleId!,
        ...formData,
      });
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EMBRYOLOGY_ADD,
          message: `Embryo '${res.publicId}' has been added`,
        });
        props.addNewEmbryology(res);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_EMBRYOLOGY_ADD,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    } else if (props.type === 'Edit') {
      const [res, error] = await updateEmbryology({
        ...formData,
        publicId: props.embryology.publicId,
      });
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_EMBRYOLOGY_UPDATE,
          message: `Embryo '${res.publicId}' has been updated`,
        });
        props.updateEmbryology(res);
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_EMBRYOLOGY_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
    }
    setIsLoading(false);
  };

  const renderSelect = (inputFor: SelectData) => {
    return (
      <div key={inputFor.value} className="w-full space-y-1">
        <Label>{inputFor.label}</Label>
        <Select
          value={formData[inputFor.value] as string}
          onValueChange={(value: string) => inputHandler(inputFor.value, value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent className="z-[9999999] bg-white">
            {inputFor.options.map(option => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const filteredEggCollections = useMemo(() => {
    return formData.patientPublicId
      ? eggCollections.filter(
          eggCollection =>
            eggCollection.patient.publicId === formData.patientPublicId
        )
      : eggCollections;
  }, [formData.patientPublicId, eggCollections]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Edit' ? 'Edit Embryo' : 'Add Embryo'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Patient
              </Label>
              <Select
                name="patientPublicId"
                value={formData.patientPublicId}
                onValueChange={value => inputHandler('patientPublicId', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[9999999]">
                  <SelectItem value={patient.patient.publicId}>
                    {patient.patient.fullName}
                  </SelectItem>
                  {partner.partner.publicId && (
                    <SelectItem value={partner.partner.publicId}>
                      {partner.partner.fullName} - partner
                    </SelectItem>
                  )}
                </SelectContent>
              </Select>
              {formErrors.patientPublicId && (
                <small className="text-destructive-500">
                  {formErrors.patientPublicId}
                </small>
              )}
            </div>
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Egg Collection
              </Label>
              <Select
                value={formData.eggCollectionPublicId}
                onValueChange={value =>
                  inputHandler('eggCollectionPublicId', value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="z-[9999999] bg-white">
                  {filteredEggCollections.length > 0 ? (
                    filteredEggCollections.map(option => (
                      <SelectItem key={option.publicId} value={option.publicId}>
                        {option.publicId}
                      </SelectItem>
                    ))
                  ) : (
                    <p className="p-3 text-center text-neutral-400 text-wrap">
                      No egg collection found for the selected patient
                    </p>
                  )}
                </SelectContent>
              </Select>
              {formErrors.eggCollectionPublicId && (
                <small className="text-destructive-500">
                  {formErrors.eggCollectionPublicId}
                </small>
              )}
            </div>
            <div className="w-full space-y-1">
              <Label>Oocyte Maturity</Label>
              <Input
                value={formData.oocyteMaturity}
                onChange={e => inputHandler('oocyteMaturity', e.target.value)}
              />
            </div>
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Date Collected/Thawed
              </Label>
              <DatePicker
                variant="dateTime"
                initialValue={formData.dateCollectedThawed}
                handleDateChange={date =>
                  inputHandler('dateCollectedThawed', date)
                }
              />
            </div>
            {daySelectData.map(data => renderSelect(data))}
            {renderSelect({
              label:'Fate',
              value:'fate',
              options:fateOptions
            })}
            {
              renderSelect({
                label:'Ploidy',
                value:'ploidy',
                options:ploidyOptions
              })
            }
            <div className="w-full space-y-1">
              <Label>Straw</Label>
              <Input
                value={formData.straw}
                onChange={e => inputHandler('straw', e.target.value)}
              />
            </div>
            <div className="w-full space-y-1">
              <Label>Notes</Label>
              <Textarea
                value={formData.notes}
                onChange={e => inputHandler('notes', e.target.value)}
              />
            </div>
          </div>
        </Slider>
        <DialogFooter className="p-4 border-t border-neutral-100 flex gap-2 w-full">
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={isLoading}
            className="w-full"
          >
            {props.type === 'Edit' ? 'Save embryo' : 'Add embryo'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmbryologyModal;
