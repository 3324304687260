/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  deleteEmbryology,
  getAllEmbryologies,
} from '../../../../../services/api';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../components/UI';
import {
  DeleteIcon,
  LeftArrowIcon,
  OutlinedAddIcon,
} from '../../../.././../assets/icons';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import { getReadableDate } from '../../../../../utils/date.utl';
import { notify, AlertDialog } from '../../../../../components/common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import EmbryologyModal from './EmbryologyModal';
import { THEME } from '../../../../../constants/ColorConstants';
import { PenIcon } from 'lucide-react';
import { embryologyTableLabels } from '../../../../../constants/constants';
const Embryology = () => {
  const [embryologies, setEmbryologies] = useState<EmbryologyType[]>([]);
  const { treatmentCycleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addEmbryologyModalOpen, setAddEmbryologyModalOpen] =
    useState<boolean>(false);
  const [selectedEmbryology, setSelectedEmbryology] =
    useState<EmbryologyType | null>(null);
  const [editEmbryologyModalOpen, setEditEmbryologyModalOpen] =
    useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  useEffect(() => {
    const getEmbryologies = async () => {
      if (!treatmentCycleId) return;
      setIsLoading(true);
      const [res, error] = await getAllEmbryologies(treatmentCycleId);
      if (res) {
        setEmbryologies(res);
      } else {
        notify.error({
          title: 'Failed to fetch all embryology',
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getEmbryologies();
  }, []);
  const addNewEmbryology = (data: EmbryologyType) => {
    const newEmbryologies = [...embryologies];
    newEmbryologies.push(data);
    setEmbryologies(newEmbryologies);
  };
  const updateEmbryology = (data: EmbryologyType) => {
    const newEmbryologies = [...embryologies];
    const updatedIndex = newEmbryologies.findIndex(
      embryology => embryology.publicId === selectedEmbryology?.publicId
    );
    newEmbryologies[updatedIndex] = data;
    setEmbryologies(newEmbryologies);
  };

  const closeDeleteModalHandler = () => {
    setSelectedEmbryology(null);
    setDeleteModalOpen(false);
    setIsDeleting(false);
  };
  const deleteHandler = async () => {
    if (!selectedEmbryology) return;
    setIsDeleting(true);
    const [, error] = await deleteEmbryology(selectedEmbryology.publicId);
    if (!error) {
      let newEmbryologies = [...embryologies];
      newEmbryologies = newEmbryologies.filter(
        embryology => embryology.publicId !== selectedEmbryology.publicId
      );
      setEmbryologies(newEmbryologies);
      notify.success({
        title: SUCCESS_TITLE.GENERIC_EMBRYOLOGY_DELETE,
        message: `Embryo '${selectedEmbryology.publicId}' has been deleted`,
      });
      closeDeleteModalHandler();
    } else {
      notify.success({
        title: ERROR_TITLE.GENERIC_EMBRYOLOGY_DELETE,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
  };
  return (
    <>
      {deleteModalOpen && selectedEmbryology && (
        <AlertDialog
          type="delete"
          open={deleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteHandler}
          alertTitle={`Deleting embryo '${selectedEmbryology.publicId}'`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete embryo '{selectedEmbryology.publicId}'
          ?
        </AlertDialog>
      )}
      {addEmbryologyModalOpen && (
        <EmbryologyModal
          type="Create"
          open={addEmbryologyModalOpen}
          onClose={() => setAddEmbryologyModalOpen(false)}
          addNewEmbryology={addNewEmbryology}
        />
      )}
      {editEmbryologyModalOpen && selectedEmbryology && (
        <EmbryologyModal
          type="Edit"
          open={editEmbryologyModalOpen}
          onClose={() => setEditEmbryologyModalOpen(false)}
          updateEmbryology={updateEmbryology}
          embryology={selectedEmbryology}
        />
      )}
      <div>
        <div className="p-3 flex justify-between items-center border-b">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Embryo
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setAddEmbryologyModalOpen(true)}
              disabled={!!location?.state?.isReadOnly}
            >
              Add embryo
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
          </div>
        ) : embryologies.length > 0 ? (
          <div className="p-3">
            <div className="w-full flex border border-neutral-100 rounded">
              <div className="border-r bg-neutral-100">
                {embryologyTableLabels.map((label,index) => (
                  <div
                    key={`${index}-${label}`}
                    className="border-b p-3 flex items-center h-[50px]"
                  >
                    <p className="font-semibold">{label}</p>
                  </div>
                ))}
              </div>
              <div className="w-full overflow-y-hidden flex flex-row">
                {embryologies.map((embryology, index) => (
                  <div
                    key={`${embryology}-${index}`}
                    className="border-r min-w-[200px]"
                  >
                    <div className="border-b border-neutral-100 p-3 flex items-center justify-center h-[50px] gap-1">
                      <p className="font-semibold">{embryology.publicId}</p>
                      <Button
                        variant={'link'}
                        onClick={() => {
                          setSelectedEmbryology(embryology);
                          setEditEmbryologyModalOpen(true);
                        }}
                      >
                        <PenIcon size={15} stroke={THEME.PRIMARY_500} />
                      </Button>
                    </div>
                    <div className="border-b border-neutral-100 p-3 flex items-center justify-center h-[50px]">
                      {embryology.oocyteMaturity ?? '-'}
                    </div>
                    <div className="border-b border-neutral-100 p-3 flex items-center justify-center h-[50px]">
                      {embryology.eggCollection?.collectionDate
                        ? getReadableDate(
                            embryology.eggCollection.collectionDate
                          )
                        : '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.treatmentCycle?.treatment?.name ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.dateCollectedThawed
                        ? getReadableDate(embryology.dateCollectedThawed)
                        : '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day1 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day2 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day3 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day4 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day5 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day6 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day7 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.day8 ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.ploidy ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.fate ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.straw ?? '-'}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center border-b h-[50px]">
                      {embryology.notes ? (
                        embryology.notes.length > 15 ? (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <p>
                                  {' '}
                                  {embryology.notes.slice(0, 5)}...
                                  {embryology.notes.slice(-4)}
                                </p>
                              </TooltipTrigger>
                              <TooltipContent className="max-w-[400px]">
                                <p>{embryology.notes}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ) : (
                          embryology.notes
                        )
                      ) : (
                        '-'
                      )}
                    </div>
                    <div className="border-neutral-100 p-3 flex items-center justify-center h-[50px]">
                      <Button
                        variant={'link'}
                        onClick={() => {
                          setSelectedEmbryology(embryology);
                          setDeleteModalOpen(true);
                        }}
                      >
                        <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <p className="text-[14px] text-neutral-500">
              No embryology. Please try again later
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Embryology;
