import React from 'react';
import {
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI';

interface SelectInputProps {
  label: string;
  name: string;
  value: string;
  options: string[];
  onValueChange: (
    value: 'Fetal heartbeat' | 'Early outcome' | 'Live birth'
  ) => void;
  errorMessage?: string;
  className?: string;
  required?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  value,
  options,
  onValueChange,
  errorMessage,
  className = '',
  required = false,
}) => {
  const finalErrorMessage =
    required && !errorMessage ? 'This field is required.' : errorMessage;

  return (
    <div className={`w-full space-y-1 ${className}`}>
      <Label
        className={`after:content-['*'] after:text-destructive-500 ${required ? 'required' : ''}`}
      >
        {label}
      </Label>
      <div className="space-y-1">
        <Select name={name} value={value} onValueChange={onValueChange}>
          <SelectTrigger hasError={!!finalErrorMessage}>
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent className="bg-shades-0 z-[9999999]">
            {options.map(option => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {finalErrorMessage && (
          <small className="text-destructive-500">{finalErrorMessage}</small>
        )}
      </div>
    </div>
  );
};

export default SelectInput;
