import {
  addFemalePatientInformation,
  addPartnerInformation,
} from '../redux/slice';
import { addPatientInformation } from '../redux/slice/patientInformation';
import { getPatientByPublicId } from '../services/api/endPoints/patients';
import { useDispatch } from 'react-redux';
import { CONSTANT } from '../constants/constants';
import { getFormattedPatientData } from '../utils/Helper';

const useFetchPatient = () => {
  const dispatch = useDispatch();

  const fetchPatientDetails = async (
    patientPublicId: string,
    isPartner: boolean
  ) => {
    try {
      const { data } = await getPatientByPublicId(patientPublicId);
      if (data.publicId) {
        const formattedData = getFormattedPatientData(data);
        if (formattedData.gender === CONSTANT.FEMALE) {
          dispatch(addFemalePatientInformation(formattedData));
        }
        if (isPartner) {
          dispatch(addPartnerInformation(formattedData));
        } else {
          dispatch(addPatientInformation(formattedData));
        }
        return [formattedData, null] as const;
      } else {
        return [null, data];
      }
    } catch (error) {
      return [null, error] as const;
    }
  };

  return fetchPatientDetails;
};

export default useFetchPatient;
