import React from 'react';

interface ListProps {
  title: string;
  children: React.ReactNode;
}

const List: React.FC<ListProps> = ({ title, children }) => {
  return (
    <div className="list">
      <h2 className="text-4xl font-medium text-neutral-900">{title}</h2>
      <div className="list-grid">{children}</div>
    </div>
  );
};

export default List;
