import React, { useState, useEffect } from 'react';
import { downloadFileByUrl } from '../../services/api/endPoints/files.api';
import defaultImage from '../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import Skeleton from 'react-loading-skeleton';
import { cn } from '../../utils';
import { useDispatch } from 'react-redux';
import { updateUserStaffDetails } from '../../redux/slice';

interface ProfileImageProps {
  user: StaffInformation;
  height: string;
  width: string;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ user, height, width }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string>(defaultImage);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (user.profilePictureBase64DataUrl) {
        setImageUrl(user.profilePictureBase64DataUrl);
        setIsLoading(false);
        return;
      }

      if (!user.profilePictureUrl) {
        setIsLoading(false);
        return;
      }

      try {
        const [res] = await downloadFileByUrl({ url: user.profilePictureUrl });
        if (res) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const result = reader.result as string;
            setImageUrl(result);
            dispatch(
              updateUserStaffDetails({
                ...user,
                profilePictureBase64DataUrl: result,
              })
            );
          };
          reader.readAsDataURL(res);
        }
      } catch (error) {
        console.error('Error fetching profile image:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileImage();
  }, [user, dispatch]);

  return (
    <>
      {isLoading ? (
        <Skeleton height={height} width={width} circle />
      ) : (
        <img
          src={imageUrl}
          alt="Profile"
          className={cn([
            'rounded-full inline mr-2',
            `w-[${width}]`,
            `h-[${height}]`,
          ])}
        />
      )}
    </>
  );
};

export default ProfileImage;
