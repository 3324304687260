import React from 'react';

interface SemenAnalysisIconProps {
  stroke?: string;
}

const SemenAnalysisIcon: React.FC<SemenAnalysisIconProps> = ({ stroke = 'currentColor' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03006 7.64446C8.45148 8.00143 9.22717 7.77837 9.76262 7.14624C10.2981 6.51412 10.3905 5.7123 9.9691 5.35533C9.54768 4.99836 8.77199 5.22142 8.23654 5.85355C7.70108 6.48568 7.60864 7.2875 8.03006 7.64446Z"
      fill="black"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33333 11H8.22222C8.4285 11 8.62633 10.921 8.77219 10.7803C8.91806 10.6397 9 10.4489 9 10.25C9 10.0511 8.91806 9.86032 8.77219 9.71967C8.62633 9.57902 8.4285 9.5 8.22222 9.5H5.77778C5.5715 9.5 5.37367 9.42098 5.22781 9.28033C5.08194 9.13968 5 8.94891 5 8.75C5 8.55109 5.08194 8.36032 5.22781 8.21967C5.37367 8.07902 5.5715 8 5.77778 8H7.66667"
      stroke={stroke}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SemenAnalysisIcon;
