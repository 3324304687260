import { URL } from '../requestConstructor';

export const getAllMedicationsByTid = async (treatmentId: string) => {
  try {
    const response = await URL.get(
      `/medication/get?treatmentCyclePublicId=${treatmentId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAllMedicationNames = async () => {
  try {
    const response = await URL.get('/medication/get/names');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAllMedicationTypes = async () => {
  try {
    const response = await URL.get('/medication/get/types');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAllMedicationDosage = async () => {
  try {
    const response = await URL.get('/medication/get/dosageUnits');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAllMedicationMetadata = async () => {
  try {
    const response = await URL.get('/medication/get/metadata');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createMedicineRecord = async (data: MedicationCreate) => {
  try {
    const response = await URL.post('/medication/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const discontinueMedicine = async (
  publicId: string,
  discontinuedOn?: string //2024-04-09
) => {
  const url = discontinuedOn
    ? `/medication/discontinue?publicId=${publicId}&discontinuedOn=${discontinuedOn}`
    : `/medication/discontinue?publicId=${publicId}`;
  try {
    const response = await URL.put(url);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
