import React from 'react';
import { Outlet } from 'react-router-dom';
import './Booking.css';
import { Button } from '../../components/UI';
import { LogoType2, OutlinedCirlce } from '../../assets/icons';
const BookingLayout = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <header className="w-full px-[16px] border-b border-neutral-100 flex justify-between items-center py-[12px] px-[5%]">
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <img
                src={OutlinedCirlce}
                alt="Outlined Circle"
                style={{ height: '25px' }}
              />
              <img src={LogoType2} alt="Logo" style={{ height: '23px' }} />
            </div>
          </div>
          <div className=" gap-4 items-center">
            <div className="flex items-center gap-2">
              <Button size={'medium'} variant={'secondary'}>
                Create Account
              </Button>
              <Button size={'medium'} variant={'primary'}>
                Log In
              </Button>
            </div>
          </div>
        </header>
        <main className="flex-1 overflow-y-auto pt-3 px-[5%]">
          <Outlet />
        </main>
        <footer className="py-3 flex justify-center">
          <p className="text-[14px] text-neutral-500">
            © Swivo Technologies 2024 | Terms of Use | Privacy Policy
          </p>
        </footer>
      </div>
    </>
  );
};

export default BookingLayout;
