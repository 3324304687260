import { HEIGHT_UNITS, WEIGHT_UNITS } from '../constants/constants';
import { calculateAge } from './data.helper';
import {
  formatToYYYYMMDD,
  getAppointmentReadableDateTime,
  getNextDate,
  getReadableDate,
} from './date.utl';
export const getFullName = (
  data: PatientLite | PatientInformation | StaffInformation
) => {
  const parts: string[] = [];

  if (data.title) parts.push(data.title);
  if (data.firstName) parts.push(data.firstName);
  if (data.lastName) parts.push(data.lastName);

  return parts.join(' ');
};

export const getNextAppointment = (appt: Booking | null): string => {
  if (appt) {
    return `${appt.service.name} on ${getAppointmentReadableDateTime(
      new Date(appt.appointmentTime)
    )}`;
  }

  return '-';
};

export const getHeightWithUnit = (
  data: PatientLite | PatientInformation
): string => {
  if (data.height && data.heightUnit) {
    if (data.heightUnit === HEIGHT_UNITS.INCH) {
      //convert to feet & inches
      const feet = Math.floor(data.height / 12);
      const inches = data.height % 12;
      return `${feet} FT ${inches} IN`;
    }

    return `${data.height} ${data.heightUnit.toUpperCase()}`;
  }

  return '-';
};

export const getWeightWithUnit = (
  data: PatientLite | PatientInformation
): string => {
  if (data.weight && data.weightUnit) {
    return `${data.weight} ${data.weightUnit.toUpperCase()}`;
  }

  return '-';
};

export const calculateBMI = (
  data: PatientLite | PatientInformation
): string => {
  if (data.height && data.heightUnit && data.weight && data.weightUnit) {
    let heightInMeter = 0;
    if (data.heightUnit === HEIGHT_UNITS.INCH) {
      heightInMeter = data.height * 0.0254;
    } else if (data.heightUnit === HEIGHT_UNITS.CM) {
      heightInMeter = data.height / 100;
    } else {
      return '-';
    }

    let weightInKg = 0;
    if (data.weightUnit === WEIGHT_UNITS.LBS) {
      weightInKg = data.weight * 0.453592;
    } else if (data.weightUnit === WEIGHT_UNITS.KG) {
      weightInKg = data.weight;
    } else {
      return '-';
    }

    if (heightInMeter === 0) return '-';
    if (weightInKg === 0) return '-';

    const bmi = weightInKg / (heightInMeter * heightInMeter);
    return bmi.toFixed(2);
  }

  return '-';
};

export const getAddress = (address: Address): string => {
  const parts: string[] = [];

  if (address.street1) parts.push(address.street1);
  if (address.city) parts.push(address.city);
  if (address.state) parts.push(address.state);
  if (address.postalCode) parts.push(address.postalCode);
  if (address.country) parts.push(address.country);

  return parts.join(', ');
};
const appointmentTimeMatrix = [
  {
    name: 'First Consultation',
    time: 30,
  },
  {
    name: 'Follow Up Consultation',
    time: 15,
  },
  { name: 'Ultrasound Scan', time: 15 },
  { name: 'Anti-Mullerian (AMH) Test', time: 15 },
  { name: 'One-Stop Fertility Diagnosis Couple', time: 30 },
  { name: 'One-Stop Fertility Diagnosis Female', time: 30 },
  { name: 'HyCoSy (Tube Test)', time: 30 },
];

export const getTimeByServiceName = (name: string): number => {
  const appointment = appointmentTimeMatrix.find(app => app.name === name);

  return appointment ? appointment.time : 0;
};

export const countryCodes = [
  { code: '+1', country: 'US' },
  { code: '+44', country: 'GB' },
  { code: '+91', country: 'IN' },
  { code: '+86', country: 'CN' },
  { code: '+81', country: 'JP' },
  { code: '+49', country: 'DE' },
  { code: '+33', country: 'FR' },
  { code: '+61', country: 'AU' },
  { code: '+7', country: 'RU' },
  { code: '+55', country: 'BR' },
];

export const getFormattedPatientData = (data: any) => {
  const formattedData = { ...data };
  formattedData.dateOfBirth = getReadableDate(data.dateOfBirth);
  formattedData.age = data.dateOfBirth ? calculateAge(data.dateOfBirth) : '';
  return formattedData;
};

export const sortTreatmentCycles = (
  cycles: TreatmentCycle[]
): TreatmentCycle[] => {
  return cycles.sort((a, b) => {
    const getStatusOrder = (cycle: TreatmentCycle) => {
      if (cycle.endDate) {
        return 3; // Completed
      } else if (cycle.cancelledDate) {
        return 2; // Cancelled
      } else {
        return 1; // Active
      }
    };

    const statusA = getStatusOrder(a);
    const statusB = getStatusOrder(b);

    return statusA - statusB;
  });
};

export const findMaxHormoneResultDate = (data: HormoneLevel[]): Date => {
  let maxDate = new Date(data[0]?.resultDate).setHours(0, 0, 0, 0);
  data.forEach(current => {
    const normalizeDate = new Date(current.resultDate).setHours(0, 0, 0, 0);
    maxDate =
      normalizeDate > maxDate
        ? new Date(current.resultDate).setHours(0, 0, 0, 0)
        : maxDate;
  });
  return new Date(maxDate);
};

export const generateTimeLine = (
  startDate: Date | string,
  endDate: Date | string
): TimeLine[] => {
  const dateLabels: TimeLine[] = [];
  let currentDate = new Date(startDate);
  let end = new Date(endDate);
  const normalizeDate = (date: Date) => new Date(date.setHours(0, 0, 0, 0));
  currentDate = normalizeDate(currentDate);
  end = normalizeDate(end);

  // Ensure there are always 30 days in the timeline
  const daysDifference = Math.ceil(
    (end.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  if (daysDifference < 30) {
    end = new Date(currentDate);
    end.setDate(currentDate.getDate() + 29);
  }

  let dayCounter = 1;

  while (currentDate <= end) {
    dateLabels.push({
      label: `CD${dayCounter}`,
      day: currentDate.toLocaleDateString('en-UK', { weekday: 'long' }), // Weekday name
      date: formatToYYYYMMDD(currentDate),
    });

    currentDate = getNextDate(currentDate); // Use the provided utility function
    dayCounter++;
  }

  return dateLabels;
};
import Cookies from 'js-cookie';

export const clearCookies = () => {
  const allCookies = Cookies.get();
  for (const cookie in allCookies) {
    Cookies.remove(cookie, { path: '/' });
  }
};

export const calculateAverage = (...numbers: number[]): number => {
  if (numbers.length === 0) return 0;

  const sum = numbers.reduce((acc, num) => acc + num, 0);
  return sum / numbers.length;
};

export const calculateAverageDiameter = (follicle: FollicleInput): number => {
  const diameters = [
    follicle.diameter1,
    follicle.diameter2,
    follicle.diameter3,
  ];

  // Filter out null or undefined values and keep only valid numbers
  const validDiameters = diameters.filter(
    diameter => diameter !== null && diameter !== undefined
  ) as number[]; // Type assertion to treat filtered values as numbers

  // Calculate the sum of valid diameters
  const sum = validDiameters.reduce((acc, diameter) => acc + diameter, 0);

  const count = validDiameters.length;

  // Return the average if there are valid diameters, otherwise return 0
  return count > 0 ? sum / count : 0;
};

export const getCommutativeSize = (follicles: FollicleInput[] = []): number => {
  const totalSize = follicles.reduce((total, follicle) => {
    return total + calculateAverageDiameter(follicle);
  }, 0);
  return parseFloat(totalSize.toFixed(1));
};

export const getTotalLessThan10 = (follicles: FollicleInput[]): number => {
  return follicles.filter(follicle => calculateAverageDiameter(follicle) < 10)
    .length;
};

export const getTotalGreaterThan16 = (follicles: FollicleInput[]): number => {
  return follicles.filter(follicle => calculateAverageDiameter(follicle) > 16)
    .length;
};
